import { AfterViewInit, Component, OnInit, DoCheck, OnDestroy, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.services';
import { BusService } from 'src/app/services/bus.services';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { dataTableSettingStatusCertification } from 'src/app/models/dictionary';
import Chart, { DoughnutController } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {formatDate} from '@angular/common';


@Component({
  selector: 'app-status-certification',
  templateUrl: './status-certification.component.html',
  styleUrls: ['./status-certification.component.css']
})
export class EstadoCertificacionComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  
  certifications: any[] = [];
  public ppu="";
  public token;
  public datefrom="";
  public dateto="";
  public today;
  dtOptions:any = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  chart: any = [];
  chart2: any = [];
  chart3: any = [];
  chart4: any = [];
  meses: any = [0,31,28,31,30,31,30,31,31,30,31,30,31];
  ad_aprobados:number = 0;
  ad_noaprobados:number = 0;
  ad_pendientes:number = 0;

  it_aprobados:number = 0;
  it_noaprobados:number = 0;
  it_vencidos:number = 0;
  it_pendientes:number = 0;

  egb_aprobados:number = 0;
  egb_noaprobados:number = 0;
  egb_vencidos:number = 0;
  egb_pendientes:number = 0;

  egf_aprobados:number = 0;
  egf_noaprobados:number = 0;
  egf_pendientes:number = 0;

  constructor(
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private _BusService:BusService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.token = this._UserService.getToken();
    registerLocaleData(es);
    this.dtOptions = dataTableSettingStatusCertification;
    this.today = formatDate(new Date(), 'yyyy-MM-dd', 'en')
    this.datefrom =  formatDate(new Date(), 'yyyy-MM', 'en')
    //this.dateto = formatDate(new Date(), 'yyyy-MM', 'en')

    this.chart = new Chart('ad', {
      type: 'pie',
      data: {
        labels: ['Aprobados', 'Reprobados', 'Pendientes'],
        datasets: [
          {
            label: '# de Buses',
            data: [0,0,0],
            borderWidth: 1,
            backgroundColor: [
              'rgb(34, 177, 76)',
              'rgb(255,0,0)',
              'rgb(255,128,0)'
            ],
            hoverOffset: 4,

          },
        ],
      },
      options:{ 
        responsive: false,
        plugins:{         
          datalabels: {
            color: 'white',
            font: {
              size: 13,
              weight: 'bold',
            },
            formatter: function(value, ctx) {
              let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += +data;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage=='0.00%'?'':percentage;
            }
          }
        }
      },
        
      plugins: [ChartDataLabels],
    });

    this.chart2 = new Chart('it', {
      type: 'pie',
      data: {
        labels: ['Aprobados', 'Reprobados', 'Pendientes', 'Vencidos'],
        datasets: [
          {
            label: '# de Buses',
            data: [0,0,0],
            borderWidth: 1,
            backgroundColor: [
              'rgb(34, 177, 76)',
              'rgb(255,0,0)',
              'rgb(255,128,0)',
              'rgb(236,230,0)'
            ],
            hoverOffset: 4
          },
        ],
      },
      options:{ 
        responsive: false,
        plugins:{         
          datalabels: {
            color: 'white',
            font: {
              size: 14,
              weight: 'bold',
            },
            formatter: function(value, ctx) {
              let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += +data;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage=='0.00%'?'':percentage;
            }
          }
        }
      },
    });

    this.chart3 = new Chart('egb', {
      type: 'pie',
      data: {
        
        labels: ['Aprobados', 'Reprobados', 'Pendientes', 'Vencidos'],
        datasets: [
          {
            label: '# de Buses',
            data: [0,0,0,0],
            borderWidth: 1,
            backgroundColor: [
              'rgb(34, 177, 76)',
              'rgb(255,0,0)',
              'rgb(255,128,0)',
              'rgb(236,230,0)'
            ],
            hoverOffset: 4
          },
        ],
      },
      options:{ 
        responsive: false,
        plugins:{         
          datalabels: {
            color: 'white',
            font: {
              size: 14,
              weight: 'bold',
            },
            formatter: function(value, ctx) {
              let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += +data;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage=='0.00%'?'':percentage;
            }
          }
        }
      },
    });

    this.chart4 = new Chart('egf', {
      type: 'pie',
      data: {
        labels: ['Aprobados', 'Reprobados', 'Pendientes'],
        datasets: [
          {
            label: 'N° de Buses',
            data: [0,0,0],
            borderWidth: 1,
            backgroundColor: [
              'rgb(34, 177, 76)',
              'rgb(255,0,0)',
              'rgb(255,128,0)',
            ],
            hoverOffset: 4
          },
        ],
        
      },
      options:{ 
        responsive: false,
        plugins:{         
          datalabels: {
            color: 'white',
            font: {
              size: 13,
              weight: 'bold',
            },
            formatter: function(value, ctx) {
              let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += +data;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage=='0.00%'?'':percentage;
            }
          }
        }
      },
      plugins: [ChartDataLabels],
      
    });


    this.BuscarCertifications();

  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  BuscarCertifications() {
      this.SpinnerService.show();
      this._BusService.getStatusCertifications(this.token, this.datefrom,"", this.ppu)
        .subscribe({ 
          next:(response) => {
            if (response.status=='success'){
              this.certifications = response.data.certificaciones;
              //total_buses = response.data.buses_activos;
              //this.chart.data.labels = ["Aprobados", "No aprobados", "Pendientes"]
              this.ad_aprobados = response.data.dashboard.auditoria.aprobados;
              this.ad_noaprobados = response.data.dashboard.auditoria.noaprobados;
              this.ad_pendientes = response.data.dashboard.auditoria.pendientes;
              this.it_aprobados = response.data.dashboard.inspeccion.aprobados;
              this.it_noaprobados = response.data.dashboard.inspeccion.noaprobados;
              this.it_vencidos = response.data.dashboard.inspeccion.vencidos;
              this.it_pendientes = response.data.dashboard.inspeccion.pendientes;
              this.egb_aprobados = response.data.dashboard.egb.aprobados;
              this.egb_noaprobados = response.data.dashboard.egb.noaprobados;
              this.egb_vencidos = response.data.dashboard.egb.vencidos;
              this.egb_pendientes = response.data.dashboard.egb.pendientes;
              this.egf_aprobados = response.data.dashboard.egf.aprobados;
              this.egf_noaprobados = response.data.dashboard.egf.noaprobados;
              this.egf_pendientes = response.data.dashboard.egf.pendientes;

              this.chart.data.datasets[0].data = [this.ad_aprobados,this.ad_noaprobados, this.ad_pendientes];
              this.chart2.data.datasets[0].data = [this.it_aprobados,this.it_noaprobados, this.it_pendientes, this.it_vencidos];
              this.chart3.data.datasets[0].data = [this.egb_aprobados,this.egb_noaprobados, this.egb_pendientes, this.egb_vencidos];
              this.chart4.data.datasets[0].data = [this.egf_aprobados,this.egf_noaprobados, this.egf_pendientes];
              this.chart.update();
              this.chart2.update();
              this.chart3.update();
              this.chart4.update();


              this.today = formatDate(new Date(), 'yyyyMM', 'en');

              if (+this.today > +this.datefrom.replace("-", "")){
                this.today = this.datefrom + "-" + "01"
              }else
                this.today = formatDate(new Date(), 'yyyy-MM-dd', 'en');

              if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                  dtInstance.destroy();
                  this.dtTrigger.next(0);
                });
              } else {
                this.isDtInitialized = true;
                this.reDraw();
              }
            }

          },
          error:(error)=> {
            this.SpinnerService.hide();
            if (error.status != 401) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado'
              });
              this._router.navigate(['logout/1']);
            }},
          complete: () => {
            this.SpinnerService.hide();
          }
        });

  }

  limpiar() {
    this.ppu = "";
    this.datefrom = "";
    this.dateto = "";
    this.certifications = [];
    this.ad_aprobados = 0;
    this.ad_noaprobados = 0;
    this.ad_pendientes = 0;
  
    this.it_aprobados = 0;
    this.it_noaprobados = 0;
    this.it_pendientes = 0;
  
    this.egb_aprobados = 0;
    this.egb_noaprobados = 0;
    this.egb_pendientes = 0;
  
    this.egf_aprobados = 0;
    this.egf_noaprobados= 0;
    this.egf_pendientes = 0;
  }

  downloadPdf(base64String, fileName) {
    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }
  onClickDownloadPdf(ppu, base64String){
    this.downloadPdf(base64String, ppu + "_" + this.datefrom);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(0);
  }
    
}
