import { Component, OnInit, DoCheck } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditsService } from 'src/app/services/audits.services';
import { StepperOrientation } from '@angular/cdk/stepper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';
import { Bus } from 'src/app/models/bus';
import { BusService } from 'src/app/services/bus.services';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

export interface Sistemas {
    id: number,
    description: string
}

@Component({
    selector: 'app-inspeccion-add',
    templateUrl: './inspeccion-add.component.html',
    styleUrls: ['./inspeccion-add.component.css'],
})


export class InspeccionAddComponent implements OnInit, DoCheck {
    selectedPpu: string;
    stepperOrientation: Observable<StepperOrientation>;
    public token;
    public name;
    public inspeccion: any;
    public components: any;
    public categoria: string = "";
    public observaciones: string = ""
    public periodo: string;
    firstFormGroup: FormGroup;
    public ppu: string;
    public buses: Bus[] = [];
    public AllNoCumple: boolean = false;
    public AllCumple: boolean = false;
    public elementos_inspeccionados: number = 0;
    public criticidad_baja: number = 0;
    public criticidad_media: number = 0;
    public criticidad_alta: number = 0;
    public criticidad_baja_cumple: number = 0;
    public criticidad_media_cumple: number = 0;
    public criticidad_alta_cumple: number = 0;
    public resultado = "A";
    public reinspeccion: boolean = false;
    public sistemas: string[] = [];
    public total_sistemas_alta: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public total_sistemas_media: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public total_sistemas_baja: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public total_sistemas_alta_cumple: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public total_sistemas_media_cumple: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public total_sistemas_baja_cumple: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    public resumen: any[] = [];
    public sistems: Sistemas[] = [];
    constructor(
        public modal: NgbActiveModal,
        private _auditsService: AuditsService,
        private _userService: UserService,
        private _formBuilder: FormBuilder,
        private _BusService: BusService,
        private _router: Router,
        private SpinnerService: NgxSpinnerService,
        breakpointObserver: BreakpointObserver
    ) {
        this.stepperOrientation = breakpointObserver
            .observe('(min-width: 800px)')
            .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

        this.firstFormGroup = this._formBuilder.group({
            ppu: new FormControl('', [Validators.required]),
            km: new FormControl('', [Validators.required, Validators.min(1)]),
            fecha_inicio: new FormControl('', [Validators.required]),
            hora_inicio: new FormControl('', [Validators.required]),
            fecha_termino: new FormControl('', [Validators.required]),
            hora_termino: new FormControl('', [Validators.required]),

            lugar_inspeccion: new FormControl('', [Validators.required]),
            observaciones: new FormControl('', []),
        });

    }

    get form() {
        return this.firstFormGroup.controls;
    }

    ngOnInit() {
        this.token = this._userService.getToken();
        this.getSistemasInspeccion();
        this.loadBuses();
    }

    ngDoCheck() {
        this.token = this._userService.getToken();
    }

    Eliminar(i) {
        $("#" + i).remove();
    }

    CloseModal() {
        this.modal.close('Cerrar');
    }

    getSistemasInspeccion() {
        this.SpinnerService.show();
        this._auditsService.getSistemaInspeccionTecnica(this.token)
            .subscribe({
                next: (resp) => { this.components = resp.data },
                error: (error) => { 
                    if (error.status != 401) {
                        this.SpinnerService.hide();
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            width: 400,
                            text: error.error.message,
                            footer: '<a href="">¿Por qué tengo este problema?</a>'
                        });
                    } else if (error.status == 401) {
                        this.SpinnerService.hide();
                        Swal.fire({
                            icon: "warning",
                            title: "Sesión Expirada",
                            text: 'Su sesión ha finalizado'
                        });
                        this._router.navigate(['logout/1']);
                    }
                },
                complete: () => {
                    this.SpinnerService.hide();
                }
            });
    }

    GrabarInspeccion() {
        var aprobados = 0;
        var reprobado = 0;
        this.components.forEach(element => {
            if (element.cumple == "1") {
                aprobados++;
            } else {
                if (element.nocumple == "1")
                    reprobado++;
            }

        });


        if (aprobados == 0 && reprobado == 0) {
            return;
        }

        var request: any = {
            "ppu": this.firstFormGroup.get('ppu').value,
            "fecha_inicio": this.firstFormGroup.get('fecha_inicio').value,
            "hora_inicio": this.firstFormGroup.get('hora_inicio').value,
            "fecha_termino": this.firstFormGroup.get('fecha_termino').value,
            "hora_termino": this.firstFormGroup.get('hora_termino').value,
            "km": this.firstFormGroup.get('km').value,
            "taller": this.firstFormGroup.get('lugar_inspeccion').value,
            "observaciones": this.firstFormGroup.get('observaciones')?.value,
            "estado": this.resultado,
            "componentes": this.components,
            "resumen": this.resumen
        }
        this.SpinnerService.show();
        this._auditsService.createAuditInspection(this.token, request)
            .subscribe({
                next: (resp) => {
                    if (resp.status == 'success') {
                        Swal.fire("Inspección Técnica ingresada correctamente", '', 'success')
                        this.modal.close('Yes');
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            width: 400,
                            text: resp.error,
                            footer: '<a href="">¿Por qué tengo este problema?</a>'
                    });
                }

                },
                error: (error) => {
                    if (error.status != 401) {
                        this.SpinnerService.hide();
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            width: 400,
                            text: error.error.message,
                            footer: '<a href="">¿Por qué tengo este problema?</a>'
                        });
                    } else if (error.status == 401) {
                        this.SpinnerService.hide();
                        Swal.fire({
                            icon: "warning",
                            title: "Sesión Expirada",
                            text: 'Su sesión ha finalizado'
                        });
                        this._router.navigate(['logout/1']);
                    }
                },
                complete: () => {
                    this.SpinnerService.hide();
                }
            })




    }

    // Cumple($event, id_sistema, id_componente, i){

    //     if(this.components[i].cumple == "1"){
    //         $event.target.checked = false;
    //         this.components[i].cumple=0;
    //         this.components[i].nocumple=0;
    //         this.components[i].estado_inspeccion = "";
    //         this.components[i].nueva_certificacion = false;
    //     }else{
    //         if($event.target.checked){
    //             this.components[i].nocumple=0;
    //             this.components[i].nueva_certificacion = false;
    //             this.components[i].estado_inspeccion = "APROBADO";
    //             var comp = 'reinspeccion' + id_sistema + id_componente;
    //             let element  =  document.getElementById(comp) as HTMLInputElement; 
    //             element.checked = false;
    //         }
    //         else{
    //             this.components[i].cumple=0;
    //             this.components[i].estado_inspeccion = "";
    //         }
    //     }

    // }

    // NoCumple($event, id_sistema, id_componente, i){
    //     if(this.components[i].cumple == "1"){
    //         this.components[i].cumple = 0;
    //     }
    //     if(this.components[i].nocumple == "1"){
    //         $event.target.checked = false;
    //         this.components[i].cumple=0;
    //         this.components[i].nocumple=0;
    //         this.components[i].estado_inspeccion = "";
    //         this.components[i].nueva_certificacion = false;
    //     }else{
    //         if($event.target.checked){
    //             this.components[i].nueva_certificacion = true;
    //             var comp = 'reinspeccion' + id_sistema + id_componente;
    //             let element  =  document.getElementById(comp) as HTMLInputElement; 
    //             element.checked = true;
    //             this.components[i].estado_inspeccion = "REPROBADO";
    //         }
    //         else{
    //             this.components[i].nueva_certificacion = false;
    //             this.components[i].estado_inspeccion = "";
    //         }
    //     }
    // }

    loadBuses() {

        this._BusService.getBuses(this.token)
            .subscribe({
                next: (response) => {
                    this.buses = response.data as Bus[];
                    //this.setPage(1);
                    this.SpinnerService.hide();
                },
                error: (error) => {
                    if (error.status == 401) {
                        this.SpinnerService.hide();
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            width: 400,
                            text: error.error.message,
                            footer: '<a href="">¿Por qué tengo este problema?</a>'
                        });
                    } else if (error.status == 401) {
                        this.SpinnerService.hide();
                        Swal.fire({
                            icon: "warning",
                            title: "Sesión Expirada",
                            text: 'Su sesión ha finalizado'
                        });
                        this._router.navigate(['logout/1']);
                    }
                },
                complete: () => {

                }

            });
    }

    SelectAll($event, cumple) {
        let sys: Sistemas;

        this.elementos_inspeccionados = 0;
        if ($event.target.checked) {
            this.components.forEach(element => {

                if (cumple) {
                    element.cumple = true;
                    element.nocumple = false;
                    this.AllCumple = true;
                    this.AllNoCumple = false;
                    this.elementos_inspeccionados += 1;
                    this.criticidad_baja = 0;
                    this.criticidad_media = 0;
                    this.criticidad_alta = 0;
                    this.sistems[element.id_sistema - 1] = { "id": element.id_sistema, "description": element.descripcion_sistema };
                    this.total_sistemas_baja_cumple[element.id_sistema - 1] += 1;
                    this.total_sistemas_media_cumple[element.id_sistema - 1] += 1;
                    this.total_sistemas_alta_cumple[element.id_sistema - 1] += 1;
                    this.resultado = "A"
                    this.total_sistemas_baja[element.id_sistema - 1] = 0;
                    this.total_sistemas_media[element.id_sistema - 1] = 0;
                    this.total_sistemas_alta[element.id_sistema - 1] = 0;
                } else {
                    element.cumple = false;
                    element.nocumple = true;
                    this.AllCumple = false;
                    this.AllNoCumple = true;
                    this.resultado = "R"
                    this.elementos_inspeccionados += 1;

                    this.sistems[element.id_sistema - 1] = { "id": element.id_sistema, "description": element.descripcion_sistema };

                    if (element.criticidad == 'Alta') {
                        this.total_sistemas_alta[element.id_sistema - 1] = this.total_sistemas_alta[element.id_sistema - 1] + 1;
                        this.criticidad_alta += 1;
                    }
                    if (element.criticidad == 'Media') {
                        this.total_sistemas_media[element.id_sistema - 1] = this.total_sistemas_media[element.id_sistema - 1] + 1;
                        this.criticidad_media += 1;
                    }
                    if (element.criticidad == 'Baja') {
                        this.total_sistemas_baja[element.id_sistema - 1] = this.total_sistemas_baja[element.id_sistema - 1] + 1
                        this.criticidad_baja += 1;
                    }
                }

            });
        } else {
            this.resultado = "A"
            this.components.forEach(element => {
                element.cumple = false;
                element.nocumple = false;
                this.AllCumple = false;
                this.AllNoCumple = false;
                this.elementos_inspeccionados = 0;
                this.criticidad_baja = 0;
                this.criticidad_media = 0;
                this.criticidad_alta = 0;
                this.criticidad_baja_cumple = 0;
                this.criticidad_media_cumple = 0;
                this.criticidad_alta_cumple = 0;
                this.sistemas = [];
                this.sistems = [];
                this.total_sistemas_baja = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                this.total_sistemas_media = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                this.total_sistemas_alta_cumple = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                this.total_sistemas_baja_cumple = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                this.total_sistemas_media_cumple = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                this.total_sistemas_alta_cumple = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            });
        }

        


    }

    Cumple($event, i) {


        if (this.components[i].nocumple) {
            if (this.components[i].criticidad == 'Alta') {
                this.total_sistemas_alta[this.components[i].id_sistema - 1] = this.total_sistemas_alta[this.components[i].id_sistema - 1] - 1;
                this.criticidad_alta -= 1;
            }
            if (this.components[i].criticidad == 'Media') {
                this.total_sistemas_media[this.components[i].id_sistema - 1] = this.total_sistemas_media[this.components[i].id_sistema - 1] - 1;
                this.criticidad_media -= 1;
            }
            if (this.components[i].criticidad == 'Baja') {
                this.total_sistemas_baja[this.components[i].id_sistema - 1] = this.total_sistemas_baja[this.components[i].id_sistema - 1] - 1;
                this.criticidad_baja -= 1;
            }
            this.elementos_inspeccionados -= 1;
            if (this.criticidad_alta == 0 && this.criticidad_media == 0 && this.criticidad_baja == 0)
                this.sistems.splice(this.components[i].id_sistema - 1, 1);
        }

        if (this.components[i].cumple) {
            $event.target.checked = false;
            this.components[i].cumple = false;

            if (this.components[i].criticidad == 'Alta') {
                this.total_sistemas_alta_cumple[this.components[i].id_sistema - 1] = this.total_sistemas_alta_cumple[this.components[i].id_sistema - 1] - 1;
                this.criticidad_alta_cumple -= 1;
            }
            if (this.components[i].criticidad == 'Media') {
                this.total_sistemas_media_cumple[this.components[i].id_sistema - 1] = this.total_sistemas_media_cumple[this.components[i].id_sistema - 1] - 1;
                this.criticidad_media_cumple -= 1;
            }
            if (this.components[i].criticidad == 'Baja') {
                this.total_sistemas_baja_cumple[this.components[i].id_sistema - 1] = this.total_sistemas_baja_cumple[this.components[i].id_sistema - 1] - 1;
                this.criticidad_baja_cumple -= 1;
            }
            this.elementos_inspeccionados -= 1;
            if (this.total_sistemas_alta_cumple[this.components[i].id_sistema - 1] == 0 && this.total_sistemas_media_cumple[this.components[i].id_sistema - 1] == 0
                && this.total_sistemas_baja_cumple[this.components[i].id_sistema - 1] == 0)
                this.sistems[this.components[i].id_sistema - 1] = null;

            this.elementos_inspeccionados -= 1;

        } else
            if ($event.target.checked) {
                this.sistems[this.components[i].id_sistema - 1] = { "id": this.components[i].id_sistema, "description": this.components[i].descripcion_sistema };
                this.components[i].nocumple = false;

                if (this.components[i].criticidad == 'Alta') {
                    this.total_sistemas_alta_cumple[this.components[i].id_sistema - 1] = this.total_sistemas_alta_cumple[this.components[i].id_sistema - 1] + 1;
                    this.criticidad_alta_cumple += 1;
                }
                if (this.components[i].criticidad == 'Media') {
                    this.total_sistemas_media_cumple[this.components[i].id_sistema - 1] = this.total_sistemas_media_cumple[this.components[i].id_sistema - 1] + 1;
                    this.criticidad_media_cumple += 1;
                }
                if (this.components[i].criticidad == 'Baja') {
                    this.total_sistemas_baja_cumple[this.components[i].id_sistema - 1] = this.total_sistemas_baja_cumple[this.components[i].id_sistema - 1] + 1;
                    this.criticidad_baja_cumple += 1;
                }
                this.elementos_inspeccionados += 1;
            } else {
                if (this.components[i].criticidad == 'Alta') {
                    this.total_sistemas_alta_cumple[this.components[i].id_sistema - 1] = this.total_sistemas_alta_cumple[this.components[i].id_sistema - 1] - 1;
                    this.criticidad_alta_cumple -= 1;
                }
                if (this.components[i].criticidad == 'Media') {
                    this.total_sistemas_media_cumple[this.components[i].id_sistema - 1] = this.total_sistemas_media_cumple[this.components[i].id_sistema - 1] - 1;
                    this.criticidad_media_cumple -= 1;
                }
                if (this.components[i].criticidad == 'Baja') {
                    this.total_sistemas_baja_cumple[this.components[i].id_sistema - 1] = this.total_sistemas_baja_cumple[this.components[i].id_sistema - 1] - 1;
                    this.criticidad_baja_cumple -= 1;
                }
                this.elementos_inspeccionados -= 1;
                if (this.criticidad_alta_cumple == 0 && this.criticidad_media_cumple == 0 && this.criticidad_baja_cumple == 0)
                    this.sistems.splice(this.components[i].id_sistema - 1, 1);
                if (this.criticidad_alta == 0 && this.criticidad_media == 0 && this.criticidad_baja == 0)
                    this.sistems.splice(this.components[i].id_sistema - 1, 1);
            }




       


    }

    NoCumple($event, i) {

        if (this.components[i].cumple) {
            this.components[i].cumple = false;

            this.components[i].nocumple = true;

            if (this.components[i].criticidad == 'Alta') {
                this.total_sistemas_alta[this.components[i].id_sistema - 1] = this.total_sistemas_alta[this.components[i].id_sistema - 1] + 1;
                this.criticidad_alta += 1;
            }
            if (this.components[i].criticidad == 'Media') {
                this.total_sistemas_media[this.components[i].id_sistema - 1] = this.total_sistemas_media[this.components[i].id_sistema - 1] + 1;
                this.criticidad_media += 1;
            }
            if (this.components[i].criticidad == 'Baja') {
                this.total_sistemas_baja[this.components[i].id_sistema - 1] = this.total_sistemas_baja[this.components[i].id_sistema - 1] + 1;
                this.criticidad_baja += 1;
            }

        } else {
            if (this.components[i].nocumple) {
                $event.target.checked = false;
                this.components[i].nocumple = false;
                this.elementos_inspeccionados -= 1;
                if (this.components[i].criticidad == 'Alta') {
                    this.total_sistemas_alta[this.components[i].id_sistema - 1] = this.total_sistemas_alta[this.components[i].id_sistema - 1] - 1;
                    this.criticidad_alta -= 1;
                }
                if (this.components[i].criticidad == 'Media') {
                    this.total_sistemas_media[this.components[i].id_sistema - 1] = this.total_sistemas_media[this.components[i].id_sistema - 1] - 1;
                    this.criticidad_media -= 1;
                }
                if (this.components[i].criticidad == 'Baja') {
                    this.total_sistemas_baja[this.components[i].id_sistema - 1] = this.total_sistemas_baja[this.components[i].id_sistema - 1] - 1;
                    this.criticidad_baja -= 1;
                }
            } else
                if ($event.target.checked) {
                    this.sistems[this.components[i].id_sistema - 1] = { "id": this.components[i].id_sistema, "description": this.components[i].descripcion_sistema };
                    if (this.components[i].criticidad == 'Alta') {
                        this.total_sistemas_alta[this.components[i].id_sistema - 1] = this.total_sistemas_alta[this.components[i].id_sistema - 1] + 1;
                        this.criticidad_alta += 1;
                    }
                    if (this.components[i].criticidad == 'Media') {
                        this.total_sistemas_media[this.components[i].id_sistema - 1] = this.total_sistemas_media[this.components[i].id_sistema - 1] + 1;
                        this.criticidad_media += 1;
                    }
                    if (this.components[i].criticidad == 'Baja') {
                        this.total_sistemas_baja[this.components[i].id_sistema - 1] = this.total_sistemas_baja[this.components[i].id_sistema - 1] + 1;
                        this.criticidad_baja += 1;
                    }
                    this.elementos_inspeccionados += 1;
                } else {
                    if (this.components[i].criticidad == 'Alta') {
                        this.total_sistemas_alta[this.components[i].id_sistema - 1] = this.total_sistemas_alta[this.components[i].id_sistema - 1] - 1;
                        this.criticidad_alta -= 1;
                    }
                    if (this.components[i].criticidad == 'Media') {
                        this.total_sistemas_media[this.components[i].id_sistema - 1] = this.total_sistemas_media[this.components[i].id_sistema - 1] - 1;
                        this.criticidad_media -= 1;
                    }
                    if (this.components[i].criticidad == 'Baja') {
                        this.total_sistemas_baja[this.components[i].id_sistema - 1] = this.total_sistemas_baja[this.components[i].id_sistema - 1] - 1;
                        this.criticidad_baja -= 1;
                    }

                    this.elementos_inspeccionados -= 1;
                }

        }






        
    }

    InformeRevision() {

        this.resumen = [];
        let i = 0;
        this.sistems.forEach(element => {

            this.resumen.push({
                "id_sistema": element.id,
                "descripcion_sistema": element.description,
                "criticidad_alta": this.total_sistemas_alta[i],
                "criticidad_media": this.total_sistemas_media[i],
                "criticidad_baja": this.total_sistemas_baja[i],
                "resultado": this.total_sistemas_alta[i] == 0 && this.total_sistemas_media[i] == 0 && this.total_sistemas_baja[i] == 0,
                "reinspeccion": this.total_sistemas_alta[i] > 0 || this.total_sistemas_media[i] > 0 || this.total_sistemas_baja[i] > 0
            });

            i += 1;

        }
        );


        if (this.elementos_inspeccionados > 0) {

            if (this.resultado == 'A') {
                this.reinspeccion = false;
            } else {
                this.reinspeccion = true;
            }

        } else {
            if (this.observaciones != "") {
                this.resultado = "R";
                this.reinspeccion = true;
            }
        }

   
    }
}
