import { AfterViewInit, Component, OnInit, DoCheck, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { Subject } from 'rxjs';
import { dataTableSettingSupportsLocal } from 'src/app/models/dictionary';
import { AddClaimComponent } from './modal/add-request/add-claim.component';
import { ClaimsService } from 'src/app/services/claims.services';


@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.css'],
  providers: []
})
export class ClaimsComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public token;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ot:string="";
  ppu:string="";
  estado:string="";
  requests:any[]=[];
  datefrom="";
  dateto="";
  constructor(private modalService: NgbModal,
    private SpinnerService: NgxSpinnerService,
    private _userService: UserService,
    private _ClaimsService:ClaimsService,
    private _router: Router){

  }
  ngOnInit() {
    this.token = this._userService.getToken();
    this.dtOptions = dataTableSettingSupportsLocal;
  }

  ngAfterViewInit(): void {    
    this.dtTrigger.next(0);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngDoCheck(): void {
    this.token = this._userService.getToken();
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

  addSupportRequest() {
    const modalRef = this.modalService.open(AddClaimComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.name = "Nueva Solicitud";

    modalRef.result.then(
      (result) => {
        var res = result.split(";", 2);
        if (res[0] == "Yes") {
          this.getSupportRequest();
        }
      },
      (cancel) => {
      }
    );
  }

  limpiar(){
    this.ot="";
    this.ppu="";
    this.estado="";
    this.requests=[];
    this.datefrom="";
    this.dateto="";
    this.reDraw();
  }

  getSupportRequest(){
    this.SpinnerService.show();
    this._ClaimsService.getRequests(this.token, this.ot, this.ppu, this.datefrom, this.dateto, this.estado)
    .subscribe({
      next:(resp)=>{
        if (resp.status=='success'){
         this.requests = resp.data;
        }
        else{
          this.requests = [];
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            width: 400,
            text: "Se ha producido un error al obtener las solicitudes",
            footer: '<a href="">¿Por qué tengo este problema?</a>'
          });
        }


        this.reDraw();
      },
      error:(error)=>{
          this.SpinnerService.hide();
          this.requests = [];
          if (error.status != 401) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"});
              this._router.navigate(['logout/1']);
          }
        
      },
      complete:()=>{
        this.SpinnerService.hide();
      }
    })
  }

  deleteSupportRequest(id){
    Swal.fire({
      title: "Eliminar Reclamo",
      text: "¿Estas seguro(a) de que desea continuar?",
      icon: "error",
      width: "500px",
      heightAuto: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._ClaimsService.deleteRequest(this.token, id)
        .subscribe({
          next:(res) => {

          if (res.status == 'success') {
            Swal.fire("Solicitud Eliminada Con Exito!", "", "success");
            this.getSupportRequest();
          } else {            
            Swal.fire({
              icon: "error",
              title: "Ha ocurrido el siguiente error:",
              text: res.message,
            });         
          }
        },
        error:(error)=>{
          this.SpinnerService.hide();
          if (error.status != 401) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"});
              this._router.navigate(['logout/1']);
          }
        },
        complete:()=>{
          this.SpinnerService.hide();
        }
      });
      }
    });
  }

  AcceptSupportResponse(id){
    Swal.fire({
      title: "Registrar Respuesta de Falla",
      input: "text",
      inputPlaceholder: "DD-MM-YYYY",
      text: "Ingrese Fecha Recepcion (DD-MM-YYYY)",
      icon: "success",
      width: "500px",
      heightAuto: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Continuar!",
      cancelButtonText: "Cancelar",
      inputValidator: datefrom => {
        // Si el valor es válido, debes regresar undefined. Si no, una cadena
        if (!datefrom) {
            return "Por favor ingrese Fecha Recepción";
        } else {
            return undefined;
        }
    }
    }).then((result) => {
      if (result.isConfirmed) {
        this.SpinnerService.show();
        var request = {
          "id" : id,
          "datefrom" : result.value.datefrom,
          "detalle" : result.value.detalle
        }

        
        this._ClaimsService.updateRequest(this.token, request)
        .subscribe({
          next:(res) => {
            if (res.status == 'success') {
              Swal.fire("Recepción realizada Con Exito!", "", "success");
              this.getSupportRequest();
            } else {            
              Swal.fire({
                icon: "error",
                title: "Ha ocurrido el siguiente error:",
                text: res.message,
              });         
            }
        },
        error:(error)=>{
          this.SpinnerService.hide();
          if (error.status != 401) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"});
              this._router.navigate(['logout/1']);
          }
        },
        complete:()=>{
          this.SpinnerService.hide();

        }
      });
      }
    });
  }

}
