import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TabsModule } from 'ngx-bootstrap/tabs'
import { MaterialModule } from 'src/material.module';
import { MaintenanceRoutingModule } from './maintenance-routing.module';
import { MaintenanceComponent } from './maintenance.component';
//import { UserService } from '../services/user.service';
import { UserGuard } from '../services/user.guard';
import { RegisterComponent } from './views/register/register.component';
import { RegisterMaintenanceComponent } from './views/register/modal/register-maintenance/register-maintenance.component';
import { ConsultarComponent } from './views/consultar/consultar.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';
import { ImportarComponent } from './views/importar/importar.component';
import {MatTabsModule} from '@angular/material/tabs';
import { PagerService } from '../services/pager.services';
import { UserService } from '../services/user.services';
import { MaintenanceService } from '../services/maintenance.services';
import { BusService } from '../services/bus.services';
import { DataTablesModule } from 'angular-datatables';
import { UploadOTComponent } from './views/consultar/modal/upload-ot.component';
@NgModule({
  declarations: [
    MaintenanceComponent,
    RegisterComponent,
    RegisterMaintenanceComponent,
    ConsultarComponent,
    ImportarComponent,
    UploadOTComponent
    ,
  ],
  imports: [
    MaintenanceRoutingModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxSpinnerModule,
    NgSelectModule,
    TabsModule.forRoot(),
    MatTabsModule,
    DataTablesModule.forRoot(),
  ],
  exports: [
    MaintenanceComponent,
  ],
  providers: [
   // UserService,
    UserGuard,
    PagerService, MaintenanceService, UserService, DatePipe, BusService
  ],
    bootstrap: [MaintenanceComponent, ConsultarComponent, ImportarComponent, UploadOTComponent ],
})
export class MaintenanceModule { }