import { Component, OnInit, DoCheck } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Bus } from 'src/app/models/bus';
import { Plan } from 'src/app/models/plan';
import { RegisterMaintenanceComponent } from './modal/register-maintenance/register-maintenance.component';
import { BusService } from 'src/app/services/bus.services';
import { PlanService } from 'src/app/services/plan.services';
import { PagerService } from 'src/app/services/pager.services';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-maintenance',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [UserService, BusService, PlanService, PagerService]
})
export class RegisterComponent implements OnInit, DoCheck {

  public buses: Bus[] = [];
  public plans: Plan[] = [];
  pager: any = {};
  // paged items
  pagedItems: any[];
  totalxPage: number = 10;
  public _codigo: string;
  selectedPpu: string;
  public token;
  constructor(
    private modalService: NgbModal,
    private SpinnerService: NgxSpinnerService,
    private _BusService: BusService,
    private _PlanService: PlanService,
    private _pagerService: PagerService,
    private _UserService: UserService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.token = this._UserService.getToken();
    this.loadBuses();
    this.loadPlanes();
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  loadPlanes(codigo = null) {
    this.SpinnerService.show();
    this._PlanService.getPlanes(this.token, codigo)
      .subscribe(response => {
        this.plans = (response.data) as Plan[];
        this.setPage(1);
        this.SpinnerService.hide();
      },
        error => {
          if (error.status == 404) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado'
            });
            this._router.navigate(['logout/1']);
          }
        }
      );
  }

  loadBuses() {

    this._BusService.getBuses(this.token)
      .subscribe(
        response => {
          this.buses = response.data as Bus[];
          //this.setPage(1);
          this.SpinnerService.hide();
        },
        error => {
          if (error.status == 404) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado'
            });
            this._router.navigate(['logout/1']);
          }
        }

      );
  }

  SearchPlan() {
    this.SpinnerService.show();
    this.pager = {};
    this.loadPlanes(this._codigo);

    /*if (this._codigo.length>0){
      
     // this.datafilter = this.plans.filter(x=> x.codigo.toUpperCase().includes((this._codigo.toUpperCase())));
    }
    else 
*/

  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    // get pager object from service
    this.pager = this._pagerService.getPager(this.plans.length, page, +this.totalxPage);

    // get current page of items
    this.pagedItems = this.plans.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  RegisterMantenance(plan: Plan) {
    if (this.selectedPpu != null) {
      const modalRef = this.modalService.open(RegisterMaintenanceComponent, {
        centered: true,
        size: "lg",
      });
      modalRef.componentInstance.name = this.selectedPpu;
      modalRef.componentInstance.ppu = this.selectedPpu;
      modalRef.componentInstance.codigo_plan = plan.codigo_plan;
      modalRef.componentInstance.categoria = plan.categoria;
      modalRef.result.then(
        (result) => {
          /* var res = result.split(";", 2);
            if (res[0] == "Yes") {
            }*/
          if (result != 'Cerrar') {
          }

          //this.ngOnInit();
        },
        (cancel) => {
        }
      );
    }
    else
      Swal.fire("Debe Seleccionar un vehículo", "", "error");
  }

}
