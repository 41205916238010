import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TechnicalReviewRoutingModule } from './technical-review-routing.module';
import { TechnicalReviewComponent } from './technical-review.component';
import { UserService } from '../services/user.services';
import { UserGuard } from '../services/user.guard';
import { TechnicalReviewService } from '../services/technical-review.services';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BusService } from 'src/app/services/bus.services';
import { NgSelectModule } from '@ng-select/ng-select';
import { MaterialModule } from 'src/material.module';
import { PagerService } from '../services/pager.services';
import { MaintenanceService } from '../services/maintenance.services';
import { DatePipe } from '@angular/common';
import { TechnicalReviewRegisterComponent } from './views/technical-review-register/technical-review-register.component';
import { TechnicalReviewGetComponent } from './views/technical-review-get/technical-review-get.component';
import { TechnicalReviewImportComponent } from './views/technical-review-import/technical-review-import.component';
import { TechnicalReviewAddComponent } from './views/technical-review-register/modal/add-technical-review.component';
import { DataTablesModule } from 'angular-datatables';
@NgModule({
  declarations: [
    TechnicalReviewComponent,
    TechnicalReviewRegisterComponent,
    TechnicalReviewGetComponent,
    TechnicalReviewImportComponent,
    TechnicalReviewAddComponent
  ],
  imports: [
    TechnicalReviewRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgbModule,
    NgSelectModule,
    MaterialModule,
    DataTablesModule.forRoot(),
  ],
  providers: [
    UserService,
    UserGuard,
    TechnicalReviewService,
    BusService,
    PagerService, 
    MaintenanceService, 
    DatePipe
  ],
  bootstrap: [TechnicalReviewComponent,TechnicalReviewRegisterComponent, TechnicalReviewGetComponent, TechnicalReviewImportComponent, TechnicalReviewAddComponent]
})
export class TechnicalReviewModule { }