import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '../services/admin.guard';
import { PlansComponent } from './views/plans/plans.component';
import { UsersComponent } from './views/users/users.component';
import { BusesAdminComponent } from './views/buses/buses.component';
export const routes: Routes = [
  {
        path: 'admin',
        canActivate: [AdminGuard],
        children:[
      { path: 'buses',component: BusesAdminComponent },
      { path: 'users',component: UsersComponent },
      { path: 'plans',component: PlansComponent },
      { path: '', redirectTo: 'users', pathMatch: 'full' },
      { path: '**', redirectTo: '404' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports : [ RouterModule ]
})
export class AdminRoutingModule { }
