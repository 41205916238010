import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../services/user.guard';
import { TechnicalReviewGetComponent } from './views/technical-review-get/technical-review-get.component';
import { TechnicalReviewImportComponent } from './views/technical-review-import/technical-review-import.component';
import { TechnicalReviewRegisterComponent } from './views/technical-review-register/technical-review-register.component';
export const routes: Routes = [
  {
        path: 'technicalreview',
        canActivate: [UserGuard],
        children:[
      { path: 'registrar', component: TechnicalReviewRegisterComponent },
      { path: 'importar', component: TechnicalReviewImportComponent },
      { path: 'consultar', component: TechnicalReviewGetComponent },
      { path: '', redirectTo: 'registrar', pathMatch: 'full' },
      { path: '**', redirectTo: '404' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports : [ RouterModule ]
})
export class TechnicalReviewRoutingModule { }
