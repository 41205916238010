// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.input-group>.input-group-prepend {
    flex: 0 0 30% !important;
  }

  .input-group .input-group-text {
    width: 100% !important;
    align-content:flex-end !important;
  }

  .drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    width: 70%;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }
  
  .drop-container:hover {
    background: #eee;
    border-color: #111;
  }
  
  .drop-container:hover .drop-title {
    color: #222;
  }
  
  .drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }`, "",{"version":3,"sources":["webpack://./src/app/certification/views/revision/modal/informe-egb.component.css"],"names":[],"mappings":";AACA;IACI,wBAAwB;EAC1B;;EAEA;IACE,sBAAsB;IACtB,iCAAiC;EACnC;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,UAAU;IACV,8DAA8D;EAChE;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,iCAAiC;EACnC","sourcesContent":["\r\n.input-group>.input-group-prepend {\r\n    flex: 0 0 30% !important;\r\n  }\r\n\r\n  .input-group .input-group-text {\r\n    width: 100% !important;\r\n    align-content:flex-end !important;\r\n  }\r\n\r\n  .drop-container {\r\n    position: relative;\r\n    display: flex;\r\n    gap: 10px;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 70px;\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    border: 2px dashed #555;\r\n    color: #444;\r\n    cursor: pointer;\r\n    width: 70%;\r\n    transition: background .2s ease-in-out, border .2s ease-in-out;\r\n  }\r\n  \r\n  .drop-container:hover {\r\n    background: #eee;\r\n    border-color: #111;\r\n  }\r\n  \r\n  .drop-container:hover .drop-title {\r\n    color: #222;\r\n  }\r\n  \r\n  .drop-title {\r\n    color: #444;\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n    text-align: center;\r\n    transition: color .2s ease-in-out;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
