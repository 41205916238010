import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";

import * as $ from 'jquery';
import * as bootstrap from "bootstrap";

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { UserService } from '../services/user.services';
import { AdminGuard } from '../services/admin.guard';
import { UsersComponent } from './views/users/users.component';
import { PlansComponent } from './views/plans/plans.component';
import { UserComponent } from './views/users/modal/register-user/register-user.component';
import { PlanComponent } from './views/plans/modal/register-plan/register-plan.component';
import { BusesAdminComponent } from './views/buses/buses.component';
import { BusComponent } from './views/buses/modal/register-bus/register-bus.component';
import { DeleteBusComponent } from './views/buses/modal/delete-bus/deletebus.component';
import { DataTablesModule } from 'angular-datatables';
import { PlanService } from '../services/plan.services';
import { BusService } from '../services/bus.services';


@NgModule({
  declarations: [
    AdminComponent,
    UsersComponent,
    PlansComponent,
    UserComponent,
    PlanComponent,
    BusesAdminComponent,
    BusComponent,
    DeleteBusComponent
  ],
  imports: [
    AdminRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    HttpClientModule,
    DataTablesModule.forRoot(),
  ],
  exports: [
    AdminComponent,
  ],
  providers: [
   UserService,
   AdminGuard,
   PlanService,
   BusService
  ],
    bootstrap: [AdminComponent],
})
export class AdminModule { }