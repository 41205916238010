
export var itemsAdmin = [
  {
    text: 'Buses',
    url: '/buses',
    type: 'primary',
    active: 'true',
    subMenu: [
      // { text: 'Listado de Buses', url : '/lists-buses', type: 'primary' },
      //{ text: 'Dashboard', url: '/dashboard', type: 'primary', active: 'false' },
      { text: 'Hoja de Vida', url: '/hoja-vida', type: 'primary', active: 'true' },
      { text: 'Informes', url: '/report-bus', type: 'primary', active: 'false' },
      { text: 'Certificaciones', url: '/status-certification', type: 'primary', active: 'false' }
    ]
  },
  {
    text: 'Revision Tecnica',
    url: '/technicalreview',
    type: 'primary',
    active: 'false',
    subMenu: [
     // { text: 'Consultar', url: '/consultar', type: 'primary' , active: 'true'},
      { text: 'Registrar', url: '/registrar', type: 'primary' , active: 'true'},
     // { text: 'Importar', url: '/importar', type: 'primary' , active: 'false'},
    ]

  },
  {
    text: 'Mantenciones',
    url: '/maintenance',
    type: 'primary',
    active: 'false',
    subMenu: [
      { text: 'Consultar', url: '/consultar', type: 'primary', active: 'true' },      
     // { text: 'Registrar', url: '/register', type: 'primary' },
      { text: 'Importar', url: '/importar', type: 'primary' }
     
    ]
  },
  {
    text: 'Soporte Local',
    url: '/supports',
    type: 'primary',
    active: 'false',
    subMenu: [
      { text: 'Solicitud Repuestos', url: '/requests', type: 'primary', active: 'true' },
      { text: 'Entrega Repuestos', url: '/deliveries', type: 'primary' },
      { text: 'Solicitud Atención', url: '/repairs', type: 'primary' },
      { text: 'Resolución Fallas', url: '/resolution', type: 'primary'},
      
     
    ]
  },
  {
    text: 'Auditorias',
    url: '/audit',
    type: 'primary',
    active: 'false',
    subMenu: [
      { text: 'Auditoría Documental', url: '/documentary-audit', type: 'primary', active: 'true' },
      { text: 'Inspección Técnica', url: '/technical-inspection', type: 'primary', active: 'false'  },
      { text: 'Estado General del Bus', url: '/review-status', type: 'primary', active: 'false' }

    ]
  },
  {
    text: 'Certificación',
    url: '/certification',
    type: 'primary',
    active: 'false',
    subMenu: [
      { text: 'Auditoría Documental', url: '/documental-audit', type: 'primary', active: 'true' },
      { text: 'Inspección Técnica', url: '/technical-inspection', type: 'primary', active: 'false'  },
      { text: 'Estado General del Bus', url: '/review-status', type: 'primary', active: 'false' }

    ]

  },
  {
    text: 'Administración',
    url: '/admin',
    type: 'secondary',
    subMenu: [
      { text: 'Usuarios', url: '/users', type: 'secondary' , active:'true' },
      { text: 'Buses', url: '/buses', type: 'secondary' },
      { text: 'Planes', url: '/plans', type: 'secondary' },
    ]
  }

];

export var itemsUsersOperador = [
  {
    text: 'Buses',
    url: '/buses',
    type: 'primary',
    active: 'true',
    subMenu: [
      // { text: 'Listado de Buses', url : '/lists-buses', type: 'primary' },
     // { text: 'Dashboard', url: '/dashboard', type: 'primary', active: 'true' },
      { text: 'Hoja de Vida', url: '/hoja-vida', type: 'primary', active:'true' },
      { text: 'Informes', url: '/report-bus', type: 'primary', active: 'false' },
      { text: 'Certificaciones', url: '/status-certification', type: 'primary', active: 'false' }
    ]
  },
  {
    text: 'Mantenciones',
    url: '/maintenance',
    type: 'primary',
    active: 'false',
    subMenu: [
      //{ text: 'Registrar', url: '/register', type: 'primary' },
      { text: 'Consultar', url: '/consultar', type: 'primary', active:'true' },
      { text: 'Importar', url: '/importar', type: 'primary' }
    ]
  },  {
    text: 'Revisión Tecnica',
    url: '/technicalreview',
    type: 'primary',
    active: 'false',
    subMenu: [
      //{ text: 'Consultar', url: '/consultar', type: 'primary' , active: 'true'},
      { text: 'Registrar', url: '/registrar', type: 'primary' , active: 'true'},
      //{ text: 'Importar', url: '/importar', type: 'primary' , active: 'false'},
      
    ]

  },
  {
    text: 'Soporte Local',
    url: '/supports',
    type: 'primary',
    active: 'false',
    subMenu: [
      { text: 'Solicitud Repuestos', url: '/requests', type: 'primary', active: 'true' },
      { text: 'Solicitud Atención', url: '/repairs', type: 'primary' },      
     
    ]
  },


];

export var itemsUsersCertificador = [
  {
    text: 'Buses',
    url: '/buses',
    type: 'primary',
    active: 'true',
    subMenu: [
      // { text: 'Listado de Buses', url : '/lists-buses', type: 'primary' },
      //{ text: 'Dashboard', url: '/dashboard', type: 'primary', active: 'true' },
      { text: 'Hoja de Vida', url: '/hoja-vida', type: 'primary', active: 'true' },
      { text: 'Informes', url: '/report-bus', type: 'primary', active: 'false' },
      { text: 'Certificaciones', url: '/status-certification', type: 'primary', active: 'false' }
    ]
  },
  {
    text: 'Auditorias',
    url: '/audit',
    type: 'primary',
    active: 'false',
    subMenu: [
      { text: 'Auditoría Documental', url: '/documentary-audit', type: 'primary', active: 'true' },
      { text: 'Inspección Técnica', url: '/technical-inspection', type: 'primary', active: 'false' },
      { text: 'Estado General del Bus', url: '/review-status', type: 'primary', active: 'false' }

    ]
  },
  {
    text: 'Certificación',
    url: '/certification',
    type: 'primary',
    active: 'false',
    subMenu: [
      { text: 'Auditoría Documental', url: '/documental-audit', type: 'primary', active: 'true' },
      { text: 'Inspección Técnica', url: '/technical-inspection', type: 'primary', active: 'false' },
      { text: 'Estado General del Bus', url: '/review-status', type: 'primary', active: 'false' }

    ]
  },
  {
    text: 'Soporte Local',
    url: '/supports',
    type: 'primary',
    active: 'false',
    subMenu: [
      { text: 'Entrega Repuestos', url: '/deliveries', type: 'primary' },
      { text: 'Resolución Fallas', url: '/resolution', type: 'primary'},
      
     
    ]
  },

];
