import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { TechnicalReviewService } from 'src/app/services/technical-review.services';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-technical-review-add',
  templateUrl: './add-technical-review.component.html',
  styleUrls: ['./add-technical-review.component.css'],
})
export class TechnicalReviewAddComponent implements OnInit {

  public token;
  public identity;
  public ppu: string = "";
  public datefrom:string="";
  public dateto:string=""
  public status:string=""
  file:File;
  public base64;
  addRevisionTecnicaForm: FormGroup;
    constructor(
    public modal: NgbActiveModal,
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private formBuilder: FormBuilder,
    private _TechnicalReviewService: TechnicalReviewService,
    private _router: Router,
  ) {

    this.addRevisionTecnicaForm = this.formBuilder.group({
      ppu: new FormControl("", Validators.required),
      datefrom: new FormControl("", Validators.required),
      dateto: new FormControl("", Validators.required),
      status: new FormControl("", Validators.required),
      base64: new FormControl("", Validators.required),
    });


    }

    get form() {
      return this.addRevisionTecnicaForm.controls;
    }

    ngOnInit() {
    this.token = this._UserService.getToken();
    this.identity = this._UserService.getIdentity();
    registerLocaleData(es);
    }
    SaveTechnicalReview(){
        
        var request:any = {
            "ppu": this.addRevisionTecnicaForm.get('ppu').value,
            "monthfrom": this.addRevisionTecnicaForm.get('datefrom').value,
            "monthto": this.addRevisionTecnicaForm.get('dateto').value,
            "status": this.addRevisionTecnicaForm.get('status').value,
            "evidencia":  this.addRevisionTecnicaForm.get('base64').value,
        }
        this.SpinnerService.show();
        this._TechnicalReviewService.addTechnicalReview(this.token, request)
        .subscribe({ 
            next:(response) => {
              if(response.status == "success"){
                this.SpinnerService.hide();
                this.limpiar();
                Swal.fire("Revisión Técnica registrada", "", "success");
                this.modal.close('Yes');
            }else {
                Swal.fire("Debe completar todos los campos", response.message, "error");
                this.SpinnerService.hide();
            }
        
            },
            error:(error)=> {
              console.error(error)
              this.SpinnerService.hide();
              if (error.status != 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  width: 400,
                  text: error.error.message,
                  footer: '<a href="">¿Por qué tengo este problema?</a>'
                });
              } else if (error.status == 401) {
                Swal.fire({
                  icon: "warning",
                  title: "Sesión Expirada",
                  text: 'Su sesión ha finalizado'
                });
                this._router.navigate(['logout/1']);
              }
            },
            complete: () => {
              console.info('complete');
              this.SpinnerService.hide();
            }
        });
    }
    

    uploadFile($event) {
    var file = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
    this.base64 = reader.result;
    this.addRevisionTecnicaForm.controls['base64'].setValue(this.base64);
    };
    }

    limpiar(){
    this.ppu = "";
    this.base64="";
    $("#file").val('');
    this.addRevisionTecnicaForm.reset();
    }

    CloseModal() {
        this.modal.close('Cerrar');
    }

}
