// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group-prepend-25 {
  flex: 0 0 20% !important;
}

.input-group-text {
  align-content:flex-end !important;
}

div.dt-buttons {
  float: right !important;
}


.dataTables_wrapper .dt-buttons {
  float:none;
  text-align:right;
  margin: 0.3%;
}

table.dataTable tbody th, table.dataTable tbody td {
  padding: 4px 8px !important;
  font-family: "tahoma" !important;
  font-family: Barlow,sans-serif;
  font-size: 14px;
  color: #676a6c;
  overflow-x: hidden;
}

.dataTables_wrapper .dataTables_paginate .paginate_button{
  padding: 0.0em 0.0em !important;
  display: contents !important;
}


.dataTables_wrapper .dataTables_length select {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: transparent;
    margin: 3%;
}

div.dataTables_wrapper div.dataTables_length select{
  width:50px !important;
}


.table-default {
  border-collapse: separate;
  border-spacing: 0;
}

.dataTables_filter {
  float: right !important;
}
  
 .wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  text-align:center;
  transform: translateX(-50%);
}

`, "",{"version":3,"sources":["webpack://./src/app/buses/views/report-bus/report-bus.component.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,uBAAuB;AACzB;;;AAGA;EACE,UAAU;EACV,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,gCAAgC;EAChC,8BAA8B;EAC9B,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,4BAA4B;AAC9B;;;AAGA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,UAAU;AACd;;AAEA;EACE,qBAAqB;AACvB;;;AAGA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB;;CAEC;EACC,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,iBAAiB;EACjB,2BAA2B;AAC7B","sourcesContent":[".input-group-prepend-25 {\r\n  flex: 0 0 20% !important;\r\n}\r\n\r\n.input-group-text {\r\n  align-content:flex-end !important;\r\n}\r\n\r\ndiv.dt-buttons {\r\n  float: right !important;\r\n}\r\n\r\n\r\n.dataTables_wrapper .dt-buttons {\r\n  float:none;\r\n  text-align:right;\r\n  margin: 0.3%;\r\n}\r\n\r\ntable.dataTable tbody th, table.dataTable tbody td {\r\n  padding: 4px 8px !important;\r\n  font-family: \"tahoma\" !important;\r\n  font-family: Barlow,sans-serif;\r\n  font-size: 14px;\r\n  color: #676a6c;\r\n  overflow-x: hidden;\r\n}\r\n\r\n.dataTables_wrapper .dataTables_paginate .paginate_button{\r\n  padding: 0.0em 0.0em !important;\r\n  display: contents !important;\r\n}\r\n\r\n\r\n.dataTables_wrapper .dataTables_length select {\r\n    border: 1px solid #aaa;\r\n    border-radius: 3px;\r\n    padding: 5px;\r\n    background-color: transparent;\r\n    margin: 3%;\r\n}\r\n\r\ndiv.dataTables_wrapper div.dataTables_length select{\r\n  width:50px !important;\r\n}\r\n\r\n\r\n.table-default {\r\n  border-collapse: separate;\r\n  border-spacing: 0;\r\n}\r\n\r\n.dataTables_filter {\r\n  float: right !important;\r\n}\r\n  \r\n .wrapper{\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  width: 300px;\r\n  text-align:center;\r\n  transform: translateX(-50%);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
