import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';
import { Bus } from '../models/bus';
import { HTTP_OPTIONS } from './header.config';


@Injectable()
export class BusService {

	public url;
	public headers: HttpHeaders;


	constructor(
		public _http: HttpClient,
	) {
		this.url = global.url;
		this.headers = new HttpHeaders();
	}

	getBuses(token): Observable<any> {
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
		//.set('Authorization', token);
		return this._http.get(this.url + '/admin/buses/get', {headers: this.headers.set('Authorization', token)});

	}
	saveBus(token, bus): Observable<any> {
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
		//.set('Authorization', token);
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(bus);
		let params = 'json='+json;

		return this._http.post(this.url + '/admin/bus/create', params, {headers: headers});

	}

	updateBus(token, bus: Bus): Observable<any> {
		//.set('Authorization', token);
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(bus);
		let params = 'json='+json;

		return this._http.post(this.url + '/admin/bus/update', params, {headers: headers});

	}

	deleteBus(token, bus: Bus): Observable<any> {
		//.set('Authorization', token);
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(bus);
		let params = 'json='+json;
		return this._http.post(this.url + '/admin/bus/delete', params, {headers: headers});
	}

	getMantenimientos(token, ppu: string, datefrom:string, dateto:string ,ot:string): Observable<any>  {		
		return this._http.get(this.url + '/bus/mantenimientos?ppu='+ ppu + "&datefrom="+ datefrom + "&dateto="+ dateto + "&ot=" + ot,{headers: this.headers.set('Authorization', token)});
	}

	getMantenimientos2(token, ppu: string, datefrom:string, dateto:string ,ot:string): Observable<any>  {		
		return this._http.get(this.url + '/bus/mantenimientos2?ppu='+ ppu + "&datefrom="+ datefrom + "&dateto="+ dateto + "&ot=" + ot,{headers: this.headers.set('Authorization', token)});
	}

	getMantenimientosOT(token, ot: string, ppu:string): Observable<any>  {
		return this._http.get(this.url + '/bus/mantenimientos/detailOT?ppu='+ ppu +  "&ot=" + ot, {headers: this.headers.set('Authorization', token)});
	}

	importBuses(token, formData:FormData): Observable<any>{
		return this._http.post(this.url + '/admin/buses/import', formData, {headers: this.headers.set('Authorization', token)});
	}


	getReportsCertification(token, ppu, datefrom, dateto, tipo): Observable<any>{
		return this._http.get(this.url + '/bus/reports?ppu='+ ppu +  "&datefrom=" + datefrom +  "&dateto=" + dateto +  "&tipo=" + tipo , {headers: this.headers.set('Authorization', token)});
	}

	getStatusCertifications(token, datefrom, dateto, ppu):Observable<any>{
		return this._http.get(this.url + '/buses/status/certification?ppu='+ ppu +  "&datefrom=" + datefrom +  "&dateto=" + dateto , {headers: this.headers.set('Authorization', token)});
	}
}