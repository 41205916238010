import { ChangeDetectorRef, Component, OnInit, DoCheck } from '@angular/core';
import { UserService } from 'src/app/services/user.services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MaintenanceService } from 'src/app/services/maintenance.services';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
    selector: 'app-upload',
    templateUrl: './upload-ot.component.html',
    styleUrls: ['./upload-ot.component.css']
})
export class UploadOTComponent implements OnInit, DoCheck {
    public token;
    public ppu = "";
    public datefrom="";
    public dateto="";
    public file:File;
    public base64;
    public ot;
    addReportForm: FormGroup;
    constructor(
        private SpinnerService: NgxSpinnerService,
        private _UserService: UserService,
        public modal: NgbActiveModal,
        private _formBuilder: FormBuilder,
        private changeDetector: ChangeDetectorRef,
        public _MaintenanceService:MaintenanceService,
        private _router: Router,
     ) {

    }
    ngOnInit() {
        this.token = this._UserService.getToken();
    }

    ngDoCheck(): void {
        this.token = this._UserService.getToken();
    }

    CloseModal() {
        this.modal.close('');
    }

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }

    uploadFile($event) {
        var file = $event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (fileLoadedEvent) => {
        this.base64 = fileLoadedEvent.target.result; 
    };


        
    }

    saveReport(){
        
        var request:any = {
            "ppu": this.ppu,
            "ot": this.ot,
            "evidencia":  this.base64
        }
        this.SpinnerService.show();
        this._MaintenanceService.UploadOTMaintenance(this.token, request)
        .subscribe(
            response => {
                if(response.status == "success"){
                    this.SpinnerService.hide();
                    Swal.fire("Documento registrado en OT", "", "success");                    
                    this.modal.close('Yes');
                }else {
                    Swal.fire("Se ha producido un error", "", "error");
                    this.SpinnerService.hide();
                  }
                
            },
            error => {
                if (error.status != 401) {
                this.SpinnerService.hide();
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    width: 400,
                    text: error.error.message,
                    footer: '<a href="">¿Por qué tengo este problema?</a>'
                });
                } else if (error.status == 401) {
                this.SpinnerService.hide();
                Swal.fire({
                    icon: "warning",
                    title: "Sesión Expirada",
                    text: 'Su sesión ha finalizado'
                });
                this._router.navigate(['logout/1']);
                }
            }
        )
    }

    fileToBase64 = (filename, filepath) => {
        return new Promise(resolve => {
          var file = new File([filename], filepath);
          var reader = new FileReader();
          // Read file content on file loaded event
          reader.onload = function(event) {
            resolve(event.target.result);
          };
          
          // Convert data to base64 
          reader.readAsDataURL(file);
        });
      };
}
