import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule,  ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TabsModule } from 'ngx-bootstrap/tabs'
import { MaterialModule } from 'src/material.module';
import { BusesRoutingModule } from './buses-routing.module';
import { BusesComponent } from './buses.component';
//import { UserService } from '../services/user.service';
import { UserGuard } from '../services/user.guard';
import { HojaVidaComponent } from './views/hoja-vida/hoja-vida.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { UserService } from '../services/user.services';
import { PagerService } from '../services/pager.services';
import { BusService } from '../services/bus.services';
import {MatTabsModule} from '@angular/material/tabs';
import { AuditsService } from '../services/audits.services';
import { MaintenanceService } from '../services/maintenance.services';
import { ReportBusComponent } from './views/report-bus/report-bus.component';
import { DataTablesModule } from 'angular-datatables';
import { EstadoCertificacionComponent } from './views/status-certification/status-certification.component';

@NgModule({
  declarations: [
    BusesComponent,
    HojaVidaComponent,
    ReportBusComponent,
    EstadoCertificacionComponent
  ],
  imports: [
    BusesRoutingModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxSpinnerModule,
    TabsModule.forRoot(),
    MatTabsModule,
    DataTablesModule.forRoot(),
  ],
  providers: [
   // UserService,
    UserGuard,
    PagerService, MaintenanceService, UserService, BusService, AuditsService
  ],
  exports:[ BusesComponent],
  bootstrap: [BusesComponent, HojaVidaComponent, ReportBusComponent, EstadoCertificacionComponent]
})
export class BusesModule { }