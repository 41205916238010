import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../services/user.guard';
import { RegisterComponent } from './views/register/register.component';
import { ConsultarComponent } from './views/consultar/consultar.component';
import { ImportarComponent } from './views/importar/importar.component';

export const routes: Routes = [
  {
        path: 'maintenance',
        canActivate: [UserGuard],
        children:[
      { path: 'register', component: RegisterComponent },
      { path: 'consultar', component: ConsultarComponent },
      { path: 'importar', component: ImportarComponent },
      { path: '', redirectTo: 'consultar', pathMatch: 'full' },
      { path: '**', redirectTo: '404' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports : [ RouterModule ]
})
export class MaintenanceRoutingModule { }
