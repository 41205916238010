import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';
import { User } from '../models/user';
import { LocalizedString } from '@angular/compiler';

@Injectable()
export class UserService{

	public url;
	public identity;
	public token;

	constructor(
		public _http: HttpClient,
	){
		this.url = global.url;
	}

    getUsers(token): Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);		
		return this._http.get(this.url + '/admin/users/get', {headers: headers});
	}

	createUser(token: any, user):Observable<any>{

		let json = JSON.stringify(user);
		let params = 'json='+json;

		let headers = new HttpHeaders()
			.set('Content-Type', 'application/x-www-form-urlencoded')
			.set('Authorization', token);

		return this._http.post(this.url + '/admin/user/create', params, {headers: headers} );
	}
	editUser(user, token){
		let json = JSON.stringify(user);
		let params = 'json='+json;

		let headers = new HttpHeaders()
			.set('Content-Type', 'application/x-www-form-urlencoded')
			.set('Authorization', token);

		return this._http.put(this.url + '/admin/user/update', params, {headers: headers} );
	}

	deleteUser(token:any , user):Observable<any>{
		let json = JSON.stringify(user);
		let params = 'json='+json;

		let headers = new HttpHeaders()
			.set('Content-Type', 'application/x-www-form-urlencoded')
			.set('Authorization', token);

		return this._http.post(this.url + '/admin/user/delete', params, {headers: headers} );

	}

	signup(user, getToken = null):Observable<any>{

		if (getToken!=null){
			user.getToken = 'true';
		}
		let json=JSON.stringify(user);
		let params = 'json='+json;
		let headers = new HttpHeaders()
			.set('Content-Type', 'application/x-www-form-urlencoded');

		return this._http.post(this.url + '/login', params, {headers: headers} );
	}

	getIdentity(){
		let identity = JSON.parse(localStorage.getItem('identity'));

		if (identity && identity != "undefined"){
			this.identity = identity;
		}else{
			this.identity = null;
		}

		return this.identity;

	}
	getToken(){
		let token = localStorage.getItem('token');

		if (token && token != "undefined"){
			this.token = token;
		}else{
			this.token = null;
		}

		return this.token;

	}

}