import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { User } from '../models/user';
import { UserService } from '../services/user.services';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService]
})
export class LoginComponent implements OnInit {
public user: User = new User();
public status: string;
public token;
public identity;
  constructor(
    private _router: Router,
    private _UserService: UserService,
    private _route: ActivatedRoute,
    private SpinnerService: NgxSpinnerService
    ) {  
   }
  
  ngOnInit() {
    this.logout();
    this.SpinnerService.hide();

  }

onSubmit(form){
  this.SpinnerService.show();
  this._UserService.signup(this.user).subscribe({

    next: (response) => {
      if(response!=null){
        if (response.status != 'error'){
          this.status = 'success';
          this.token = response;
          this._UserService.signup(this.user, true).subscribe(
            response => {
              if (response.status != 'error'){
              this.identity = response;
              localStorage.setItem('token', this.token);
              localStorage.setItem('identity', JSON.stringify(this.identity));
              this.SpinnerService.hide();
              this._router.navigate(['home']);
              }else{
                this.status = 'error';
                this.SpinnerService.hide();
              }
            },
            error => {this.SpinnerService.hide();}
          );
        }else{
          this.status = 'error';
          this.SpinnerService.hide();
        }
      }else{
        this.status = 'error2';
        this.SpinnerService.hide();
      }

    },
    error: (error) => {
      this.status = 'error2';
      this.SpinnerService.hide();},
      complete:()=>{
        this.SpinnerService.hide();
      }
    })
}

logout(){
  this._route.params.subscribe(params => {
    let logout = 1; //+params['sure'];
    if (logout == 1){
      localStorage.removeItem('identity');
      localStorage.removeItem('token');

      this.identity = null;
      this.token = null;

      //Redireccion al inicio
      this._router.navigate(['login']);
    }

  }

  );
}
}
