// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .input-group>.input-group-prepend {
    flex: 0 0 25% !important;
  }

  .input-group .input-group-text {
    width: 100% !important;
    align-content:flex-end !important;
  }`, "",{"version":3,"sources":["webpack://./src/app/maintenance/views/register/modal/register-maintenance/register-maintenance.component.css"],"names":[],"mappings":";EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,sBAAsB;IACtB,iCAAiC;EACnC","sourcesContent":["\r\n  .input-group>.input-group-prepend {\r\n    flex: 0 0 25% !important;\r\n  }\r\n\r\n  .input-group .input-group-text {\r\n    width: 100% !important;\r\n    align-content:flex-end !important;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
