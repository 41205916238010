
export class Plan{
   codigo_plan:string
   categoria:string
   descripcion_plan:string
   marca_plan:string
   modelo_plan:string
   sistema:string
   subsistema:string
   inspeccion_tecnica:boolean;
   usuario_creacion:string;
   fecha_creacion:string;
   usuario_modificacion:string;
   fecha_modificacio:string;
    
};