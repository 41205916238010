import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../services/user.guard';
import { DocumentosComponent } from './views/documental/documental.component';
import { InspeccionComponent } from './views/inspeccion/inspeccion.component';
import { EstadoGeneralBusComponent } from './views/estado-general-bus/estado-general-bus.component';
export const routes: Routes = [
  {
        path: 'audit',
        canActivate: [UserGuard],
        children:[
      { path: 'documentary-audit', component: DocumentosComponent },
      { path: 'technical-inspection', component: InspeccionComponent },
      { path: 'review-status', component: EstadoGeneralBusComponent },
      { path: '', redirectTo: 'documentary-audit', pathMatch: 'full' },
      { path: '**', redirectTo: '404' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})
  ],
  exports : [ RouterModule ]
})
export class AuditRoutingModule { }
