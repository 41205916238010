import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../services/user.guard';
import { DeliveryComponent } from './views/delivery/delivery.component';
import { RequestComponent } from './views/request/request.component';
import { ClaimsComponent } from './views/claims/claims.component';
import { ResponseClaimComponent } from './views/response-claim/response-claim.component';

export const routes: Routes = [
  {
        path: 'supports',
        canActivate: [UserGuard],
        children:[
      { path: 'requests', component: RequestComponent },
      { path: 'deliveries', component: DeliveryComponent },
      { path: 'repairs', component: ClaimsComponent },
      { path: 'resolution', component: ResponseClaimComponent },
      { path: '', redirectTo: 'requests', pathMatch: 'full' },
      { path: '**', redirectTo: '404' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports : [ RouterModule ]
})
export class SupportsRoutingModule { }
