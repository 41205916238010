import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from 'src/material.module';
import { AuditRoutingModule } from './audit-routing.module';
import { AuditComponent } from './audit.component';
import { UserGuard } from '../services/user.guard';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';
import { DocumentosComponent } from './views/documental/documental.component';
import { ObservacionComponent } from './views/documental/modal/observacion/observacion.component';
import { AuditDetailComponent } from './views/documental/modal/audit-details/audit-details.component';
import { PagerService } from '../services/pager.services';
import { UserService } from '../services/user.services';
import { MaintenanceService } from '../services/maintenance.services';
import { CertificationService } from '../services/certification.services';
import { AuditsService } from '../services/audits.services';
import { DataTablesModule } from 'angular-datatables';
import { EstadoGeneralBusComponent } from './views/estado-general-bus/estado-general-bus.component';
import { MatTabsModule } from '@angular/material/tabs';
import { InspeccionComponent } from './views/inspeccion/inspeccion.component';
import { InspeccionAddComponent } from './views/inspeccion/modal/inspeccion-add/inspeccion-add.component';
import { PautaEGBAddComponent } from './views/estado-general-bus/modal/pauta-egb-add/pauta-egb-add.component';


@NgModule({
  declarations: [
    AuditComponent,
    DocumentosComponent,
    ObservacionComponent,
    AuditDetailComponent,
    EstadoGeneralBusComponent,
    InspeccionComponent,
    InspeccionAddComponent,
    PautaEGBAddComponent
  ],
  imports: [
    AuditRoutingModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxSpinnerModule,
    NgSelectModule,
    MatTabsModule,
    DataTablesModule.forRoot()
  ],
  exports: [
    AuditComponent,
  ],
  providers: [
   // UserService,
    UserGuard,
    PagerService, UserService, CertificationService, MaintenanceService, AuditsService
  ],
    bootstrap: [AuditComponent, DocumentosComponent, ObservacionComponent, AuditDetailComponent, EstadoGeneralBusComponent, InspeccionComponent, InspeccionAddComponent, PautaEGBAddComponent],
})
export class AuditModule { }