import { Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { PagerService } from 'src/app/services/pager.services';
import { MaintenanceService } from 'src/app/services/maintenance.services';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { RequestProcess } from 'src/app/models/response';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-import-maintenance',
  templateUrl: './importar.component.html',
  providers: [],
})
export class ImportarComponent implements OnInit, DoCheck {
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  totalxPage: number = 10;
  pager2: any = {};
  // paged items
  pagedItems2: any[];
  totalxPage2: number = 10;
  _maintenances: any[] = new Array<any>();
  _repuestos: any[] = new Array<any>();
  @ViewChild('form') form;
  public token;
  public identity;
  //public buses:any;
  constructor(
    private _pagerService: PagerService,
    private _maintenanceService: MaintenanceService,
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private _router: Router,
    private datepipe: DatePipe = new DatePipe('es-CL')
  ) { }
  percentDone: number;
  uploadSuccess: boolean;
  files: File[];
  logOperaciones: any;
  procesar: boolean = false;
  base;
  ngOnInit() {
    registerLocaleData(es);
    this.token = this._UserService.getToken();
    this.identity = this._UserService.getIdentity();
    const date = new Date();    // Mon Aug 27 2018 01:39:21 GMT+0100
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  uploadFile($event) {

    this.files = $event.target.files;
    this.procesar = true;
  }

  limpiar() {
    this.files = [];
    this.logOperaciones = [];
    this._maintenances = [];
    this._repuestos = [];
    this.pagedItems = [];
    this.pagedItems2 = [];
    this.procesar = false;
    this.form.nativeElement.reset();
  }

  uploadAndProgress() {
    this.SpinnerService.show();
    if (!this.files) {
      this.SpinnerService.hide();
      //this.message = 'No se pudo importar el archivo';
      return false;
    }
    var formData = new FormData();
    Array.from(this.files).forEach(f => formData.append('file', f))

    this._maintenanceService.uploadFile(this.token, formData)
      .subscribe(response => {
          if (response.status=='success'){
          Swal.fire({
            icon: 'success',
            title: 'Importacion Exitosa',
            width: 400,
            text: "Registros Importados: " + response.data.correctos + " Registros Repetidos: " + response.data.repetidos + " Error: " + response.data.errores
          });
          this.limpiar();
        }
        /*this.logOperaciones = response.data.log[0];*/
        //this.base = response.data;
       /* this._maintenances = response.data[0];
        this.setPage(1);
        this._repuestos = response.data[1];
        this.setPageR(1);*/
        this.SpinnerService.hide();
      },
        error => {
          if (error.status != 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado'
            });
            this._router.navigate(['logout/1']);
          }
          this.limpiar();
        }

      );
  }

  cancelProcess() {
    this.SpinnerService.show();
    let cancel = { 'id_interfase': this.logOperaciones.id_interfase, 'call_user': this.identity.rut };
    this._maintenanceService.cancelProcess(this.token, cancel)
      .subscribe(
        response => {
          this.limpiar();
          this.SpinnerService.hide();
        },
        error => {
          if (error.status != 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado'
            });
            this._router.navigate(['logout/1']);
          }
          this.limpiar();
        }
      );
  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    // get pager object from service
    this.pager = this._pagerService.getPager(this._maintenances.length, page, +this.totalxPage);

    // get current page of items
    this.pagedItems = this._maintenances.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  setPageR(page: number) {
    if (page < 1 || page > this.pager2.totalPages) {
      return;
    }

    // get pager object from service
    this.pager2 = this._pagerService.getPager(this._repuestos.length, page, +this.totalxPage2);

    // get current page of items
    this.pagedItems2 = this._repuestos.slice(this.pager2.startIndex, this.pager2.endIndex + 1);
  }
  
  numeroAFecha(numeroDeDias, esExcel = false) {
    var diasDesde1900 = esExcel ? 25567 + 1 : 25567;
    // 86400 es el número de segundos en un día, luego multiplicamos por 1000 para obtener milisegundos.
    if (typeof numeroDeDias == 'number'){
      if (numeroDeDias<1)
        return new Date((numeroDeDias+0.125) * 86400 * 1000);
      else
        return new Date((numeroDeDias - diasDesde1900) * 86400 * 1000);
    }
    else
    
      var fecha = numeroDeDias.toString();
      var dia = fecha.substring(0,2);
      var mes = fecha.substring(3,5);
      var año = fecha.substring(6,10);
      //02-03-2023
      var cadena=mes +'-'+dia+'-'+ año;
      return new Date(cadena);

    
    }
  Process() {

    this.SpinnerService.show();

    this._maintenanceService.processImport(this.token, this.base)
      .subscribe(
        response => {
          if (response) {
            Swal.fire({
              icon: 'success',
              title: 'Importacion Exitosa',
              width: 400,
              text: "Registros Importados: " + response.data.correctos + " Registros Repetidos: " + response.data.repetidos
            });
            this.limpiar();
            /*this.logOperaciones = response.data.log[0];
            this._maintenances = response.data.mantenimientos;
            this.setPage(1);
            this._repuestos = response.data.repuestos;
            this.setPageR(1);*/
            this.SpinnerService.hide();
            /*this.procesado = response;*/
          }
          else {
            Swal.fire("Se ha producido un error", "", "error");
            this.SpinnerService.hide();
          }
        },
        error => {
          this.SpinnerService.hide();
          if (error.status != 401) {
            
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado'
            });
            this._router.navigate(['logout/1']);
          }
          this.limpiar();
        });
  }

  downloadExcel() {
    const filename = 'assets/Formato_Carga_Mantenimientos-Repuestos.xlsx';
  }

}
