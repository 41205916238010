import { Component, DoCheck, Injectable, OnInit } from '@angular/core';
import { UserService } from './services/user.services';
@Injectable({
  providedIn: 'root',
})

@Component({
  selector: '#app-root',
  styleUrls : ['app.component.scss'],
  templateUrl: './app.component.html',
  providers: [UserService]
})
export class AppComponent implements OnInit, DoCheck {
  title = 'Gestión Flota Scania Chile';
  public identity;
  public token;

  constructor(private _userService: UserService){
    this.loadUser();
  }

ngOnInit(){
  
}

ngDoCheck(){
  this.loadUser();
}

loadUser(){
  this.identity = this._userService.getIdentity();
  this.token = this._userService.getToken();
}
}
