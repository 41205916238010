

export class Maintenance{
    id_mantenimiento:number;    
    ppu:string;
    ot:string;
    codigo_plan:string;
    fecha_inicio:Date;
    fecha_final:Date;
    hora_inicio:string;
    hora_final:string;
    categoria:string;
    detalle:string;
    kilometraje:number;
    causa:string;
    repuestos: Repuestos[];
};

export class Repuestos{
    nroparte:string;
    descripcion:string;
    marca:string;
    cantidad:number;
};

export class MaintenceFile{
    ppu:string;
    ot:string;
    base64:string
}