import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.services';

@Injectable()
export class AdminGuard implements CanActivate {

	public ruta;
	constructor(
		private _router: Router,
		private _userService: UserService
		){}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){

		let identity = this._userService.getIdentity();
		if (identity && identity.perfil ==99){
			return true;
		}else{
			this._router.navigate(['home']);
			return false;
		}		
	}
}