// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group-prepend {
    flex: 0 0 30% !important;
    
  }

  .input-group-text {
    width:100% !important;
    align-content:flex-end !important;
  }

  .ng-select.custom {
    border:0px;
    min-height: 0px;
    border-radius: 0;
    width: 250px;    
    
}
.ng-select.custom .ng-select-container  {            
    min-height: 0px;
    border-radius: 0;
    height: 150px;
}

.nuevo {
  height: 45px !important;
}
input {
  width: 250px;  
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  width: 300px;
  transition: background .2s ease-in-out, border .2s ease-in-out;
  margin: 0;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/app/maintenance/views/consultar/modal/upload-ot.component.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;;EAE1B;;EAEA;IACE,qBAAqB;IACrB,iCAAiC;EACnC;;EAEA;IACE,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,YAAY;;AAEhB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,eAAe;EACf,YAAY;EACZ,8DAA8D;EAC9D,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,iCAAiC;AACnC","sourcesContent":[".input-group-prepend {\r\n    flex: 0 0 30% !important;\r\n    \r\n  }\r\n\r\n  .input-group-text {\r\n    width:100% !important;\r\n    align-content:flex-end !important;\r\n  }\r\n\r\n  .ng-select.custom {\r\n    border:0px;\r\n    min-height: 0px;\r\n    border-radius: 0;\r\n    width: 250px;    \r\n    \r\n}\r\n.ng-select.custom .ng-select-container  {            \r\n    min-height: 0px;\r\n    border-radius: 0;\r\n    height: 150px;\r\n}\r\n\r\n.nuevo {\r\n  height: 45px !important;\r\n}\r\ninput {\r\n  width: 250px;  \r\n}\r\n\r\n.drop-container {\r\n  position: relative;\r\n  display: flex;\r\n  gap: 10px;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 70px;\r\n  padding: 20px;\r\n  border-radius: 10px;\r\n  border: 2px dashed #555;\r\n  color: #444;\r\n  cursor: pointer;\r\n  width: 300px;\r\n  transition: background .2s ease-in-out, border .2s ease-in-out;\r\n  margin: 0;\r\n}\r\n\r\n.drop-container:hover {\r\n  background: #eee;\r\n  border-color: #111;\r\n}\r\n\r\n.drop-container:hover .drop-title {\r\n  color: #222;\r\n}\r\n\r\n.drop-title {\r\n  color: #444;\r\n  font-size: 20px;\r\n  font-weight: bold;\r\n  text-align: center;\r\n  transition: color .2s ease-in-out;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
