import { Component, OnInit, DoCheck, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MaintenanceService } from 'src/app/services/maintenance.services';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { Subject } from 'rxjs';
import { dataTableSettingSpareParts } from 'src/app/models/dictionary';
import { Repuestos } from 'src/app/models/maintence';

@Component({
  selector: 'app-detail-spareparts',
  templateUrl: './detail-spareparts.component.html',
  styleUrls: ['./detail-spareparts.component.css'],
})
export class DetailSparePartsComponent implements OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  ot: number;
  // pager object
  public token;
  repuestos: Repuestos[];

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isDtInitialized: boolean = false;
  constructor(
    private _MaintenanceService: MaintenanceService,
    public modal: NgbActiveModal,
    private _UserService: UserService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService
  ) {

  }

  ngOnInit(): void {
    this.token = this._UserService.getToken();
    this.dtOptions = dataTableSettingSpareParts;
    this.getRepuestos();
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }
 
  getRepuestos() {
    this.SpinnerService.show();
    this._MaintenanceService.getRepuestos(this.token, this.ot)
      .subscribe(
        response => {
          if (response.status=='success'){
            if (response.data){
              this.repuestos = response.data as Repuestos[];
              if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                  this.SpinnerService.hide();
                  dtInstance.destroy();
                  this.dtTrigger.next(0);
                });
              } else {
                this.SpinnerService.hide();
                this.isDtInitialized = true;
                this.dtTrigger.next(0);
              }
            }else{
              this.SpinnerService.hide();
              this.dtTrigger.next(0);
            }

          }else{
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 404,
              text: response.error,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          }
        },
        error => {
          this.SpinnerService.hide();
              if (error.status != 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  width: 400,
                  text: error.error.message,
                  footer: '<a href="">¿Por qué tengo este problema?</a>'
                });
              } else if (error.status == 401) {
                Swal.fire({
                  icon: "warning",
                  title: "Sesión Expirada",
                  text: 'Su sesión ha finalizado'
                });
                this._router.navigate(['logout/1']);
              }
        }
      )
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}