// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group>.input-group-prepend {
    flex: 0 0 20% !important;
    
  }

  .input-group .input-group-text {
    width: 100% !important;
    align-content:flex-end !important;
  }

  .ng-select.custom {
    border:0px;
    min-height: 0px;
    border-radius: 0;
    width: 325px;
    
}
.ng-select.custom .ng-select-container  {            
    min-height: 0px;
    border-radius: 0;
    height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/app/technical-review/views/technical-review-import/technical-review-import.component.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;;EAE1B;;EAEA;IACE,sBAAsB;IACtB,iCAAiC;EACnC;;EAEA;IACE,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,YAAY;;AAEhB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".input-group>.input-group-prepend {\r\n    flex: 0 0 20% !important;\r\n    \r\n  }\r\n\r\n  .input-group .input-group-text {\r\n    width: 100% !important;\r\n    align-content:flex-end !important;\r\n  }\r\n\r\n  .ng-select.custom {\r\n    border:0px;\r\n    min-height: 0px;\r\n    border-radius: 0;\r\n    width: 325px;\r\n    \r\n}\r\n.ng-select.custom .ng-select-container  {            \r\n    min-height: 0px;\r\n    border-radius: 0;\r\n    height: 150px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
