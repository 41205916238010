import { Component, OnInit, DoCheck } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditsService } from 'src/app/services/audits.services';
import { Observable, map } from 'rxjs';
import { StepperOrientation } from '@angular/cdk/stepper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Bus } from 'src/app/models/bus';
import { BusService } from 'src/app/services/bus.services';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pauta-egb-add',
  templateUrl: './pauta-egb-add.component.html',
  styleUrls: ['./pauta-egb-add.component.css'],
})
export class PautaEGBAddComponent implements OnInit, DoCheck {
    
    firstFormGroup: FormGroup;
    selectedPpu: string;
    stepperOrientation: Observable<StepperOrientation>;
    public token;
    public inspeccion:any;
    public components:any;
    public categoria:string = "";
    public observaciones:string=""
    public periodo:string;
    public buses: Bus[] = [];
    public elementos_inspeccionados:number=0;
    public criticidad_baja:number=0;
    public criticidad_media:number=0;
    public criticidad_alta:number=0;
    public resultado="APROBADO";
    public ppu="";
    public reinspeccion:boolean=false;
    public AllNoCumple:boolean =false;
    public AllCumple:boolean =false;
    public base64;
    public file:File;
    constructor(public modal: NgbActiveModal,
        private _auditsService: AuditsService, 
        private _userService: UserService,
        private SpinnerService: NgxSpinnerService,
        private _formBuilder: FormBuilder, 
        private _BusService: BusService,
        private _router: Router,
        breakpointObserver: BreakpointObserver) {
        this.stepperOrientation = breakpointObserver
          .observe('(min-width: 800px)')
          .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));

          this.firstFormGroup = this._formBuilder.group({
            ppu:new FormControl('', [Validators.required]),
            km:new FormControl('', [Validators.required, Validators.min(1)]),
            folio:new FormControl('', [Validators.required, Validators.min(1)]),
            fecha_inicio:new FormControl('', [Validators.required]),
            hora_inicio:new FormControl('', [Validators.required]),
            fecha_termino:new FormControl('', [Validators.required]),
            hora_termino:new FormControl('', [Validators.required]),
            lugar_inspeccion:new FormControl('', [Validators.required]),
            observaciones: new FormControl('', []),
            file: new FormControl('', []),

          });
         
      }



    get form() {
        return this.firstFormGroup.controls;
      }

    ngOnInit(){
        this.token = this._userService.getToken();
        this.loadBuses();
        this.getSistemasEGB();
    }

    ngDoCheck(){
        this.token = this._userService.getToken();
    }

    Eliminar(i){
        $("#"+i).remove();
    }

    CloseModal() {
        this.modal.close('Cerrar');
    }

    getSistemasEGB(){
        this.SpinnerService.show();
        this._auditsService.getSistemaEGB(this.token)
        .subscribe({
            next:(resp)=>{this.components=resp.data},
            error:(error)=>{this.SpinnerService.hide();},
            complete:()=>{
                this.SpinnerService.hide();
            }
        });
    }

    GrabarInspeccion(){
        var request: any = {
            
            "ppu" : this.firstFormGroup.get('ppu').value,
            "kilometraje": this.firstFormGroup.get('km').value,
            "nrofolio": this.firstFormGroup.get('folio').value,
            "fecha_inicio": this.firstFormGroup.get('fecha_inicio').value,
            "hora_inicio": this.firstFormGroup.get('hora_inicio').value,
            "fecha_termino": this.firstFormGroup.get('fecha_termino').value,
            "hora_termino": this.firstFormGroup.get('hora_termino').value,
            "lugar_inspeccion": this.firstFormGroup.get('lugar_inspeccion').value,
            "observaciones":this.firstFormGroup.get('observaciones').value,
            "componentes" : this.elementos_inspeccionados>0?this.components : null,
            "resultado" : this.resultado,
            "total_items": this.elementos_inspeccionados,
            "total_baja": this.criticidad_baja,
            "total_media": this.criticidad_media,
            "total_alta": this.criticidad_alta,
            "reinspeccion": this.reinspeccion,
            "evidencia": this.base64
        }
        
        this._auditsService.createAuditInspectionReview(this.token, request)
        .subscribe({
            next:(resp)=>{
                if (resp.status=='success'){
                    Swal.fire("Revisión EGB ingresada correctamente", '', 'success')
                }
                this.modal.close('Yes');
            },
            error:(error)=>{
                this.SpinnerService.hide();
            },
            complete: ()=>{
                this.SpinnerService.hide();
            }
        })

       


    }

    Cumple($event, i){


        if (this.components[i].nocumple){
            if (this.components[i].nivel_criticidad=='Alta')
                this.criticidad_alta -=1;
            if (this.components[i].nivel_criticidad=='Media')
                this.criticidad_media -=1;
            if (this.components[i].nivel_criticidad=='Baja')
                this.criticidad_baja -=1;
            this.elementos_inspeccionados -=1;
        }

        if (this.components[i].cumple){
            $event.target.checked = false;
            this.components[i].cumple=false;
            this.elementos_inspeccionados -=1;
        }else
             if($event.target.checked){                
                 this.components[i].nocumple=false;
                 this.elementos_inspeccionados +=1;
             }else{                
                this.elementos_inspeccionados -=1;
             }
            
         

    }

    NoCumple($event, i){

        if (this.components[i].cumple){
            this.components[i].cumple = false;

            this.components[i].nocumple=true;

            if (this.components[i].nivel_criticidad=='Alta')
                this.criticidad_alta +=1;
            if (this.components[i].nivel_criticidad=='Media')
                this.criticidad_media +=1;
            if (this.components[i].nivel_criticidad=='Baja')
                this.criticidad_baja +=1;

        }else{
            if (this.components[i].nocumple){
                $event.target.checked = false;
                this.components[i].nocumple=false;
                this.elementos_inspeccionados -=1;
                if (this.components[i].nivel_criticidad=='Alta')
                    this.criticidad_alta -=1;
                if (this.components[i].nivel_criticidad=='Media')
                    this.criticidad_media -=1;
                if (this.components[i].nivel_criticidad=='Baja')
                    this.criticidad_baja -=1;
            }else
            if($event.target.checked){
                    if (this.components[i].nivel_criticidad=='Alta')
                        this.criticidad_alta +=1;
                    if (this.components[i].nivel_criticidad=='Media')
                        this.criticidad_media +=1;
                    if (this.components[i].nivel_criticidad=='Baja')
                        this.criticidad_baja +=1;
                    this.elementos_inspeccionados +=1;
            }else{
                
                    if (this.components[i].nivel_criticidad=='Alta')
                        this.criticidad_alta -=1;
                    if (this.components[i].nivel_criticidad=='Media')
                        this.criticidad_media -=1;
                    if (this.components[i].nivel_criticidad=='Baja')
                        this.criticidad_baja -=1;

                    this.elementos_inspeccionados -=1;
                }

        }



        


    }

    loadBuses() {

        this._BusService.getBuses(this.token)
        .subscribe({
            next:(response) => {
            this.buses = response.data as Bus[];
            //this.setPage(1);
            this.SpinnerService.hide();
            },
            error:(error) => {
                if (error.status == 404) {
                    this.SpinnerService.hide();
                    Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    width: 400,
                    text: error.error.message,
                    footer: '<a href="">¿Por qué tengo este problema?</a>'
                    });
                } else if (error.status == 401) {
                    this.SpinnerService.hide();
                    Swal.fire({
                    icon: "warning",
                    title: "Sesión Expirada",
                    text: 'Su sesión ha finalizado'
                    });
                    this._router.navigate(['logout/1']);
                }
            },
            complete: ()=>{

            }

    });
    }

    InformeRevision(){
        
        if (this.elementos_inspeccionados>0){

            if ( this.resultado=='APROBADO'){
                this.reinspeccion = false;
            }else{
                this.reinspeccion = true;   
            }
        
        }else{
            if (this.observaciones != ""){
                this.resultado="NO APROBADO";
                this.reinspeccion = true;
            }
        }

        // if(this.criticidad_alta==0 && this.criticidad_baja ==0 && this.criticidad_media ==0 ){
        //     this.resultado="APROBADO";
        // }
        // else{

        // }
    }

    SelectAll($event, cumple){
        this.elementos_inspeccionados=0;
        if ($event.target.checked){
            this.components.forEach(element => {
                
                if (cumple){
                    element.cumple=true;
                    element.nocumple=false;
                    this.AllCumple = true;
                    this.AllNoCumple = false;
                    this.resultado = "APROBADO";
                    this.elementos_inspeccionados +=1;
                    this.criticidad_baja = 0;
                    this.criticidad_media = 0;
                    this.criticidad_alta = 0;
                }else{
                    element.cumple=false;
                    element.nocumple=true;
                    this.AllCumple = false;
                    this.AllNoCumple = true;
                    this.resultado = "NO APROBADO";
                    this.elementos_inspeccionados +=1;
                    if (element.nivel_criticidad=='Alta')
                        this.criticidad_alta +=1;
                    if (element.nivel_criticidad=='Media')
                        this.criticidad_media +=1;
                    if (element.nivel_criticidad=='Baja')
                        this.criticidad_baja +=1;
                }

            });
        }else{
            this.resultado = "APROBADO";
            this.components.forEach(element => {
                    element.cumple=false;
                    element.nocumple=false;
                    this.AllCumple = false;
                    this.AllNoCumple = false;
                    this.elementos_inspeccionados =0;
                    this.criticidad_baja = 0;
                    this.criticidad_media = 0;
                    this.criticidad_alta = 0;
            });
        }

        
    }

    uploadFile($event) {
        var file = $event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
        this.base64 = reader.result; };
    }
}
