export class ApiResponse{
    status:string;
    code:number;    
    data:any;
}

export class RequestProcess{
    id_interfase:number;
    status_execution:boolean;    
    call_user:string;
}