import { Component, OnInit, DoCheck } from '@angular/core';
import { UserService } from 'src/app/services/user.services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ClaimsService } from 'src/app/services/claims.services';

@Component({
    selector: 'app-supports-add-claim',
    templateUrl: './add-claim.component.html',
    styleUrls: ['./add-claim.component.css']
})
export class AddClaimComponent implements OnInit, DoCheck {
    public token;
    public ppu = "";
    public datefrom="";
    public file:File;
    public base64;
    public ots:any[] =  [];
    addSolicitudForm: FormGroup;
    constructor(
        private SpinnerService: NgxSpinnerService,
        private _UserService: UserService,
        public modal: NgbActiveModal,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _ClaimsService: ClaimsService
     ) {
        this.addSolicitudForm = this._formBuilder.group({
            datefrom: new FormControl("", Validators.required),
            ot: new FormControl("", Validators.required),
            ppu: new FormControl("", Validators.required),
            detalle: new FormControl("", Validators.required),
          });

        
    }
    ngOnInit() {
        this.token = this._UserService.getToken();
    }

    ngDoCheck(): void {
        this.token = this._UserService.getToken();
    }

    CloseModal() {
        this.modal.close('');
    }

    get form() {
        return this.addSolicitudForm.controls;
      }

    SaveSolicitud(){
        this.SpinnerService.show();
        var request = {
            "ot" : this.addSolicitudForm.get('ot').value,
            "ppu": this.addSolicitudForm.get('ppu').value,
            "detalle": this.addSolicitudForm.get('detalle').value,
            "fecha_solicitud": this.addSolicitudForm.get('datefrom').value,
        };
        this._ClaimsService.saveRequest(this.token, request)
        .subscribe({
            next:(resp)=>{

                if (resp.status=='success'){
                    Swal.fire("Solicitud Generada con Exito!", "", "success")
                    this.modal.close('Yes');
                }

            },
            error:(error)=>{
                this.SpinnerService.hide();
                if (error.status != 401) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      width: 400,
                      text: error.error.message,
                      footer: '<a href="">¿Por qué tengo este problema?</a>'
                    });
                  } else if (error.status == 401) {
                    Swal.fire({
                      icon: "warning",
                      title: "Sesión Expirada",
                      text: 'Su sesión ha finalizado',
                      showCancelButton: false,
                      confirmButtonColor: "#4dbd74",
                      confirmButtonText: "Continuar"});
                      this._router.navigate(['logout/1']);
                  }
            },
            complete:()=>{
                this.SpinnerService.hide();
            }
            
        })
    }

}