import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs'
import { MaterialModule } from 'src/material.module';
import { CertificationRoutingModule } from './certification-routing.module';
import { CertificationComponent } from './certification.component';
import { UserGuard } from '../services/user.guard';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';
import { DocumentalComponent } from './views/documental/documental.component';
import { RevisionComponent } from './views/revision/revision.component';
import { PagerService } from '../services/pager.services';
import { MaintenanceService } from '../services/maintenance.services';
import { UserService } from '../services/user.services';
import { InformeAuditoriaComponent } from './views/documental/modal/informe-auditoria.component';
import { DataTablesModule } from 'angular-datatables';
import { InspeccionComponent } from './views/inspeccion/inspeccion.component';
import { InformeInspeccionComponent } from './views/inspeccion/modal/informe-inspeccion.component';
import { InformeEGBComponent } from './views/revision/modal/informe-egb.component';

@NgModule({
  declarations: [
    CertificationComponent,
    DocumentalComponent,
    RevisionComponent,
    InformeAuditoriaComponent,
    InspeccionComponent,
    InformeInspeccionComponent,
    InformeEGBComponent
  ],
  imports: [
    CertificationRoutingModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxSpinnerModule,
    NgSelectModule,
    TabsModule.forRoot(),
    DataTablesModule.forRoot()
  ],
  exports: [
    CertificationComponent,
  ],
  providers: [
   // UserService,
    UserGuard,
    PagerService, MaintenanceService, UserService

  ],
    bootstrap: [CertificationComponent, DocumentalComponent, RevisionComponent, InformeAuditoriaComponent, InspeccionComponent,InformeInspeccionComponent, InformeEGBComponent],
})
export class CertificationModule { }