import { AfterViewInit, Component, OnInit, DoCheck, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MaintenanceService } from 'src/app/services/maintenance.services';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';
import { TableUtil } from 'src/app/services/excel-export';
import { BusService } from 'src/app/services/bus.services';
import { DetailMaintenancesComponent } from 'src/app/views/detail-maintenances/detail-maintenances.component';
import { DetailSparePartsComponent } from 'src/app/views/detail-spareparts/detail-spareparts.component';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { formatDate } from "@angular/common";
import { dataTableSettingNotButtonsMax } from 'src/app/models/dictionary';
import { UploadOTComponent } from './modal/upload-ot.component';

@Component({
  selector: 'app-consultar-maintenance',
  templateUrl: './consultar.component.html',
  styleUrls: ['./consultar.component.css']
})
export class ConsultarComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public maintenances: any[] = new Array<any>();
  public maintenancesExport: any[] = [];
  public ppu: string = "";
  public datefrom: string = "";
  public dateto: string = "";
  public ot:string="";
  public token;
  public taller_bus;
  //public buses:any;
  
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  constructor(
    private modalService: NgbModal,
    private _maintenanceService: MaintenanceService,
    private SpinnerService: NgxSpinnerService,
    private _UserService:UserService,
    private _router: Router,
    ) {
      
     }

    ngAfterViewInit(): void {      
      this.dtTrigger.next(0);
    }

  ngOnInit() {
    registerLocaleData(es);
    this.token = this._UserService.getToken();
    this.datefrom = formatDate(new Date(), 'yyyy-MM', 'en')
    this.dateto = formatDate(new Date(), 'yyyy-MM', 'en')
    this.dtOptions = {
      info: true,
      responsive: true,
      order: [[ 3, "asc" ]],
      deferRender: true,
      //"scrollY":        "520px",
      pageLength: 10, 
      //scrollX:        true,
      lengthMenu: [10, 15, 20, 25, 50],
      dom: 'Blfrtip',
      //"scrollCollapse": true,
      buttons: {
        buttons: [
          {
            extend: "excelHtml5",
            text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
            className: "btn btn-success",
            enabled: true
          },
        ],
      },
      autoWidth:true,
      language: {
        emptyTable: "No hay coincidencias",
        zeroRecords: "No hay coincidencias",
        lengthMenu: "Mostrar _MENU_ Elementos",
        processing: "Procesando...",
        search: "Buscar:",
        info: "De _START_ a _END_ de _TOTAL_ elementos",
        infoEmpty: "De 0 a 0 de 0 elementos",
        infoFiltered: "(filtrados de _MAX_ elementos totales)",
        paginate: {
          first: "Prim.",
          last: "Últ.",
          next: "Sig.",
          previous: "Ant.",
        },
        aria: {
          sortAscending: ": Ordenar columnas de forma ascendente",
          sortDescending: ": Ordenar columnas de forma descendente",
        },
      },
      drawCallback: function (settings) {
      },
      initComplete: function (data) {
        $("#season-tble_wrapper > .dt-buttons").appendTo("div.c");
        }
    };


  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  reDraw(): void {
     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
       dtInstance.clear().draw(); // Add this  line to clear all rows..
       dtInstance.destroy();      
       // dtTrigger la reconstruye
       this.dtTrigger.next(0);      
     });
   }
  
  getMantenciones(token, ppu, datefrom, dateto, ot) {
    this.SpinnerService.show();
    this._maintenanceService.getMantenimientos(token, ppu, datefrom, dateto, ot)
      .subscribe(
        response => {

          if(response.status=='success'){
          this.maintenances = response.data;
          this.reDraw();
          this.SpinnerService.hide();
        }else{
          this.maintenances = [];
          this.reDraw();
        }
        },
        error => {
              if (error.status != 401) {
                this.SpinnerService.hide();
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  width: 400,
                  text: error.error.message,
                  footer: '<a href="">¿Por qué tengo este problema?</a>'
                });
              } else if (error.status == 401) {
                this.SpinnerService.hide();
                Swal.fire({
                  icon: "warning",
                  title: "Sesión Expirada",
                  text: 'Su sesión ha finalizado'
                });
                this._router.navigate(['logout/1']);
              }
        }
      );
  }
  

  DetalleRepuestos(ot: number) {
    const modalRef = this.modalService.open(DetailSparePartsComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.ot = ot;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  UploadOT(ot: number, ppu) {
    const modalRef = this.modalService.open(UploadOTComponent, {
      centered: true,
      size: "md",
    });
    modalRef.componentInstance.ot = ot;
    modalRef.componentInstance.ppu = ppu;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  DetalleMantenimientos(ot: string, ppu:string) {
    const modalRef = this.modalService.open(DetailMaintenancesComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.ot = ot;
    modalRef.componentInstance.ppu = ppu;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }
  

  ExportToExcel() {
    this._maintenanceService.exportMaintenances(this.token, this.datefrom)
    .subscribe({
      next:(response) => {
        this.maintenancesExport = response.data;
        TableUtil.exportArrayToExcel(this.maintenancesExport, "Mantenimientos");
      },
      error:(error) => {
      },
      complete:()=>{
        this.SpinnerService.hide();
      }
  });
    
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  handleEnter(event){    
    if (event.charCode==13) this.getMantenciones(this.token, this.ppu, this.datefrom, this.dateto, this.ot);
  }

  limpiar(){
    this.ppu="";
    this.ot="";
    this.datefrom="";
    this.dateto="";
    this.maintenances = [];
    this.reDraw();
  }

  EliminarOT(ot, ppu){
    Swal.fire({
      title: "Eliminar Orden de Trabajo?",
      text: "¿Estas seguro(a) de que desea eliminar la OT: " + ot + "?",
      icon: "warning",
      width: "500px",
      heightAuto: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._maintenanceService.deleteMaintenance(this.token, ot, ppu)
        .subscribe((res) => {

          if (res.status == 'success') {
            Swal.fire("OT Eliminada", "", "success");
            this.getMantenciones(this.token, this.ppu, this.datefrom, this.dateto, this.ot);
          } else {            
            Swal.fire({
              icon: "error",
              title: "Ha ocudido el siguiente error:",
              text: res.message,
            });         
          }
        });
      }
    });
  }

}
