import { AbstractControl } from "@angular/forms";

export const ValidateRut = (rut: string)  => {

    if (rut == null || rut =="" || !rut.includes('-') ) return false;
   // let dv = rut.substring(rut.length, rut.length-1);
   // rut = rut.substring(0, rut.length-2);
 
   let aRut = rut.split('-');
   var dv:string = aRut[1];
   rut = aRut[0];
   let sum = 0;
   let mul = 2;
 
   let i = rut.length;
   while (i--) {
     sum = sum + parseInt(rut.charAt(i)) * mul;
     if (mul % 7 === 0) {
       mul = 2;
     } else {
       mul++;
     }
   }
 
   var res = sum % 11;
   let dv1: string;
   if (res === 0) {
     dv1 = '0';
   } else if (res === 1) {
     dv1 = 'K';
   } else dv1= `${11 - res}`.toString();
  if (dv1 == dv.toUpperCase() ) return true;
   else {return false;}
   //return `${11 - res}`;
 
   }
 
export const RutValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
 
     if (control.value !== undefined && !ValidateRut(control.value)) {
         return { 'errors': true };
     }
     return null;
 }

export const dataTableSettingNotButtonsMax = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  //pageLength: 10, 
  deferRender: true,
  autoWidth:true,
  //scrollX:        true,
  lengthMenu: [5, 10, 15, 20, 25, 50],
  dom: 'lfrtip',
  //"scrollCollapse": true,
  buttons: {
      buttons: [
        {
          extend: "excelHtml5",
          text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
          className: "btn btn-success",
          filename: "Hoja_de_Vida",
          enabled: true  
        },
      ],
    },
  /*columnDefs:[
    {
      targets: [9],
      searcheable: false,
      orderable:false
    }
  ],*/
  language: {
    emptyTable: "",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#season-tble_wrapper > .dt-buttons").appendTo("div.c");
    }
};

export const dataTableSettingCertDocumental = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  //pageLength: 10, 
  deferRender: true,
  autoWidth:true,
  //scrollX:        true,
  lengthMenu: [10, 15, 20, 25, 50],
  dom: 'Blfrtip',
  buttons: {
    dom:{
      button: {
        tag: "button",
        className: "",
      }
    },
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
        className: "btn btn-success",
        enabled: true,
        filename: "GestionFlota-Certificacion_Documental",
        exportOptions:{
          columns:[0,1,2,3,4,5,6,7,8,10,11]
        }
      },
    ],

  },
  /*columnDefs:[
    {
      targets: [9],
      searcheable: false,
      orderable:false
    }
  ],*/
  language: {
    emptyTable: "",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#tble-cert-ad_wrapper > .dt-buttons").appendTo("div.c2");
    }
};

export const dataTableSettingPlans = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  //pageLength: 10, 
  deferRender: true,
  autoWidth:true,
  //scrollX:        true,
  lengthMenu: [10, 15, 20, 25, 50],
  dom: 'Blfrtip',
  //"scrollCollapse": true,
  buttons: {
      buttons: [
        {
          extend: "excelHtml5",
          text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
          className: "btn btn-success",
          enabled: true
        },
      ],
    },
  /*columnDefs:[
    {
      targets: [9],
      searcheable: false,
      orderable:false
    }
  ],*/
  language: {
    emptyTable: "",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#season-tble_wrapper > .dt-buttons").appendTo("div.c");
    }
};

export const dataTableSettingTechnicalReviews = {
  info: true,
  responsive: true,  
  //"scrollY":        "520px",
  pageLength: 10, 
  deferRender: true,
  autoWidth:true,
  order: [[1, 'asc']],
  //scrollX:        true,
  lengthMenu: [10, 15, 20, 25, 50],
  dom: 'Blfrtip',
  //"scrollCollapse": true,
  buttons: {
      buttons: [
        {
          extend: "excelHtml5",
          text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
          class: "btn btn-success",
          enabled: true
        },
      ],
    },
  /*columnDefs:[
    {
      targets: [9],
      searcheable: false,
      orderable:false
    }
  ],*/
  language: {
    emptyTable: "",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#season-tble_wrapper > .dt-buttons").appendTo("div.c");
    }
};

export const dataTableSettingStatusCertification = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  //pageLength: 10, 
  deferRender: true,
  lengthChange: true,
  autoWidth:true,
  //scrollX:        true,
  lengthMenu: [10, 15, 20, 25, 50],
  dom: 'Blfrtip',
  buttons: {
    dom:{
      button: {
        tag: "button",
        className: "",
      }
    },
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
        className: "btn btn-success",
        enabled: true,
        filename: "GestionFlota-EstadoCertificacionBus"
      },
    ],

  },
  columnDefs:[
    {
      targets: [6,8,10,12],
      visible: false,
      
    }
  ],
  language: {
    emptyTable: "",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#season-tble_wrapper > .dt-buttons").appendTo("div.c");
    }
};

export const dataTableSettingSpareParts = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  deferRender: true,
  //scrollX:        true,
  lengthMenu: [5],
  dom: 'lfrtip',
  //"scrollCollapse": true,
  language: {
    emptyTable: "",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
};

export const dataTableSettingDetailMaintenances = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  pageLength: 5, 
  deferRender: true,
  //scrollX:        true,
  lengthMenu: [5],
  dom: 'lfrtip',
  //"scrollCollapse": true,
  language: {
    emptyTable: "",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
};

export const dataTableSettingChexbox = ()  =>{
  var rows_selected = [];
  var dataTableChekbox=  {
    info: true,
    responsive: true,
    //order: [[ 0, "desc" ]],
    //"scrollY":        "520px",
    pageLength: 10,
    //scrollX:        true,
    lengthMenu: [10, 12, 14, 15, 20, 30],
    dom: "lfrtip",
    'columnDefs': [{
      'targets': 0,
      'orderable': false,
      /*'searchable': false,
      'orderable': false,
      data:1,
      'className': 'dt-body-center',
      'render': function (data, type, full, meta){
          return '<input type="checkbox" name="id[]" value="' + $('<div/>').text(data).html() + '">';
      }*/
   }],
   buttons: {
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
        className: "btn btn-success",
        enabled: true
      },
    ],
  },
    language: {
      emptyTable: "",
      zeroRecords: "No hay coincidencias",
      lengthMenu: "Mostrar _MENU_ Elementos",
      search: "Buscar:",
      info: "De _START_ a _END_ de _TOTAL_ elementos",
      infoEmpty: "De 0 a 0 de 0 elementos",
      infoFiltered: "(filtrados de _MAX_ elementos totales)",
      paginate: {
        first: "Prim.",
        last: "Últ.",
        next: "Sig.",
        previous: "Ant.",
      },
      aria: {
        sortAscending: ": Ordenar columnas de forma ascendente",
        sortDescending: ": Ordenar columnas de forma descendente",
      },
    },
    initComplete: function (data, e) {
      //$("#season-tble_wrapper > .dt-buttons").appendTo("div.c");
      $("#season-tble_wrapper > .dt-buttons").appendTo("div.NoExport");
    },
    rowCallback: (row: Node, data: any[] | Object, index: number) => {
      const self = this;
      $('#example-select-all').on('click', function(){
        //$('input[type="checkbox"]', row).prop('checked', true);
         // Get row ID
        /*var checkedList = document.querySelectorAll("input[type=checkbox]:checked")
        if(checkedList.length > 0){
          $('input[type="checkbox"]', row).prop('checked', true);
        }else{
          $('input[type="checkbox"]', row).prop('checked', false);
        }*/

        // Get all rows with search applied
        //var rows = row;
        // Check/uncheck checkboxes for all rows in the table

     });
     //return row;
    },

  }


return dataTableChekbox;

};

export const dataTableSetting = {
  info: true,
  responsive: true,
  paging: false,
  searching: false,
  diplayStart:1,

  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  pageLength: 1, 
  deferRender: true,
  //scrollX:        true,
  dom: 'lrti',
  //"scrollCollapse": true,
  /*columnDefs:[
    {
      targets: [9],
      searcheable: false,
      orderable:false
    }
  ],*/
  language: {
    emptyTable: "",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
      $("#season-tble_wrapper > .dt-buttons").appendTo("div.c4");
    }
};

export const dataTableSettingAuditoryDocumental = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  //pageLength: 10, 
  deferRender: true,
  autoWidth:true,
  //scrollX:        true,
  lengthMenu: [10, 15, 20, 25, 50],
  dom: 'Blfrtip',

  //"scrollCollapse": true,
  buttons: {
    dom:{
      button: {
        tag: "button",
        className: "",
      }
    },
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
        className: "btn btn-success",
        enabled: true,
        filename: "GestionFlota-AuditoriaDocumental",
        exportOptions:{
          columns:[1,2,3,4,5,6,7,8,9,10,11]
        }
      },
    ],
  },
  language: {
    emptyTable: "No hay coincidencias",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#season-tble_wrapper > .dt-buttons").appendTo("div.c");
    }
};

export const dataTableSettingInspectionTechnical = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  //pageLength: 10, 
  deferRender: true,
  autoWidth:true,
  //scrollX:        true,
  lengthMenu: [10, 15, 20, 25, 50],
  dom: 'Blfrtip',
  buttons: {
    dom:{
      button: {
        tag: "button",
        className: "",
      }
    },
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
        className: "btn btn-success",
        enabled: true,
        filename: "GestionFlota-InspeccionTecnica",
        exportOptions:{
          columns:[0,1,2,3,4,5,6,7,8,9,10]
        }
      },
    ],
  },
  //"scrollCollapse": true,

  columnDefs:[
    {
      targets: 'no-sort',
      orderable:false
    },
  ],
  language: {
    emptyTable: "No hay coincidencias",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#tble-it_wrapper > .dt-buttons").appendTo("div.c");
    }
};

export const dataTableSettingInspectionTechnicalRevision = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  //pageLength: 10, 
  deferRender: true,
  autoWidth:true,
  //scrollX:        true,
  lengthMenu: [10, 15, 20, 25, 50],
  dom: 'lfrtip',
  buttons: {
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
        className: "btn btn-success",
        enabled: true
      },
    ],
  },
  //"scrollCollapse": true,

  columnDefs:[
    {
      targets: 'no-sort',
      orderable:false
    },
  ],
  language: {
    emptyTable: "No hay coincidencias",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#season-tble_wrapper > .dt-buttons").appendTo("div.c");
    }
};

export const dataTableSettingEstadoGeneralBus = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  //pageLength: 10, 
  deferRender: true,
  autoWidth:true,
  //scrollX:        true,
  lengthMenu: [10, 15, 20, 25, 50],
  dom: 'Blfrtip',
  buttons: {
    dom:{
      button: {
        tag: "button",
        className: "",
      }
    },
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
        className: "btn btn-success",
        enabled: true,
        filename: "GestionFlota-EstadoGeneralDelBus",
        exportOptions:{
          columns:[0,1,2,3,4,5,6,7,8,9,10,11,13,15]
        }
      },
    ],
  },
  language: {
    emptyTable: "No hay coincidencias",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#tble-egb_wrapper > .dt-buttons").appendTo("div.c");
    }
};

export const dataTableSettingCertEstadoGeneralBus = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  //pageLength: 10, 
  deferRender: true,
  autoWidth:true,
  //scrollX:        true,
  lengthMenu: [10, 15, 20, 25, 50],
  dom: 'Blfrtip',
  buttons: {
    dom:{
      button: {
        tag: "button",
        className: "",
      }
    },
    buttons: [
      {
        extend: "excelHtml5",
        text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
        className: "btn btn-success",
        enabled: true,
        filename: "GestionFlota-Cert_EstadoGeneralDelBus",
        exportOptions:{
          columns:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,16,19]
        }
      },
    ],
  },
  language: {
    emptyTable: "No hay coincidencias",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#tble-egb_wrapper > .dt-buttons").appendTo("div.c");
    }
};

export const dataTableSettingSupportsLocal = {
  info: true,
  responsive: true,
  //order: [[ 0, "desc" ]],
  //"scrollY":        "520px",
  //pageLength: 10, 
  deferRender: true,
  autoWidth:true,
  order: [[0, 'desc']],
  //scrollX:        true,
  lengthMenu: [10, 15, 20, 25, 50],
  dom: 'lfrtip',
  //"scrollCollapse": true,
  // buttons: {
  //     buttons: [
  //       {
  //         extend: "excelHtml5",
  //         text: '<i class="fa fa-arrow-circle-down"> <span style="font-family: tahoma; font-size: 14px;">Exportar</span></i>',
  //         class: "btn btn-success",
  //         enabled: true
  //       },
  //     ],
  //   },
  /*columnDefs:[
    {
      targets: [9],
      searcheable: false,
      orderable:false
    }
  ],*/
  language: {
    emptyTable: "",
    zeroRecords: "No hay coincidencias",
    lengthMenu: "Mostrar _MENU_ Elementos",
    processing: "Procesando...",
    search: "Buscar:",
    info: "De _START_ a _END_ de _TOTAL_ elementos",
    infoEmpty: "De 0 a 0 de 0 elementos",
    infoFiltered: "(filtrados de _MAX_ elementos totales)",
    paginate: {
      first: "Prim.",
      last: "Últ.",
      next: "Sig.",
      previous: "Ant.",
    },
    aria: {
      sortAscending: ": Ordenar columnas de forma ascendente",
      sortDescending: ": Ordenar columnas de forma descendente",
    },
  },
  drawCallback: function (settings) {
  },
  initComplete: function (data) {
    $("#season-tble_wrapper > .dt-buttons").appendTo("div.c");
    }
};