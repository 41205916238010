import { AfterViewInit, Component, OnInit, DoCheck, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Bus } from 'src/app/models/bus';
import Swal from 'sweetalert2';
import { ApiResponse } from 'src/app/models/response';
import { Maintenance } from 'src/app/models/maintence';
import * as bootstrap from "bootstrap";
import { UserService } from 'src/app/services/user.services';
import { BusService } from 'src/app/services/bus.services';
import { AuditsService } from 'src/app/services/audits.services';
import { Audit } from 'src/app/models/audit';
import { _MatRadioButtonHarnessBase } from '@angular/material/radio/testing';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { DetailMaintenancesComponent } from 'src/app/views/detail-maintenances/detail-maintenances.component';
import { DetailSparePartsComponent } from 'src/app/views/detail-spareparts/detail-spareparts.component';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { dataTableSettingNotButtonsMax, dataTableSetting } from 'src/app/models/dictionary';
import {formatDate} from '@angular/common';
import { DetailReviewStatusBusComponent } from 'src/app/views/detail-review-status-bus/detail-review-status-bus.component';
import { ResumenInspectionComponent } from 'src/app/views/resumen-inspection/resumen-inspection.component';
import { DetailInspectionComponent } from 'src/app/views/detail-inspection/detail-inspection.component';
import { TableUtil } from 'src/app/services/excel-export';
import * as XLSX from "xlsx";


@Component({
  selector: 'app-hoja-vida',
  templateUrl: './hoja-vida.component.html',
  styleUrls: ['./hoja-vida.component.css'],
})
export class HojaVidaComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;

  excel:any;
  public bus: any[] = [];
  maintenances: Maintenance[] = [];
  audits: Audit[] = [];
  reviews: any[] = [];
  inspections: any[] = [];
  inspections_reviews: any[] = [];
  requests_sparesparts: any[] = [];
  requests_claims: any[] = [];

  public bus2: any[] = [];
  maintenances2: Maintenance[] = [];
  audits2: Audit[] = [];
  reviews2: any[] = [];
  inspections2: any[] = [];
  inspections_reviews2: any[] = [];
  requests_sparesparts2: any[] = [];
  requests_claims2: any[] = [];
  ppu: string="";
  public token;
  public datefrom="";
  public dateto="";
  public ot="";
  public data:any[]=[];
  //public buses:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtTrigger2: Subject<any> = new Subject();
  dtTrigger3: Subject<any> = new Subject();
  dtTrigger4: Subject<any> = new Subject();
  dtTrigger5: Subject<any> = new Subject();
  dtTrigger6: Subject<any> = new Subject();
  dtTrigger7: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  constructor(
    private modalService: NgbModal,
    private _busService: BusService,
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    public _AuditsService: AuditsService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.token = this._UserService.getToken();
    registerLocaleData(es);
    this.dtOptions = dataTableSettingNotButtonsMax;
    this.datefrom =  formatDate(new Date(), 'yyyy-MM', 'en')
    this.dateto = formatDate(new Date(), 'yyyy-MM', 'en')
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  getHistoryBus(ppu) {
  }

  BuscarMantenimientos() {

      this.SpinnerService.show();
      this._busService.getMantenimientos(this.token, this.ppu, this.datefrom, this.dateto, this.ot)
        .subscribe({
          next:(response)=>{
            if (response.status == 'success') {
                this.bus = response.data.bus as Bus[];
                if (!this.bus){
                  Swal.fire({
                    icon: 'info',
                    title: 'Bus no encontrado',
                    width: 400,
                    text: "Bus no existe en los registros",
                  });
                  return;
                }

              if ((response as ApiResponse).data.mantenimientos)
                this.maintenances = (response as ApiResponse).data.mantenimientos as Maintenance[];
              else
                this.maintenances = [];

              if ((response as ApiResponse).data.audits)
                this.audits = (response as ApiResponse).data.audits as Audit[];
              else {
                this.audits = [];
              }

              if ((response as ApiResponse).data.reviews)
                this.reviews = (response as ApiResponse).data.reviews as any[];
              else {
                this.reviews = [];
              }

              if ((response as ApiResponse).data.inspections)
                this.inspections = (response as ApiResponse).data.inspections as any[];
              else {
                this.inspections = [];
              }

              if ((response as ApiResponse).data.inspections_reviews)
                this.inspections_reviews = (response as ApiResponse).data.inspections_reviews as any[];
              else {
                this.inspections_reviews = [];
              }

              if ((response as ApiResponse).data.requests_sparesparts)
                this.requests_sparesparts = (response as ApiResponse).data.requests_sparesparts as any[];
              else {
                this.requests_sparesparts = [];
              }

              if ((response as ApiResponse).data.requests_claims)
                this.requests_claims = (response as ApiResponse).data.requests_claims as any[];
              else {
                this.requests_claims = [];
              }

              this.rerender();
              this.SpinnerService.hide();
            } else {
              Swal.fire({
                icon: 'info',
                title: 'Información',
                width: 400,
                text: response.message,
              });
              this.SpinnerService.hide();
            }
          },
          error:(error)=>{
            if (error.status != 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado',
                showCancelButton: false,
                confirmButtonColor: "#4dbd74",
                confirmButtonText: "Continuar"});
                this._router.navigate(['logout/1']);
            }
          }
        });
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      if (dtElement.dtInstance)
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
    });
    setTimeout(() => {
      this.dtTrigger.next(0);
      this.dtTrigger2.next(0);
      this.dtTrigger3.next(0);
      this.dtTrigger4.next(0);
      this.dtTrigger5.next(0);
      this.dtTrigger6.next(0);
      this.dtTrigger7.next(0);
    });

  }

  limpiar() {
    this.ppu = "";    
    this.ot=""
    this.bus = [];
    this.maintenances = [];
    this.audits = [];
    this.reviews = [];
    this.inspections = []
    this.inspections_reviews = [];
    this.requests_sparesparts = [];
    this.requests_claims = [];
    
    this.rerender();

    this.bus2 = [];
    this.maintenances2= [];
    this.audits2= [];
    this.reviews2 = [];
    this.inspections2 = [];
    this.inspections_reviews2 = [];
    this.requests_sparesparts2 = [];
    this.requests_claims2 = [];
  }

  ChangePPU(){
    if (this.maintenances.length>0){
      this.maintenances = [];
      this.bus = null;
      this.audits = [];
      this.ot=""
      this.datefrom="";
      this.reviews = [];
      this.rerender();
    }else{
      this.ot=""
      this.datefrom="";
    }
  }

  DetalleRepuestos(ot: number) {
    const modalRef = this.modalService.open(DetailSparePartsComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.ot = ot;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  DetalleMantenimientos(ot: string) {
    const modalRef = this.modalService.open(DetailMaintenancesComponent, {
      centered: true,
      size: "xl",
    });
    modalRef.componentInstance.ot = ot;
    modalRef.componentInstance.ppu = this.ppu;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(0);
    this.dtTrigger2.next(0);
    this.dtTrigger3.next(0);
    this.dtTrigger4.next(0);
    this.dtTrigger5.next(0);
    this.dtTrigger6.next(0);
    this.dtTrigger7.next(0);
  }

  downloadPdf(review, fileName) {
    //const source = `data:application/pdf;base64,${base64String}`;
    /*
        */
    const source = review;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

  onClickDownloadPdf(ppu, base64String, tipo?){
    this.downloadPdf(base64String, tipo + '_' + ppu + "_" + formatDate(new Date(), 'yyyyMM', 'en'));
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtTrigger2.unsubscribe();
    this.dtTrigger3.unsubscribe();
    this.dtTrigger4.unsubscribe();
    this.dtTrigger5.unsubscribe();
    this.dtTrigger6.unsubscribe();
    this.dtTrigger7.unsubscribe();
  }

  reDraw(): void {
    this.dtElements[0].dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);
      this.dtTrigger2.next(0);
      this.dtTrigger3.next(0);
      this.dtTrigger4.next(0);
      this.dtTrigger5.next(0);
      this.dtTrigger6.next(0);
      this.dtTrigger7.next(0);
    });
  }

  handleEnter(event){
    if (event.charCode==13) this.BuscarMantenimientos();
  }

  DetalleRevisionEstadoGeneralBus(inspection) {
    const modalRef = this.modalService.open(DetailReviewStatusBusComponent, {
      centered: true,
      size: "xl",
    });

    modalRef.componentInstance.id_inspeccion = inspection.id_inspeccion;
    modalRef.componentInstance.ppu = inspection.ppu;
    modalRef.componentInstance.resultado = inspection.resultado;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  DetalleInspeccion(id, resultado){
    const modalRef = this.modalService.open(DetailInspectionComponent, {
      centered:true,
      size:'xl'

    });
    modalRef.componentInstance.id_inspeccion = id;
    modalRef.componentInstance.resultado = resultado=='A'?'APROBADO':'NO APROBADO';
    modalRef.result.then(
      (result) => {
        if (result == "Yes") {
          console.info('Ok');
        }

      },
      (cancel) => {
      }
    );
  }

  ResumenInspeccion(id){
    const modalRef = this.modalService.open(ResumenInspectionComponent, {
      centered:true,
      size:'xl'

    });
    modalRef.componentInstance.id_inspeccion = id;

    modalRef.result.then(
      (result) => {
        if (result == "Yes") {
          console.info('Ok');
        }

      },
      (cancel) => {
      }
    );
  }

  ExportarExcel(){

this.SpinnerService.show();
  this._busService.getMantenimientos2(this.token, this.ppu, this.datefrom, this.dateto, this.ot)
        .subscribe({
          next:(response)=>{
            if (response.status == 'success') {
                this.bus2 = response.data.bus as Bus[];

              if ((response as ApiResponse).data.mantenimientos)
                this.maintenances2 = (response as ApiResponse).data.mantenimientos as Maintenance[];
              else
                this.maintenances2 = [];

              if ((response as ApiResponse).data.audits)
                this.audits2 = (response as ApiResponse).data.audits as Audit[];
              else {
                this.audits2 = [];
              }

              if ((response as ApiResponse).data.reviews)
                this.reviews2 = (response as ApiResponse).data.reviews as any[];
              else {
                this.reviews2 = [];
              }

              if ((response as ApiResponse).data.inspections)
                this.inspections2 = (response as ApiResponse).data.inspections as any[];
              else {
                this.inspections2 = [];
              }

              if ((response as ApiResponse).data.inspections_reviews)
                this.inspections_reviews2 = (response as ApiResponse).data.inspections_reviews as any[];
              else {
                this.inspections_reviews2 = [];
              }

              if ((response as ApiResponse).data.requests_sparesparts)
                this.requests_sparesparts2 = (response as ApiResponse).data.requests_sparesparts as any[];
              else {
                this.requests_sparesparts2 = [];
              }

              if ((response as ApiResponse).data.requests_claims)
                this.requests_claims2 = (response as ApiResponse).data.requests_claims as any[];
              else {
                this.requests_claims2 = [];
              }

              var wb = XLSX.utils.book_new();

              var ws = XLSX.utils.json_to_sheet(this.bus2);
              XLSX.utils.book_append_sheet(wb, ws, "Buses");

              if (this.maintenances2.length>0){
                var ws2 = XLSX.utils.json_to_sheet(this.maintenances2);
                XLSX.utils.book_append_sheet(wb, ws2, "Mantenimientos");
              }
              if (this.audits2.length>0){
                var ws3 = XLSX.utils.json_to_sheet(this.audits2);
                XLSX.utils.book_append_sheet(wb, ws3, "Auditoria");
              }
              if (this.reviews2.length>0){
                var ws4 = XLSX.utils.json_to_sheet(this.reviews2);
                XLSX.utils.book_append_sheet(wb, ws4, "Revision Tecnica");
              }
              if (this.inspections2.length>0){
                var ws5 = XLSX.utils.json_to_sheet(this.inspections2);
                XLSX.utils.book_append_sheet(wb, ws5, "Inspeccion Tecnica");
              }
              if (this.inspections_reviews2.length>0){
                var ws6 = XLSX.utils.json_to_sheet(this.inspections_reviews2);
                XLSX.utils.book_append_sheet(wb, ws6, "Estado GB");
              }
              if (this.requests_claims2.length>0){
                var ws7 = XLSX.utils.json_to_sheet(this.requests_claims2);
                XLSX.utils.book_append_sheet(wb, ws7, "Reclamo Repuestos");
              }
              if (this.requests_sparesparts2.length>0){
                var ws8 = XLSX.utils.json_to_sheet(this.requests_sparesparts2);
                XLSX.utils.book_append_sheet(wb, ws8, "Atenciones");
              }

              if (this.bus2.length==1)
                XLSX.writeFile(wb, "Hoja_de_Vida_PPU_" + this.bus2[0].ppu + ".xlsx");
              else
                XLSX.writeFile(wb, "Hoja_de_Vida_Historico.xlsx");

              this.SpinnerService.hide();
            } else {
              Swal.fire({
                icon: 'info',
                title: 'Información',
                width: 400,
                text: response.message,
              });
              this.SpinnerService.hide();
            }
          },
          error:(error)=>{
            if (error.status != 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado',
                showCancelButton: false,
                confirmButtonColor: "#4dbd74",
                confirmButtonText: "Continuar"});
                this._router.navigate(['logout/1']);
            }
          }
        });

  }
/*
  getHeaderNames(table) {
    // Gets header names.
    //params:
    //  table: table ID.
    //Returns:
    //  Array of column header names.

    var header = $(table).DataTable().columns().toArray();

    var names = [];
    header.forEach(function(th) {
     names.push($(th).html());
    });

    return names;
  }

  buildCols(data) {
    // Builds cols XML.
    //To do: deifne widths for each column.
    //Params:
    //  data: row data.
    //Returns:
    //  String of XML formatted column widths.

    var cols = '<cols>';
    var colNum
    for (var i=0; i<data.length; i++) {
      colNum = i + 1;
      cols += '<col min="' + colNum + '" max="' + colNum + '" width="20" customWidth="1"/>';
    }

    cols += '</cols>';

    return cols;
  }

  buildRow(data, rowNum, styleNum) {
    // Builds row XML.
    //Params:
    //  data: Row data.
    //  rowNum: Excel row number.
    //  styleNum: style number or empty string for no style.
    //Returns:
    //  String of XML formatted row.

    var style = styleNum ? ' s="' + styleNum + '"' : '';

    var row = '<row r="' + rowNum + '">';
    var colNum
    for (var i=0; i<data.length; i++) {
      colNum = (i + 10).toString(36).toUpperCase();  // Convert to alpha

      var cr = colNum + rowNum;

      row += '<c t="inlineStr" r="' + cr + '"' + style + '>' +
              '<is>' +
                '<t>' + data[i] + '</t>' +
              '</is>' +
            '</c>';
    }

    row += '</row>';

    return row;
  }

  setSheetName(xlsx, name) {
    // Changes tab title for sheet.
    //Params:
    //  xlsx: xlxs worksheet object.
    //  name: name for sheet.

    if (name.length > 0) {
      var source = xlsx.xl['workbook.xml'].getElementsByTagName('sheet')[0];
      source.setAttribute('name', name);
    }
  }

  addSheet(xlsx, table, title, name, sheetId) {
    //Clones sheet from Sheet1 to build new sheet.
    //Params:
    //  xlsx: xlsx object.
    //  table: table ID.
    //  title: Title for top row or blank if no title.
    //  name: Name of new sheet.
    //  sheetId: string containing sheetId for new sheet.
    //Returns:
    //  Updated sheet object.

    //Add sheet2 to [Content_Types].xml => <Types>
    //============================================
    var source = xlsx['[Content_Types].xml'].getElementsByTagName('Override')[1];
    var clone = source.cloneNode(true);
    clone.setAttribute('PartName','/xl/worksheets/sheet' + sheetId + '.xml');
    xlsx['[Content_Types].xml'].getElementsByTagName('Types')[0].appendChild(clone);

    //Add sheet relationship to xl/_rels/workbook.xml.rels => Relationships
    //=====================================================================
    var source = xlsx.xl._rels['workbook.xml.rels'].getElementsByTagName('Relationship')[0];
    var clone = source.cloneNode(true);
    clone.setAttribute('Id','rId'+sheetId+1);
    clone.setAttribute('Target','worksheets/sheet' + sheetId + '.xml');
    xlsx.xl._rels['workbook.xml.rels'].getElementsByTagName('Relationships')[0].appendChild(clone);

    //Add second sheet to xl/workbook.xml => <workbook><sheets>
    //=========================================================
    var source = xlsx.xl['workbook.xml'].getElementsByTagName('sheet')[0];
    var clone = source.cloneNode(true);
    clone.setAttribute('name', name);
    clone.setAttribute('sheetId', sheetId);
    clone.setAttribute('r:id','rId'+sheetId+1);
    xlsx.xl['workbook.xml'].getElementsByTagName('sheets')[0].appendChild(clone);

    //Add sheet2.xml to xl/worksheets
    //===============================
    var newSheet = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>'+
      '<worksheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships" xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006" xmlns:x14ac="http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac" mc:Ignorable="x14ac">'+
      this.getTableData(table, title) +

      '</worksheet>';

    xlsx.xl.worksheets['sheet' + sheetId + '.xml'] = $.parseXML(newSheet);

  }

  getTableData(table, title) {
    // Processes Datatable row data to build sheet.
    //Params:
    //  table: table ID.
    //  title: Title displayed at top of SS or empty str for no title.
    //Returns:
    //  String of XML formatted worksheet.

    var header = this.getHeaderNames(table);
    table = $(table).DataTable();
    var rowNum = 1;
    var mergeCells = '';
    var ws = '';

    ws += this.buildCols(header);
    ws += '<sheetData>';
    var mergeCol
    if (title.length > 0) {
      ws +=  this.buildRow([title], rowNum, 51);
      rowNum++;

      mergeCol = ((header.length - 1) + 10).toString(36).toUpperCase();

      mergeCells = '<mergeCells count="1">'+
        '<mergeCell ref="A1:' + mergeCol + '1"/>' +
                   '</mergeCells>';
    }

    ws +=  this.buildRow(header, rowNum, 2);
    rowNum++;

    // Loop through each row to append to sheet.
    table.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
      var data = this.data();

      // If data is object based then it needs to be converted
      // to an array before sending to buildRow()
      ws +=  this.buildRow(data, rowNum, '');

      rowNum++;
    } );

    ws += '</sheetData>' + mergeCells;

    return ws;

  }
*/
}

