import { ChangeDetectorRef, Component, OnInit, DoCheck } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Bus } from 'src/app/models/bus';
import Swal from 'sweetalert2';
import { BusService } from 'src/app/services/bus.services';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-bus',
  templateUrl: './register-bus.component.html',
  styleUrls: ['./register-bus.component.css'],
  providers: [BusService, UserService]
})
export class BusComponent implements OnInit, DoCheck {
  public name: string = 'Registrar Bus';
  public edit: boolean = false;
  public bus: Bus = new Bus();
  public token;
  public identity;
  addBusForm: FormGroup;
  constructor(
    public modal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private _BusService: BusService,
    private _UserService: UserService,
    private _router: Router,
  ) {
    this.addBusForm = this.formBuilder.group({
      ppu: new FormControl("", Validators.required),
      vin: new FormControl("", Validators.required),
      chassis: new FormControl("", Validators.required),
      marca: new FormControl("", Validators.required),
      modelo: new FormControl("", Validators.required),
      motor: new FormControl("", Validators.required),
      modelo_motor: new FormControl("", Validators.required),
      anio_bus: new FormControl("", Validators.required),
    });

  }

  get form() {
    return this.addBusForm.controls;
  }
  ngOnInit() {

    this.token = this._UserService.getToken();
    this.identity = this._UserService.getIdentity();

    if (this.bus.ppu) {
      this.edit = true;
      this.addBusForm.controls['ppu'].setValue(this.bus.ppu);
      this.addBusForm.controls['vin'].setValue(this.bus.vin);
      this.addBusForm.controls['chassis'].setValue(this.bus.chassis);
      this.addBusForm.controls['marca'].setValue(this.bus.marca);
      this.addBusForm.controls['modelo'].setValue(this.bus.modelo);
      this.addBusForm.controls['motor'].setValue(this.bus.motor);
      this.addBusForm.controls['modelo_motor'].setValue(this.bus.modelo_motor);
      this.addBusForm.controls['anio_bus'].setValue(this.bus.anio_bus);
    }
  }


  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }
  CloseModal() {
    this.modal.close('Cerrar');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  SaveBus() {
    this.SpinnerService.show();
    this.bus.ppu = this.addBusForm.get('ppu').value;
    this.bus.vin = this.addBusForm.get('vin').value;
    this.bus.chassis = this.addBusForm.get('chassis').value;
    this.bus.marca = this.addBusForm.get('marca').value;
    this.bus.modelo = this.addBusForm.get('modelo').value;
    this.bus.motor = this.addBusForm.get('motor').value;
    this.bus.modelo_motor = this.addBusForm.get('modelo_motor').value;
    this.bus.anio_bus = this.addBusForm.get('anio_bus').value;
    if (!this.edit)
      this.saveBus(this.bus);
    else
      this.updateBus(this.bus);

  }

  saveBus(bus) {
    this._BusService.saveBus(this.token, bus)
      .subscribe(
        response => {
          Swal.fire("Bus Creado", "", "success");
          this.SpinnerService.hide();
          this.modal.close('Yes');
        },
        error => {
          if (error.status == 404) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"});
              this._router.navigate(['logout/1']);
          }
        }

      )
  }

  updateBus(bus) {
    this._BusService.updateBus(this.token, bus)
      .subscribe(
        response => {
          
          Swal.fire("Bus Actualizado", "", "success");
          this.SpinnerService.hide();
          this.modal.close('Yes');
        },
        error => {
            if (error.status == 404) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado',
                showCancelButton: false,
                confirmButtonColor: "#4dbd74",
                confirmButtonText: "Continuar"});
                this._router.navigate(['logout/1']);
            }
        }

      )
  }

}



