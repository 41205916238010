import { ChangeDetectorRef, Component, OnInit, DoCheck } from '@angular/core';
import { UserService } from 'src/app/services/user.services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CertificationService } from 'src/app/services/certification.services';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
    selector: 'app-audit-report',
    templateUrl: './informe-auditoria.component.html',
    styleUrls: ['./informe-auditoria.component.css']
})
export class InformeAuditoriaComponent implements OnInit, DoCheck {
    public token;
    public ppu = "";
    public datefrom="";
    public file:File;
    public base64;
    public ots:any[] =  [];
    addReportForm: FormGroup;
    constructor(
        private SpinnerService: NgxSpinnerService,
        private _UserService: UserService,
        public modal: NgbActiveModal,
        private _formBuilder: FormBuilder,
        private changeDetector: ChangeDetectorRef,
        public _CertificationService:CertificationService,
        private _router: Router,
     ) {


        
    }
    ngOnInit() {
        this.token = this._UserService.getToken();
        this.addReportForm = this._formBuilder.group({
            ppu: new FormControl({value:this.ppu, disabled:true}, Validators.required),
            datefrom: new FormControl(this.datefrom, Validators.required),
            status: new FormControl("", Validators.required),
            file: new FormControl("", Validators.required),
            observations: new FormControl("")
          });

    }

    ngDoCheck(): void {
        this.token = this._UserService.getToken();
    }

    CloseModal() {
        this.modal.close('');
    }

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }

    uploadFile($event) {
        var file = $event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
        this.base64 = reader.result; };
    }

    saveReport(){
        
        var request:any = {
            "ppu": this.ppu,
            "datefrom": this.datefrom,
            "status": this.addReportForm.get('status').value,
            "evidencia":  this.base64 ,
            "observations": this.addReportForm.get('observations').value,
            "ots" : JSON.stringify(this.ots)
        }

        this.SpinnerService.show();
        this._CertificationService.saveDocumentaryAuditCertification(this.token, request)
        .subscribe(
            response => {
                if(response.status == "success"){
                    this.SpinnerService.hide();
                    Swal.fire("Informe registrado correctamente", "", "success");                    
                    this.modal.close('Yes');
                }else {
                    Swal.fire("Se ha producido un error", "", "error");
                    this.SpinnerService.hide();
                  }
                
            },
            error => {
                if (error.status != 401) {
                this.SpinnerService.hide();
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    width: 400,
                    text: error.error.message,
                    footer: '<a href="">¿Por qué tengo este problema?</a>'
                });
                } else if (error.status == 401) {
                this.SpinnerService.hide();
                Swal.fire({
                    icon: "warning",
                    title: "Sesión Expirada",
                    text: 'Su sesión ha finalizado'
                });
                this._router.navigate(['logout/1']);
                }
            }
        )
    }

    getBase64(file) {
        
        
        
     }
}