export class Bus{
    id:number;
    vin:string;
    ppu:string;
    chassis:string;
    marca:string;
    modelo:string;
    unidad_servicio:string;
    fecha_entrega_op:string;
    km_inicial:number;
    operadorId:string;
    fecha_desde:string;
    fecha_hasta:string;
    codigo_baja:number;
    motivo_baja:string;
    maquina:string;
    motor:string;
    taller_bus:string;
    anio_bus:number;
    marca_carroceria:string;
    modelo_carroceria:string;
    marca_motor:string;
    modelo_motor:string;
    marca_transmision:string;
    modelo_transmision:string;
    tipo_propulsion:string;
    norma_emision:string;
    nro_cert_homologacion:string;
    nro_plazas_total:number;
    tipologia_bus:string;

};