// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group-prepend {
    flex: 0 0 30% !important;
    
  }
  
  .input-group-text {
    width:100% !important;
    align-content:flex-end !important;
  }`, "",{"version":3,"sources":["webpack://./src/app/supports/views/delivery/modal/accept-request/accept-request.component.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;;EAE1B;;EAEA;IACE,qBAAqB;IACrB,iCAAiC;EACnC","sourcesContent":[".input-group-prepend {\r\n    flex: 0 0 30% !important;\r\n    \r\n  }\r\n  \r\n  .input-group-text {\r\n    width:100% !important;\r\n    align-content:flex-end !important;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
