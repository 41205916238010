import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';
import { HTTP_OPTIONS } from './header.config';



@Injectable()
export class AuditsService{

	public url;


	constructor(
		public _http: HttpClient,
	){
		this.url = global.url;
	}


    getAudits(token): Observable<any>{
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
										//.set('Authorization', token);
		let headers = new HttpHeaders()
				.set('Authorization', token);
		return this._http.get(this.url + '/audit/get' , {headers:headers});

	}
    getAuditsPPU(token, ppu): Observable<any>{
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
										//.set('Authorization', token);
		let headers = new HttpHeaders()
				.set('Authorization', token);
		return this._http.get(this.url + '/audit/find/'+ ppu , {headers:headers});

	}

	createAudit(token, audit): Observable<any>{
			//.set('Authorization', token);
			let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
			let json = JSON.stringify(audit);
			let params = 'json='+json;
			return this._http.post(this.url + '/audit/ot/create', params, {headers: headers});
	}

	getOTDetail(token, ppu, ot):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/audit/ot/detail?ppu=' +ppu + "&ot=" + ot  ,{headers:headers});
	}

	createAuditFile(token, file:any): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(file);
		let params = 'json='+json;
		return this._http.post(this.url + '/audit/maintenance/files/create', params, {headers:headers});
	}
	
	getAuditInspeccionesTecnicas(token, ppu, datefrom, dateto?):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/audit/inspections/technical/get?&ppu=' +ppu+'&datefrom='+datefrom + '&dateto='+dateto   ,{headers:headers});
	}

	getAuditoriasInspeccionTecnica(token, ppu, datefrom, dateto?):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/audit/inspections/auditories/get?&ppu=' +ppu+'&datefrom='+datefrom + '&dateto='+dateto   ,{headers:headers});
	}

	getSistemaInspeccionTecnica(token):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/audit/components/inspection/get' ,{headers:headers});
	}

	createAuditInspection(token, inspection):Observable<any>{

		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(inspection);
		let params = 'json='+json;
		return this._http.post(this.url + '/audit/inspection/create', params ,{headers:headers});
	}

	getSistemaEGB(token):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/audit/components/status-general-bus/get' ,{headers:headers});
	}

	getEstadoGeneralBus(token, ppu="", datefrom="", dateto=""):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/audit/review-status/inspection/reports/get?ppu='+ppu +'&datefrom='+datefrom +'&dateto=' + dateto,{headers:headers});
		
	}

	createAuditInspectionReview(token, inspection):Observable<any>{

		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(inspection);
		let params = 'json='+json;
		return this._http.post(this.url + '/audit/review-status/inspection/create', params ,{headers:headers});
	}

	DeleteEstadoGeneralBus(token, id):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.delete(this.url + '/audit/review-status/inspection/delete/'+id ,{headers:headers});
		
	}

	DeleteInspeccionTecnica(token, id):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.delete(this.url + '/audit/inspection/delete/'+id ,{headers:headers});
		
	}

	DeleteInspeccionTecnicaReport(token, id):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.delete(this.url + '/audit/inspection/report/delete/'+id ,{headers:headers});
		
	}

	DeleteInformeEstadoGeneralBus(token, id):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.delete(this.url + '/audit/review-status/report/delete/'+id ,{headers:headers});
		
	}

	getDetalleRevisionEstadoGeneralBus(token, id):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/audit/review-status/inspection/detail/'+id,{headers:headers});
		
	}

	updatePautaEstadoGeneralBus(token, request):Observable<any>{

		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(request);
		let params = 'json='+json;
		return this._http.post(this.url + '/audit/review-status/inspection/update/pauta', params ,{headers:headers});
	}

	DeleteAuditoriaDocumental(token, id):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.delete(this.url + '/audit/ot/delete/'+id ,{headers:headers});
		
	}

	DeleteInformeAuditoriaDocumental(token, id):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.delete(this.url + '/audit/report/delete/'+id ,{headers:headers});
		
	}

	getDetalleInspeccionTecnica(token, id):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/audit/inspections/technical/detail/'+id,{headers:headers});
		
	}

	getResumenInspeccionTecnica(token, id):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/audit/inspections/technical/resumen/'+id,{headers:headers});
		
	}

	
}