import { ChangeDetectorRef, Component, OnInit, DoCheck } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
//import { Bus } from 'src/app/models/bus';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.services';
import { AuditsService } from 'src/app/services/audits.services';
import { Router } from '@angular/router';
import { Audit } from 'src/app/models/audit';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Observable, ReplaySubject } from 'rxjs';
import { MaintenceFile } from 'src/app/models/maintence';
import { PagerService } from 'src/app/services/pager.services';
@Component({
  selector: 'app-audit-details',
  templateUrl: './audit-details.component.html',
  styleUrls: ['./audit-details.component.css'],
})
export class AuditDetailComponent implements OnInit, DoCheck {
  public token;
  public ppu="";
  public ot="";
  public audits:any[]  = new Array<any>;
  public maintenances:any[]= new Array<any>;
  pager: any = {};
  // paged items
  pagedItems: any[];
  totalxPage: number = 10;

  constructor(
    public modal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private _router: Router,
    private _AuditsService: AuditsService,
    private _pagerService:PagerService
  ) {

  }

  ngOnInit() {
    registerLocaleData(es);
    this.token = this._UserService.getToken();
    this.getOTDetail();
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }
  CloseModal() {
    this.modal.close('Cerrar');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
    //checkAllCheckBox(ev) { // Angular 9
  checkAllCheckBox(ev: any) {
    // Angular 13
    this.audits.forEach((x) => (x.selected = ev.target.checked));
  }

  getOTDetail() {
    this.SpinnerService.show();
    this._AuditsService.getOTDetail(this._UserService.getToken(), this.ppu, this.ot)
      .subscribe(
        response => {
        
          this.pager = {};
          this.maintenances = response.data;
          this.pagedItems = this.setPage(1, this.maintenances);
          this.SpinnerService.hide();
        },
        error => {
            if (error.status == 404) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado',
                showCancelButton: false,
                confirmButtonColor: "#4dbd74",
                confirmButtonText: "Continuar"});
                this._router.navigate(['logout/1']);
            }
          }
      );
  }

setPage(page: number, list:any[]):any[]{
  if (page < 1 || page > this.pager.totalPages) {
    return;
  }

  // get pager object from service
  this.pager = this._pagerService.getPager(list.length, page, +this.totalxPage);

  // get current page of items
  var pagedItems = list.slice(this.pager.startIndex, this.pager.endIndex + 1);

  return pagedItems;
}

}



