import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { appRoutingProviders, routing } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { LoginComponent } from './login/login.component';
import { name } from '../../package.json';
import { AdminModule } from './admin/admin.module';
import { UserGuard } from './services/user.guard';
import { NoIdentityGuard } from './services/no.identity.guard';
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { BusesModule } from './buses/buses.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { TechnicalReviewModule } from './technical-review/technical-review.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from 'src/material.module';
import $ from 'jquery';
import * as bootstrap from "bootstrap";
import { NgSelectModule } from '@ng-select/ng-select';
import { AuditModule } from './audit/audit.module';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs';
import { CertificationModule } from './certification/certification.module';
import { DetailSparePartsComponent } from './views/detail-spareparts/detail-spareparts.component';
import { DetailMaintenancesComponent } from './views/detail-maintenances/detail-maintenances.component';
import { DetailReviewStatusBusComponent } from './views/detail-review-status-bus/detail-review-status-bus.component';
import { DetailInspectionComponent } from './views/detail-inspection/detail-inspection.component';
import { ResumenInspectionComponent } from './views/resumen-inspection/resumen-inspection.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { SupportsModule } from './supports/supports.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavigationComponent,
    NotfoundComponent,
    DetailSparePartsComponent,
    DetailMaintenancesComponent,
    DetailReviewStatusBusComponent,
    DetailInspectionComponent,
    ResumenInspectionComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AdminModule,
    routing,
    BusesModule,
    MaintenanceModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgSelectModule,
    IconModule,
    IconSetModule.forRoot(),
    FormsModule,
    AuditModule,
    TechnicalReviewModule,
    NgxSpinnerModule,
    TabsModule.forRoot(),
    CertificationModule,
    DataTablesModule.forRoot(),
    SupportsModule,
    ReactiveFormsModule
  ],
  
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ 
    //UserService,
    IconSetService,
    appRoutingProviders, {
    provide: APP_BASE_HREF,
    useValue: '/'},
    UserGuard,
    NoIdentityGuard,
    TabsetConfig,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
