import { ChangeDetectorRef, Component, OnInit, DoCheck } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
//import { Bus } from 'src/app/models/bus';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';
import { ClaimsService } from 'src/app/services/claims.services';

@Component({
  selector: 'app-observacion-response',
  templateUrl: './observacion.component.html',
  styleUrls: ['./observacion.component.css'],
  
})
export class ObservacionResponseComponent implements OnInit, DoCheck {
  public token;
  public id;
  public detalle="";

  constructor(
    public modal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private _ClaimsService: ClaimsService,
    private _router: Router,
  ) {


  }

  ngOnInit() {
    this.token = this._UserService.getToken();
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }
  CloseModal() {
    this.modal.close('Cerrar');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  saveResponse() {
    

    Swal.fire({
      title: "Registrar Respuesta a Falla",
      icon: "info",
      width: "500px",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Continuar!",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        this.SpinnerService.show();
        let request = {
          "id": this.id,
          "detalle": this.detalle
        }

        this._ClaimsService.updateRequest(this.token, request)
        .subscribe({
          next:(res) => {
            if (res.status == 'success') {
              Swal.fire("Respuesta registrada Con Exito!", "", "success");
              this.modal.close('Yes');
            } else {            
              Swal.fire({
                icon: "error",
                title: "Ha ocurrido el siguiente error:",
                text: res.message,
              });         
            }
        },
        error:(error)=>{
          this.SpinnerService.hide();
          if (error.status != 401) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"});
              this._router.navigate(['logout/1']);
          }
        },
        complete:()=>{
          this.SpinnerService.hide();

        }
      });
      }
    });
   

  }



}



