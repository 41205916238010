import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs'
import { MaterialModule } from 'src/material.module';
import { SupportsRoutingModule } from './supports-routing.module';
import { SupportsComponent } from './supports.component';
import { UserGuard } from '../services/user.guard';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';
import { DeliveryComponent } from './views/delivery/delivery.component';
import { RequestComponent } from './views/request/request.component';
import { DataTablesModule } from 'angular-datatables';
import { AddRequestComponent } from './views/request/modal/add-request/add-request.component';
import { ImportRequestComponent } from './views/request/modal/import-request/import-request.component';
import { SparesPartsService } from '../services/sparesparts.services';
import { AcceptRequestComponent } from './views/delivery/modal/accept-request/accept-request.component';
import { ClaimsComponent } from './views/claims/claims.component';
import { ClaimsService } from '../services/claims.services';
import { AddClaimComponent } from './views/claims/modal/add-request/add-claim.component';
import { ResponseClaimComponent } from './views/response-claim/response-claim.component';
import { ObservacionResponseComponent } from './views/response-claim/modal/observacion/observacion.component';

@NgModule({
  declarations: [
    SupportsComponent,
    DeliveryComponent,
    RequestComponent,
    AddRequestComponent,
    ImportRequestComponent,
    AcceptRequestComponent,
    ClaimsComponent,
    AddClaimComponent,
    ResponseClaimComponent,
    ObservacionResponseComponent
  ],
  imports: [
    SupportsRoutingModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxSpinnerModule,
    NgSelectModule,
    TabsModule.forRoot(),
    DataTablesModule.forRoot()
  ],
  exports: [
    SupportsComponent,
  ],
  providers: [
   // UserService,
    UserGuard,
    SparesPartsService,
    ClaimsService
  ],
    bootstrap: [SupportsComponent, DeliveryComponent, RequestComponent, AddRequestComponent, ImportRequestComponent,
       AcceptRequestComponent, ResponseClaimComponent, ClaimsComponent, AddClaimComponent, ObservacionResponseComponent],
})
export class SupportsModule { }