import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.services';
// import { ChartOptions,  ActiveElement,
//   Chart,
//   ChartConfiguration,
//   ChartData,
//   ChartEvent,
//   ChartType, } from 'chart.js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  public token;
  title = 'ng2-charts-demo';


//   public chartType: ChartType = 'pie';

//   public chartOptions: ChartConfiguration['options'] = {
//     responsive: true,
//     indexAxis: 'y',
//     onHover: (
//       event: ChartEvent,
//       elements: ActiveElement[],
//     ) => {
//     },
//     onClick: (
//       event: ChartEvent,
//       elements: ActiveElement[],
//     ) => {
//       if (elements[0]) {
//       }
//     },
//   };

//   public chartData: ChartData<'pie'> = {
//     labels: ['2012', '2011', '2010', '2009', '2008', '2007', '2006'],
//     datasets: [{ data: [65, 59, 80, 81, 56, 55, 40], label: 'Amount' }],
//   };

//   // Pie
//   public pieChartOptions: ChartOptions<'pie'> = {
//     responsive: false,
//     onClick: (
//       event: ChartEvent,
//       elements: ActiveElement[],
//     ) => {
//       if (elements[0]) {
//     }
//   };
//   public pieChartLabels = [  'Activos' , 'Bajas'  ];
//   public pieChartDatasets = [ {
//     data: [ 97, 2 ],
//     label: ['LAS TORRES'],
//     backgroundColor: ['blue', 'red'],
//     borderColor: 'black',
//     borderWidth: 1
//   },
//   {
//     data: [ 75, 1 ],
//     label: 'SANTA CLARA',
//     backgroundColor: ['#033a6a', 'red'],
//     borderColor: 'white',
//     borderWidth: 1
//   }
//  ];
//  public pieChartLabels2 = [  'APROBADO' , 'NO APROBADO', 'PENDIENTE'];
//   public pieChartDatasets2 = [ {
//     data: [ 55, 1, 28 ],
//     label: 'CERTIFICACION',
//     backgroundColor: ['green', 'red', 'orange'],
//     borderColor: 'rgb(54, 16, 2, 235)',
//     borderWidth: 1
//   },
  

// ];
//   public pieChartLegend = true;
//   public pieChartPlugins = [];
  constructor(private _UserService:UserService) {
    this.token= this._UserService.getToken();
   }
  ngOnInit() {

  }

}
