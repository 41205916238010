import { Component, OnInit, DoCheck } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';
import { AuditsService } from 'src/app/services/audits.services';

@Component({
  selector: 'app-detail-review-status-bus',
  templateUrl: './detail-review-status-bus.component.html',
  styleUrls: ['./detail-review-status-bus.component.css'],
})
export class DetailReviewStatusBusComponent implements OnInit, DoCheck {
  public ppu:string;
  public id_inspeccion;
  public components:any[]=[];
  public token;
  public resultado;
  
  constructor(
    public modal: NgbActiveModal,
    private _UserService: UserService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService,
    private _AuditsService: AuditsService
  ) {

  }

  ngOnInit(): void {
    this.token = this._UserService.getToken();
    this.getDetailReviewStatusBus();
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  getDetailReviewStatusBus() {
    this.SpinnerService.show();
    this._AuditsService.getDetalleRevisionEstadoGeneralBus(this.token, this.id_inspeccion)
    .subscribe({
        next:(resp)=>{
            this.components = resp.data;
        },
        error:(error)=>{
            this.SpinnerService.hide();
            if (error.status != 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  width: 400,
                  text: error.error.message,
                  footer: '<a href="">¿Por qué tengo este problema?</a>'
                });
              } else if (error.status == 401) {
                Swal.fire({
                  icon: "warning",
                  title: "Sesión Expirada",
                  text: 'Su sesión ha finalizado'
                });
                this._router.navigate(['logout/1']);
              }
        },
        complete:()=>{
            this.SpinnerService.hide();
        }
    })
  }

}