import { AfterViewInit, Component, OnInit, DoCheck, ViewChild, OnDestroy } from '@angular/core';
import { BusComponent } from './modal/register-bus/register-bus.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Bus } from 'src/app/models/bus';
import Swal from 'sweetalert2';
import { BusService } from 'src/app/services/bus.services';
import $ from 'jquery';
import * as bootstrap from "bootstrap";
import { DeleteBusComponent } from './modal/delete-bus/deletebus.component';
import { UserService } from 'src/app/services/user.services';
import { TableUtil } from 'src/app/services/excel-export';
import * as XLSX from "xlsx";
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { Subject } from 'rxjs';
import { dataTableSettingNotButtonsMax } from 'src/app/models/dictionary';


@Component({
  selector: 'app-admin-buses',
  templateUrl: './buses.component.html',
  styleUrls: ['./buses.component.css']
})

export class BusesAdminComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  _colorNormal: string = "#5c6873";
  _colorRed: string = "red";
  public buses: Bus[] = new Array<Bus>();
  public bus: Bus = new Bus();
 
  _motivo_baja: string;
  public token;
  files: File[];
  procesar: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  constructor(
    private modalService: NgbModal,
    private SpinnerService: NgxSpinnerService,
    private _busService: BusService,
    private _userService: UserService,
    private _router: Router,) { }

  ngOnInit() {
    this.token = this._userService.getToken();
    this.dtOptions = dataTableSettingNotButtonsMax;
    this.loadBuses();
  }

  ngAfterViewInit(): void {    
    this.dtTrigger.next(0);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngDoCheck(): void {
    this.token = this._userService.getToken();
  }

  loadBuses() {
    this.SpinnerService.show();
    this._busService.getBuses(this.token)
      .subscribe(
        response => {
          if (response.status = 'success') {
            this.buses = response.data as Bus[];
            if (this.isDtInitialized) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                this.SpinnerService.hide();
                dtInstance.destroy();
                this.dtTrigger.next(0);
              });
            } else {
              this.SpinnerService.hide();
              this.isDtInitialized = true;
              this.reDraw();
            }
          }
        },
        error => {
          if (error.status != 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"
            });
            this._router.navigate(['logout/1']);
          }
        }

      );
  }

  addBus(bus?: Bus) {
    const modalRef = this.modalService.open(BusComponent, {
      centered: true,
      size: "lg",
    });
    if (!bus)
      modalRef.componentInstance.name = "Registrar Bus";
    else
      modalRef.componentInstance.name = "Editar Bus";

    if (bus) modalRef.componentInstance.bus = bus;
    modalRef.result.then(
      (result) => {
        var res = result.split(";", 2);
        if (res[0] == "Yes") {
          this.loadBuses();
        }
      },
      (cancel) => {
      }
    );
  }

  DeleteBus(bus) {

    const modalRef = this.modalService.open(DeleteBusComponent, {
      centered: true,
      size: "md",
    });
    modalRef.componentInstance.name = "Delete Bus";
    modalRef.componentInstance.bus = bus;
    modalRef.result.then(
      (result) => {
        var res = result.split(";", 2);
        if (res[0] == "Yes") {
          this.loadBuses();
        }
      },
      (cancel) => {
      }
    );
  }

  uploadFile($event) {

    this.files = $event.target.files;
    this.procesar = true;
  }

  uploadAndProgress() {
    this.SpinnerService.show();
    if (!this.files) {
      this.SpinnerService.hide();
      //this.message = 'No se pudo importar el archivo';
      return false;
    }
    var formData = new FormData();
    Array.from(this.files).forEach(f => formData.append('file', f))

    this._busService.importBuses(this.token, formData)
      .subscribe(response => {
        this.loadBuses();
        this.SpinnerService.hide();
      },
        error => {
          if (error.status != 404) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"
            });
            this._router.navigate(['logout/1']);
          }
        }

      );
  }

  ExportToExcel() {
    TableUtil.exportArrayToExcel(this.buses, "ListadodeBuses");
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

}
