import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';
import { HTTP_OPTIONS } from './header.config';
import { Maintenance } from '../models/maintence';


@Injectable()
export class MaintenanceService{

	public url;


	constructor(
		public _http: HttpClient,
	){
		this.url = global.url;
	}

	getMantenimientos(token, ppu: string, datefrom:string, dateto:string,ot:string): Observable<any>  {
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/mantenimientos/get?ppu='+ ppu + "&datefrom="+ datefrom + "&dateto="+ dateto+ "&ot=" + ot,{headers: headers});
	}

	
	saveMantenimiento(token, maintenance: Maintenance): Observable<any>{
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
										//.set('Authorization', token);
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.post(this.url + '/mantenimiento/create', maintenance, {headers:headers});

	}

	uploadFile(token, formData:FormData): Observable<any>{
		let headers = new HttpHeaders()
		.set('Authorization', token);
		
		return this._http.post(this.url + '/mantenimientosTmp/import', formData, {headers:headers});
	}

	cancelProcess(token, request:any): Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.post(this.url + '/mantenimientosTmp/cancel', request, {headers:headers});
	}

	processImport(token, base:any): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		
		let json = JSON.stringify(base);
			let params = 'json='+json;
		return this._http.post(this.url + '/mantenimientosTmp/process', params, {headers:headers});
	}


	getRepuestos(token, ot:number):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/repuestos/get?ot='+ ot,{headers:headers});
	}

	exportMaintenances(token, periodo):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/mantenimientos/export?periodo=' + periodo ,{headers:headers});
	}


	UploadOTMaintenance(token, request):Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(request);
		let params = 'json='+json;
		return this._http.post(this.url + '/mantenimientos/file/upload' , params, {headers: headers})
		
	}

	getDetailMaintenance(token, ot, ppu):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/mantenimientos/detail?ot=' + ot + "&ppu=" + ppu,{headers:headers});
	}

	deleteMaintenance(token, ot, ppu):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);

		return this._http.delete(this.url + '/mantenimientos/delete?ot=' + ot + "&ppu=" + ppu, {headers:headers});
	}

/*
	updateBus(bus: Bus): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json')
										//.set('Authorization', token);
		
		return this._http.post(this.url + '/bus/update', bus, HTTP_OPTIONS);

	}

	

	*/
}