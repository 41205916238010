import { AfterViewInit, OnDestroy, Component, OnInit, DoCheck, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.services';
import { AuditsService } from '../../../services/audits.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { dataTableSettingEstadoGeneralBus } from 'src/app/models/dictionary';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PautaEGBAddComponent } from './modal/pauta-egb-add/pauta-egb-add.component';
import Swal from 'sweetalert2';
import { DetailReviewStatusBusComponent } from 'src/app/views/detail-review-status-bus/detail-review-status-bus.component';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-estado-general-bus',
  templateUrl: './estado-general-bus.component.html',
  styleUrls: ['./estado-general-bus.component.css'],
})
export class EstadoGeneralBusComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  public datefrom:string="";
  public dateto:string="";
  public ppu:string="";
  public inspections:any[];
  public token;

  public cumple:number=0;
  public nocumple:number=0;
  public noaplica:number=0;
  public c_alta:number =0;
  public c_media:number =0;
  public c_baja:number =0;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  public base64;
  constructor(private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _auditsService: AuditsService,
    private modalService: NgbModal, 
    private SpinnerService: NgxSpinnerService){
    }

  ngOnInit() {
    this.token = this._userService.getToken();
    this.dtOptions = dataTableSettingEstadoGeneralBus;
    var hoy = new Date();

    this.datefrom =  formatDate(hoy, 'yyyy-MM', 'en')
    this.dateto = formatDate(hoy, 'yyyy-MM', 'en')
  }

  ngDoCheck(): void {
    this.token = this._userService.getToken();
  }

  checkCheckBox($event){
    if ($event.target.checked)
      this.cumple += 1;
  }

  ngAfterViewInit(): void {      
    this.dtTrigger.next(0);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getEstadoGeneralBus(){
    this.SpinnerService.show();
    

    this._auditsService.getEstadoGeneralBus(this.token, this.ppu, this.datefrom, this.dateto)
    .subscribe({
      next: (resp) => {
        this.inspections = resp.data;
        this.reDraw();
      },
      error: (error) => {
        this.SpinnerService.hide();
      },
      complete: ()=> {
        this.SpinnerService.hide();
      }

    })
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

  comprobar(criticidad, check){

    if (criticidad='Alta'){
      if (check){
        this.c_alta = this.c_alta+1;
      }else
        this.c_alta = this.c_alta-1;
    }else if (criticidad = 'Media'){
      if (check){
        this.c_media = this.c_media+1;
      }else
        this.c_media = this.c_media-1;
    }else{
      if (check){
        this.c_baja = this.c_media+1;
      }else
        this.c_baja = this.c_baja-1;
    }
  }

  unselect($event){
    //if (  )
    var check = document.querySelectorAll("input[name=no_aplica1]")
    $("#no_aplica1").removeAttr("checked");
  }

  addPautaEGB(){
    const modalRef = this.modalService.open(PautaEGBAddComponent, {
      centered:true,
      size:'xl'

    });
    modalRef.componentInstance.name = "Registrar Revisión Estado General del Bus";
    modalRef.result.then(
      (result) => {
        if (result == "Yes") {
            this.getEstadoGeneralBus();
        }

      },
      (cancel) => {
      }
    );
  }

  deleteInspeccion(id){
    Swal.fire({
      title: "Eliminar Revisión EGB?",
      text: "¿Estas seguro(a) de que desea eliminar?",
      icon: "warning",
      width: "500px",
      heightAuto: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._auditsService.DeleteEstadoGeneralBus(this.token, id)
        .subscribe({
          next:(res) => {

          if (res.status == 'success') {
            Swal.fire("Revisión EGB Eliminada", "", "success");
            this.getEstadoGeneralBus();
          } else {            
            Swal.fire({
              icon: "error",
              title: "Ha ocurrido el siguiente error:",
              text: res.message,
            });         
          }
        },
        error:(error)=>{
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido el siguiente error:",
            text: error});
        },
        complete:()=>{

        }
      });
      }
    });
  }

  DetalleRevisionEstadoGeneralBus(inspection) {
    const modalRef = this.modalService.open(DetailReviewStatusBusComponent, {
      centered: true,
      size: "xl",
    });
    modalRef.componentInstance.id_inspeccion = inspection.id_inspeccion;
    modalRef.componentInstance.ppu = inspection.ppu;
    modalRef.componentInstance.resultado = inspection.resultado;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  downloadPdf(base64String, fileName) {
    var prefijo_file;
    prefijo_file='Pauta_EstadoGeneralBus_';

    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${prefijo_file}${fileName}.pdf`
    link.click();
  }

  onClickDownloadPdf(base64, inspection){
    this.downloadPdf(base64, inspection.ppu + '_' + inspection.id_inspeccion);
  }

  uploadFile($event, id) {
    this.SpinnerService.show();
    var file = $event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
        this.base64 = reader.result;
      
        var request= {
          "id_inspeccion": id,
          "evidencia": this.base64
        }
        this._auditsService.updatePautaEstadoGeneralBus(this.token, request)
        .subscribe({
          next:(resp)=>{
          },
          error:(error)=>{
            this.SpinnerService.hide();
          },
          complete:()=>{
            this.SpinnerService.hide();
          }
        })
      };
  }
}