import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../services/user.guard';
import { DocumentalComponent } from './views/documental/documental.component';
import { InspeccionComponent } from './views/inspeccion/inspeccion.component';
import { RevisionComponent } from './views/revision/revision.component';
export const routes: Routes = [
  {
        path: 'certification',
        canActivate: [UserGuard],
        children:[
      { path: 'documental-audit', component: DocumentalComponent },
      { path: 'technical-inspection', component: InspeccionComponent },
      { path: 'review-status', component: RevisionComponent },
      { path: '', redirectTo: 'documental-audit', pathMatch: 'full' },
      { path: '**', redirectTo: '404' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports : [ RouterModule ]
})
export class CertificationRoutingModule { }
