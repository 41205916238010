import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
//import { UserService } from './user.service';

@Injectable()
export class UserGuard implements CanActivate {

	public ruta;
	constructor(
		private _router: Router,
		//private _userService: UserService
		){}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){

		let identity = localStorage.getItem('identity');
		if (identity){
			return true;
		}else{
			this._router.navigate(['login'], { queryParams: {ruta: state.url}});
			return false;
		}		
	}
}