// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-select.custom {
    border:0px;
    min-height: 0px;
    border-radius: 0;
    width: 250px;
}
.ng-select.custom .ng-select-container  {            
    min-height: 0px;
    border-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/maintenance/views/register/register.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".ng-select.custom {\r\n    border:0px;\r\n    min-height: 0px;\r\n    border-radius: 0;\r\n    width: 250px;\r\n}\r\n.ng-select.custom .ng-select-container  {            \r\n    min-height: 0px;\r\n    border-radius: 0;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
