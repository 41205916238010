import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';
import { Bus } from '../models/bus';
import { HTTP_OPTIONS } from './header.config';
import { Plan } from '../models/plan';


@Injectable()
export class PlanService{

	public url;


	constructor(
		public _http: HttpClient,
	){
		this.url = global.url;
	}

    getPlanes(token, codigo): Observable<any>{
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
										//.set('Authorization', token);
		let headers = new HttpHeaders().set('Authorization', token);
		if (codigo!=null) codigo='?codigo_plan='+codigo;
		else codigo=""
		return this._http.get(this.url + '/admin/planes/get'+codigo, {headers: headers});

	}
	savePlan(token, plan: Plan): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(plan);
		let params = 'json='+json;

		return this._http.post(this.url + '/admin/plan/create', params, {headers: headers});

	}

	updatePlan(token, plan: Plan): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(plan);
		let params = 'json='+json;
		
		return this._http.post(this.url + '/admin/plan/update', params, {headers: headers});

	}

 	deletePlan(token, plan :Plan):Observable<any>{

		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
					.set('Authorization', token);
		let json = JSON.stringify(plan);
		let params = 'json='+json;
 		return this._http.post(this.url + '/admin/plan/delete', params, {headers: headers});

 	}

	filterPlanes(token, codigo_plan:string, categoria: string, modelo: string){
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/admin/planes/filter?codigo_plan='+codigo_plan +'&categoria='+categoria+'&modelo='+modelo , {headers:headers});
	}

	importPlanes(token, formData:FormData): Observable<any>{
		let headers = new HttpHeaders()
		.set('Authorization', token);
		
		return this._http.post(this.url + '/admin/planes/import', formData, {headers:headers});
	}
}