import { Component, OnInit, DoCheck, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';

import { DataTableDirective } from "angular-datatables";
import { Subject } from 'rxjs';
import { dataTableSettingDetailMaintenances } from 'src/app/models/dictionary';
import { MaintenanceService } from 'src/app/services/maintenance.services';


@Component({
  selector: 'app-detail-maintenances',
  templateUrl: './detail-maintenances.component.html',
  styleUrls: ['./detail-maintenances.component.css'],
})
export class DetailMaintenancesComponent implements OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public ot: string;
  public ppu:string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isDtInitialized: boolean = false;
  public token;
  public mantenimientos: any[] = new Array<any>();
  constructor(
    private _MaintenanceService: MaintenanceService,
    public modal: NgbActiveModal,
    
    private _UserService: UserService,
    private _router: Router,
    private SpinnerService: NgxSpinnerService
  ) {

  }

  ngOnInit(): void {
    this.token = this._UserService.getToken();
    this.dtOptions = dataTableSettingDetailMaintenances;
    this.getMantenimientosOT();
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  getMantenimientosOT() {
    this.SpinnerService.show();
    this._MaintenanceService.getDetailMaintenance(this.token, this.ot, this.ppu)
      .subscribe(
        response => {
          if (response.status=='success'){
            if (response.data){
              this.mantenimientos = response.data;
              if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                  this.SpinnerService.hide();
                  dtInstance.destroy();
                  this.dtTrigger.next(0);
                });
              } else {
                this.SpinnerService.hide();
                this.isDtInitialized = true;
                this.dtTrigger.next(0);
              }
            }else{
              this.SpinnerService.hide();
              this.dtTrigger.next(0);
            }
          }
          // this.SpinnerService.hide();
          // this.mantenimientos = response.data.details;
          // this.setPage(1);

        },
        error => {
          this.SpinnerService.hide();
              if (error.status != 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  width: 400,
                  text: error.error.message,
                  footer: '<a href="">¿Por qué tengo este problema?</a>'
                });
              } else if (error.status == 401) {
                Swal.fire({
                  icon: "warning",
                  title: "Sesión Expirada",
                  text: 'Su sesión ha finalizado'
                });
                this._router.navigate(['logout/1']);
              }
        }
      )
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}