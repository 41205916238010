import { ChangeDetectorRef,  Component, OnInit, DoCheck } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Plan } from 'src/app/models/plan';
import Swal from 'sweetalert2';
import { PlanService } from 'src/app/services/plan.services';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-plan',
  templateUrl: './register-plan.component.html',
  styleUrls: ['./register-plan.component.css'],
  providers: [PlanService, UserService]
})
export class PlanComponent implements OnInit, DoCheck {
  public name: string = 'Registrar Plan';
  edit:boolean = false;
  public plan:Plan = new Plan();
  addPlanForm: FormGroup;
  public token;
  constructor(
    public modal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private _planService:PlanService,
    private _UserService:UserService,
    private _router: Router,
    ) { 
    this.addPlanForm = this.formBuilder.group({
      codigo:new FormControl({value:"", disabled:true}, Validators.required),
      categoria:new FormControl("", Validators.required),
      descripcion:new FormControl("", Validators.required),
      marca:new FormControl("", Validators.required),
      modelo:new FormControl("", Validators.required),
      sistema:new FormControl("", Validators.required),
      subsistema:new FormControl("", Validators.required),
      inspeccion_tecnica: new FormControl(false, Validators.required)
      });

  }

  get form() {
    return this.addPlanForm.controls;
  }
  ngOnInit() {
    this.token = this._UserService.getToken();
    if (this.plan.codigo_plan) {
      this.edit = true;
      this.addPlanForm.controls['codigo'].setValue(this.plan.codigo_plan);
      this.addPlanForm.controls['categoria'].setValue(this.plan.categoria);
      this.addPlanForm.controls['descripcion'].setValue(this.plan.descripcion_plan);
      this.addPlanForm.controls['marca'].setValue(this.plan.marca_plan);
      this.addPlanForm.controls['modelo'].setValue(this.plan.modelo_plan);      
      this.addPlanForm.controls['sistema'].setValue(this.plan.sistema);
      this.addPlanForm.controls['subsistema'].setValue(this.plan.subsistema);
      this.addPlanForm.controls['inspeccion_tecnica'].setValue(this.plan.inspeccion_tecnica);
      this.addPlanForm.controls['codigo'].disable();
    }
    else
      this.addPlanForm.controls['codigo'].enable();
  }
  
  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }
  
  CloseModal() {
    this.modal.close('Cerrar');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  SavePlan(){
    let plan: Plan = new Plan();
    plan.codigo_plan = this.addPlanForm.get('codigo').value;
    plan.categoria = this.addPlanForm.get('categoria').value;
    plan.descripcion_plan = this.addPlanForm.get('descripcion').value;
    plan.marca_plan = this.addPlanForm.get('marca').value;
    plan.modelo_plan = this.addPlanForm.get('modelo').value;
    plan.sistema = this.addPlanForm.get('sistema').value;
    plan.subsistema = this.addPlanForm.get('subsistema').value;
    plan.inspeccion_tecnica = this.addPlanForm.get('inspeccion_tecnica').value;

    if (this.edit)
      this.updatePlan(plan);
    else 
      this.createPlan(plan);
    
  }

  createPlan(plan){
    
    this.SpinnerService.show();
    this._planService.savePlan(this.token, plan)
    .subscribe(
      response => {
        if (response.status='success' && response.code==200){
          Swal.fire("Plan Creado", "", "success");
          this.SpinnerService.hide();
          this.modal.close('Yes');
        }
        else if (response.status='error'){
            
            this.SpinnerService.hide();
        }

      },
      error => {
        if (error.error.code != 401) {
          this.SpinnerService.hide();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            width: 400,
            text: error.error.message,
            footer: '<a href="">¿Por qué tengo este problema?</a>'
          });
        } else if (error.error.code == 401) {
          this.SpinnerService.hide();
          Swal.fire({
            icon: "warning",
            title: "Sesión Expirada",
            text: 'Su sesión ha finalizado'
          });
          this._router.navigate(['logout/1']);
        }
      }
  
    )
  }

updatePlan(plan){
  
  this.SpinnerService.show();
  this._planService.updatePlan(this.token,plan)
  .subscribe(
    response => {
      if (response.status='success' && response.code==200){
        this.SpinnerService.hide();
        Swal.fire("Plan Actualizado", "", "success");        
        this.modal.close('Yes');
      }
    },
    error => {
      if (error.error.code = 404) {
        this.SpinnerService.hide();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          width: 400,
          text: error.error.message,
          footer: '<a href="">¿Por qué tengo este problema?</a>'
        });
      } else if (error.error.code = 400) {
        this.SpinnerService.hide();
        Swal.fire({
          icon: "warning",
          title: "Sesión Expirada",
          text: 'Su sesión ha finalizado'
        });
        this._router.navigate(['logout/1']);
      }
    }

  )
}
}



