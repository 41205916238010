import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';
import { HTTP_OPTIONS } from './header.config';



@Injectable()
export class TechnicalReviewService{

	public url;


	constructor(
		public _http: HttpClient,
	){
		this.url = global.url;
	}

    getTechnicalReviews(token, ppu:string, datefrom, dateto, active?): Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/technical/review/get?ppu=' + ppu + '&datefrom=' + datefrom + '&dateto=' + dateto + "&active="+active , {headers:headers});

	}

	addTechnicalReview(token, review): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
			let json = JSON.stringify(review);
			let params = 'json='+json;
		return this._http.post(this.url + '/technical/review/create' , params, {headers: headers})
	}

	deleteTechnicalReview(token, id_revision): Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.delete(this.url + '/technical/review/delete?id_revision='+ id_revision, {headers: headers})
	}


}