import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { TechnicalReviewService } from 'src/app/services/technical-review.services';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { dataTableSettingNotButtonsMax } from 'src/app/models/dictionary';


@Component({
  selector: 'app-technical-review-get',
  templateUrl: './technical-review-get.component.html',
  styleUrls: ['./technical-review-get.component.css'],
})
export class TechnicalReviewGetComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  public token;
  public identity;
  public ppu: string = "";
  public periodo: string = "";
  public reviews:any[] = new Array<any>();
  constructor(
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private _TechnicalReviewService: TechnicalReviewService,
    private _router: Router,
    private modalService: NgbModal,
  ) {

  }

  ngOnInit() {
    this.token = this._UserService.getToken();
    this.identity = this._UserService.getIdentity();
    this.dtOptions = dataTableSettingNotButtonsMax;
    registerLocaleData(es);
  }
  ngAfterViewInit(): void {      
    this.dtTrigger.next(0);
  }

  getTechnicalReview() {
    this.SpinnerService.show();
      this._TechnicalReviewService.getTechnicalReviews(this.token, "", "", this.ppu)
        .subscribe({
          next:(response) => {
            this.reviews = response.data;
            if (this.isDtInitialized) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                this.SpinnerService.hide();
                dtInstance.destroy();
                this.dtTrigger.next(0);
              });
            } else {
              this.SpinnerService.hide();
              this.isDtInitialized = true;
              this.reDraw();
            }
            
          },
          error:(error)=> {
            if (error.status != 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado',
                showCancelButton: false,
                confirmButtonColor: "#4dbd74",
                confirmButtonText: "Continuar"});
                this._router.navigate(['logout/1']);
            }
          }
        });
  }

  limpiar() {
    this.periodo = "";
    this.ppu = "";
  }

  downloadPdf(review, fileName) {
    //const source = `data:application/pdf;base64,${base64String}`;
    /*
        */
    const source = review.evidencia;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }
  onClickDownloadPdf(review){
    if (review.evidencia != null)
      this.downloadPdf(review, 'revision_tecnica_' + this.ppu);
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}