import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable()
export class ClaimsService {

	public url;
	public headers: HttpHeaders;


	constructor(
		public _http: HttpClient,
	) {
		this.url = global.url;
		this.headers = new HttpHeaders();
	}

	getRequests(token, ot, ppu, datefrom, dateto, estado): Observable<any> {
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
		//.set('Authorization', token);
		return this._http.get(this.url + '/claims/requests/get?ot=' + ot + '&ppu=' + ppu + '&datefrom='+datefrom + '&dateto='+dateto + '&estado='+ estado, {headers: this.headers.set('Authorization', token)});

	}

	saveRequest(token, request): Observable<any> {
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
		//.set('Authorization', token);
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(request);
		let params = 'json='+json;

		return this._http.post(this.url + '/claims/requests/create', params, {headers: headers});

	}

	updateRequest(token, request): Observable<any> {
		//.set('Authorization', token);
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
		let json = JSON.stringify(request);
		let params = 'json='+json;
		return this._http.post(this.url + '/claims/requests/update', params,  {headers: headers});

	}

	deleteRequest(token, id): Observable<any> {
		//.set('Authorization', token);
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.delete(this.url + '/claims/requests/delete/'+id ,{headers:headers});
	}

	importRequest(token, formData:FormData): Observable<any>{
		return this._http.post(this.url + '/claims/requests/import', formData, {headers: this.headers.set('Authorization', token)});
	}
}
