import { AfterViewInit, Component, OnInit, DoCheck, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { CertificationService } from 'src/app/services/certification.services';
import { formatDate, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { UserService } from 'src/app/services/user.services';
import { MaintenanceService } from 'src/app/services/maintenance.services';
import { ObservacionComponent } from './modal/observacion/observacion.component';
import { Router } from '@angular/router';
import { TableUtil } from 'src/app/services/excel-export';
import { DetailMaintenancesComponent } from 'src/app/views/detail-maintenances/detail-maintenances.component';
import { DataTableDirective } from "angular-datatables";
import { Subject } from 'rxjs';
import { dataTableSettingAuditoryDocumental } from 'src/app/models/dictionary';
import { DetailSparePartsComponent } from 'src/app/views/detail-spareparts/detail-spareparts.component';
import { AuditsService } from 'src/app/services/audits.services';

@Component({
  selector: 'app-documental',
  templateUrl: './documental.component.html',
  styleUrls: ['./documental.component.css']
})
export class DocumentosComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  maintenances: any[] = new Array<any>();
  datefrom: string = "";
  dateto: string = "";
  ppu: string = "";
  public token;
  EnabledOptionsMasive:boolean=true;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  contar:number=0;
  public estado:string="";
  //public buses:any;
  constructor(
    private modalService: NgbModal,
    private _CertificationService: CertificationService,
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private _MaintenanceService: MaintenanceService,
    private _auditsService:AuditsService,
    private _router: Router
    ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.token = this._UserService.getToken();
    this.dtOptions = dataTableSettingAuditoryDocumental;
    var hoy = new Date();

    hoy.setMonth(hoy.getMonth() - 1);

    this.datefrom =  formatDate(hoy, 'yyyy-MM', 'en')
    this.dateto = formatDate(hoy, 'yyyy-MM', 'en')
  }

  ngAfterViewInit(): void {      
    this.dtTrigger.next(0);
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  getMantenciones() {
    this.SpinnerService.show();
    this._CertificationService.getAuditoriaDocumental(this._UserService.getToken(), this.datefrom, this.dateto, this.ppu, this.estado)
      .subscribe({
        next:(response) => {
          if (response.status="success"){
          this.maintenances = response.data;
            this.reDraw();
          }
          else{
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 404,
              text: response.error,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          }
        },
        error:(error)=> {
            if (error.status != 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado',
                showCancelButton: false,
                confirmButtonColor: "#4dbd74",
                confirmButtonText: "Continuar"});
                this._router.navigate(['logout/1']);
            }
          },
          complete:()=>{
            this.SpinnerService.hide();
          }
        });
}

  Accion(ppu, ot, estado)
  {
    const modalRef = this.modalService.open(ObservacionComponent, {
      centered: true,
      size: "md",
    });

      modalRef.componentInstance.name = "Observación";
      modalRef.componentInstance.ppu = ppu;
      modalRef.componentInstance.ot = ot;
      modalRef.componentInstance.estado = estado;

    modalRef.result.then(
      (result) => {
        var res = result.split(";", 2);
        if (res[0] == "Yes") {
          this.getMantenciones();
        }
      },
      (cancel) => {
      }
    );
  }

  AccionMasiva(ppu, ots:any[], estado){
    const modalRef = this.modalService.open(ObservacionComponent, {
      centered: true,
      size: "md",
    });

      modalRef.componentInstance.name = "Observación";
      modalRef.componentInstance.ppu = ppu;
      modalRef.componentInstance.ots = ots;
      modalRef.componentInstance.estado = estado;

    modalRef.result.then(
      (result) => {
        var res = result.split(";", 2);
        if (res[0] == "Yes") {
          this.getMantenciones();
          this.checkAllCheckBox(false);
          this.EnabledOptionsMasive = true;
        }
      },
      (cancel) => {
      }
    );
  }
  
  DetalleMantenimientos(ot: string, ppu:string) {
    const modalRef = this.modalService.open(DetailMaintenancesComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.ot = ot;
    modalRef.componentInstance.ppu = ppu;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
            ('yes');
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  DetalleRepuestos(ot: number) {
    const modalRef = this.modalService.open(DetailSparePartsComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.ot = ot;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  checkAllCheckBox(check) {
    // Angular 13
    let i=0;
    this.maintenances.forEach((x) => (x.selected  = check));
    if (check==true){
      this.EnabledOptionsMasive = false;
    }else{
      this.EnabledOptionsMasive = true;
    }
  }

  contarSeleccionados(ev:any){
    if (ev.target.checked==true ){
      this.contar=this.contar+1;
      this.EnabledOptionsMasive = false;
    }else{

      this.contar=this.contar-1;
      if (this.contar==0){
        this.EnabledOptionsMasive = true;
      }
    }
  }

  deleteDocumental(id){
    Swal.fire({
      title: "Eliminar Auditoria Documental?",
      text: "¿Estas seguro(a) de que desea eliminar?",
      icon: "warning",
      width: "500px",
      heightAuto: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._auditsService.DeleteAuditoriaDocumental(this.token, id)
        .subscribe({
          next:(res) => {

          if (res.status == 'success') {
            Swal.fire("Auditoria Documental Eliminada", "", "success");
            this.getMantenciones();
          } else {            
            Swal.fire({
              icon: "error",
              title: "Ha ocurrido el siguiente error:",
              text: res.message,
            });         
          }
        },
        error:(error)=>{
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido el siguiente error:",
            text: error});
        },
        complete:()=>{

        }
      });
      }
    });
  }

}
