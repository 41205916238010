import { Component, OnInit, DoCheck } from '@angular/core';
import { UserService } from 'src/app/services/user.services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CertificationService } from 'src/app/services/certification.services';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SparesPartsService } from 'src/app/services/sparesparts.services';

@Component({
    selector: 'app-supports-accept-request',
    templateUrl: './accept-request.component.html',
    styleUrls: ['./accept-request.component.css']
})
export class AcceptRequestComponent implements OnInit, DoCheck {
    public token;
    public id_repuesto;
    addAcceptForm: FormGroup;
    constructor(
        private SpinnerService: NgxSpinnerService,
        private _UserService: UserService,
        public modal: NgbActiveModal,
        private _formBuilder: FormBuilder,
        public _CertificationService:CertificationService,
        private _router: Router,
        private _SparesPartsService: SparesPartsService
     ) {
        this.addAcceptForm = this._formBuilder.group({
            datefrom: new FormControl("", Validators.required),
          });

        
    }
    ngOnInit() {
        this.token = this._UserService.getToken();
    }

    ngDoCheck(): void {
        this.token = this._UserService.getToken();
    }

    CloseModal() {
        this.modal.close('');
    }

    get form() {
        return this.addAcceptForm.controls;
      }

    SaveRequest(){
        this.SpinnerService.show();
        var request = {
            "id": this.id_repuesto,
            "datefrom": this.addAcceptForm.get('datefrom').value
        };
        this._SparesPartsService.updateRequest(this.token, request)
        .subscribe({
            next:(resp)=>{

                if (resp.status=='success'){
                    Swal.fire("Entrega realizada con Exito!", "", "success")
                    this.modal.close('Yes');
                }

            },
            error:(error)=>{
                this.SpinnerService.hide();
                if (error.status != 401) {
                    
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      width: 400,
                      text: error.error.message,
                      footer: '<a href="">¿Por qué tengo este problema?</a>'
                    });
                  } else if (error.status == 401) {
                    Swal.fire({
                      icon: "warning",
                      title: "Sesión Expirada",
                      text: 'Su sesión ha finalizado',
                      showCancelButton: false,
                      confirmButtonColor: "#4dbd74",
                      confirmButtonText: "Continuar"});
                      this._router.navigate(['logout/1']);
                  }
            },
            complete:()=>{
                this.SpinnerService.hide();
            }
            
        })
    }

}