import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.services';
import Chart, { DoughnutController } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { callback } from 'chart.js/dist/helpers/helpers.core';
// import { ChartOptions,  ActiveElement,
//   Chart,
//   ChartConfiguration,
//   ChartData,
//   ChartEvent,
//   ChartType, } from 'chart.js';

var total_buses:number = 172;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls:['./dashboard.component.css']
})



export class DashboardComponent implements OnInit {
  public token;
  title = 'ng-chart';
  
  chart: any = [];
  chart2: any = [];
  chart3: any = [];
  chart4: any = [];
  doughnutLegend = true;

  chartOptionsValue = {
    tooltips: {
      enabled: true,
      mode: 'single',
      callback: {
        label: function (tooltipItem, data){
          let label = data.label[tooltipItem.index];
          let datasetLabel = data.datasets[tooltipItem.datasetsIndex].data[tooltipItem.index];
          return label + ': ' + datasetLabel + '%'
        }
      }
    }
  }

  doughnutChartOptions = {
    responsive: false,
    plugins: {
        datalabels: {
            color: 'white',
            formatter: function (value, context) {
                return context.chart.data.labels[
                    context.dataIndex
                ];
            },
        },
        title: {
            display: true,
            text: "Reported Fault Allocation",
            color: "#D6001C",
            font: {
                family: "AvenirNextLTW01-Regular",
                size: 16,
                style: 'normal'
            }
        },
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            grid: {
                display: false,
                drawBorder: true
            }
        },
        y: {
            grid: {
                display: true,
                drawBorder: true,
            },
        },
    },
    elements: {
        point: {
            radius: 0
        }
    },
}
  doughnutLabel = {
    id: 'doughnutLabel',
    beforeDatasetsDraw(chart, args, pluginOptions){
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.font = 'bold 30px';
      ctx.fillStyle =  'rgb(255,0,0)';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(data.labels[0], xCoor, yCoor)

    }

  }

  constructor() {}

  ngOnInit() {
    total_buses = 172;
    this.chart = new Chart('canvas', {
      type: 'pie',
      data: {
        labels: ['Aprobados', 'Reprobados'],
        datasets: [
          {
            label: '# de Buses',
            data: [170,0],
            borderWidth: 1,
            backgroundColor: [
              'rgb(34, 177, 76)',
              'rgb(255,0,0)',
            ],
            hoverOffset: 4,

          },
        ],
      },
      options:{ 
        responsive: false,
        plugins:{         
          datalabels: {
            color: 'white',
            font: {
              size: 13,
              weight: 'bold',
            },
            formatter: function(value, ctx) {
              let sum = total_buses;
                // let dataArr = ctx.chart.data.datasets[0].data;
                // dataArr.map((data) => {
                //   sum += +data;
                // });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage=='0.00%'?'':percentage;
            }
          }
        }
      },
        
      plugins: [ChartDataLabels],
    });

    this.chart2 = new Chart('canvas2', {
      type: 'pie',
      data: {
        labels: ['Aprobado', 'Reprobado'],
        datasets: [
          {
            label: '# de Buses',
            data: [71, 1],
            borderWidth: 1,
            backgroundColor: [
              'rgb(34, 177, 76)',
              'rgb(255,0,0)',
              '#FD7E14'
            ],
            hoverOffset: 4
          },
        ],
      },
      options:{ 
        responsive: false,
        plugins:{         
          datalabels: {
            color: 'white',
            font: {
              size: 13,
              weight: 'bold',
            },
            formatter: function(value, ctx) {
              let sum = total_buses;
                // let dataArr = ctx.chart.data.datasets[0].data;
                // dataArr.map((data) => {
                //   sum += +data;
                // });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage=='0.00%'?'':percentage;
            }
          }
        }
      },
    });

    this.chart3 = new Chart('canvas3', {
      type: 'pie',
      data: {
        
        labels: ['Aprobado', 'Reprobado'],
        datasets: [
          {
            label: '# de Buses',
            data: [142, 0],
            borderWidth: 1,
            backgroundColor: [
              'rgb(34, 177, 76)',
              'rgb(255,0,0)',
              '#FD7E14'
            ],
            hoverOffset: 4
          },
        ],
      },
      options:{ 
        responsive: false,
        plugins:{         
          datalabels: {
            color: 'white',
            font: {
              size: 13,
              weight: 'bold',
            },
            formatter: function(value, ctx) {
              let sum = total_buses;
                // let dataArr = ctx.chart.data.datasets[0].data;
                // dataArr.map((data) => {
                //   sum += +data;
                // });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage=='0.00%'?'':percentage;
            }
          }
        }
      },
    });

    this.chart4 = new Chart('canvas4', {
      type: 'pie',
      data: {
        labels: ['Aprobado', 'Reprobado'],
        datasets: [
          {
            label: '% de Buses',
            data: [71, 1],
            borderWidth: 1,
            backgroundColor: [
              'rgb(34, 177, 76)',
              'rgb(255,0,0)',
              '#FD7E14'
            ],
            hoverOffset: 4
          },
        ],
        
      },
      options:{ 
        responsive: false,
        plugins:{         
          datalabels: {
            color: 'white',
            font: {
              size: 13,
              weight: 'bold',
            },
            formatter: function(value, ctx) {
              let sum = total_buses;
                // let dataArr = ctx.chart.data.datasets[0].data;
                // dataArr.map((data) => {
                //   sum += +data;
                // });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage=='0.00%'?'':percentage;
            }
          }
        }
      },
      plugins: [ChartDataLabels],
      
    });
  }


//   public chartType: ChartType = 'pie';

//   public chartOptions: ChartConfiguration['options'] = {
//     responsive: true,
//     indexAxis: 'y',
//     onHover: (
//       event: ChartEvent,
//       elements: ActiveElement[],
//     ) => {
//     },
//     onClick: (
//       event: ChartEvent,
//       elements: ActiveElement[],
//     ) => {
//       if (elements[0]) {
//       }
//     },
//   };

//   public chartData: ChartData<'pie'> = {
//     labels: ['2012', '2011', '2010', '2009', '2008', '2007', '2006'],
//     datasets: [{ data: [65, 59, 80, 81, 56, 55, 40], label: 'Amount' }],
//   };

//   // Pie
//   public pieChartOptions: ChartOptions<'pie'> = {
//     responsive: false,
//     onClick: (
//       event: ChartEvent,
//       elements: ActiveElement[],
//     ) => {
//       if (elements[0]) {
//       }
//     }
//   };
//   public pieChartLabels = [  'Activos' , 'Bajas'  ];
//   public pieChartDatasets = [ {
//     data: [ 97, 2 ],
//     label: ['LAS TORRES'],
//     backgroundColor: ['blue', 'red'],
//     borderColor: 'black',
//     borderWidth: 1
//   },
//   {
//     data: [ 75, 1 ],
//     label: 'SANTA CLARA',
//     backgroundColor: ['#033a6a', 'red'],
//     borderColor: 'white',
//     borderWidth: 1
//   }
//  ];
//  public pieChartLabels2 = [  'APROBADO' , 'NO APROBADO', 'PENDIENTE'];
//   public pieChartDatasets2 = [ {
//     data: [ 55, 1, 28 ],
//     label: 'CERTIFICACION',
//     backgroundColor: ['green', 'red', 'orange'],
//     borderColor: 'rgb(54, 16, 2, 235)',
//     borderWidth: 1
//   },
  

// ];
//   public pieChartLegend = true;
//   public pieChartPlugins = [];
  // constructor(private _UserService:UserService) {
  //   this.token= this._UserService.getToken();
  //  }

}
