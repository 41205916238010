import { AfterViewInit, OnDestroy, Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { TechnicalReviewService } from 'src/app/services/technical-review.services';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { formatDate, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { dataTableSettingTechnicalReviews } from 'src/app/models/dictionary';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TechnicalReviewAddComponent } from './modal/add-technical-review.component';

@Component({
  selector: 'app-technical-review-register',
  templateUrl: './technical-review-register.component.html',
  styleUrls: ['./technical-review-register.component.css'],
})
export class TechnicalReviewRegisterComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  public token;
  public identity;
  public ppu: string = "";
  public year: string = "0";
  public active: boolean = false;
  public reviews: any[] = [];
  public datefrom="";
  public dateto="";
  public vigencia=0;
  file: File;
  public base64;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  constructor(
    private modalService: NgbModal,
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private _TechnicalReviewService: TechnicalReviewService,
    private _router: Router,
  ) {

  }
  ngOnInit() {
    this.token = this._UserService.getToken();
    this.identity = this._UserService.getIdentity();
    registerLocaleData(es);
    this.dtOptions = dataTableSettingTechnicalReviews;
    this.datefrom =  formatDate(new Date(), 'yyyy-MM', 'en')
    this.dateto = formatDate(new Date(), 'yyyy-MM', 'en')
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(0);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);
    });
  }

  getTechnicalReviews(ppu: string, year: string, active: boolean) {
    this.SpinnerService.show();
      this._TechnicalReviewService.getTechnicalReviews(this.token, ppu, this.datefrom, this.dateto, this.vigencia)
        .subscribe({
          next: (response) => {
            if (response.status == 'success') {
              this.reviews = response.data;
              this.reDraw();
            }else{
              this.reviews = [];
              this.reDraw();
            }
          },
          error: (error) => {
            console.error(error)
            if (error.status != 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado'
              });
              this._router.navigate(['logout/1']);
            }
          },
          complete: () => {
            this.SpinnerService.hide();
          }
        });
  }

  uploadFile($event) {
    var file = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64 = reader.result;
    };
  }

  limpiar() {
    this.ppu = "";
    this.base64 = "";
    $("#file").val('');
  }

  downloadPdf(base64String, fileName) {
    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }
  onClickDownloadPdf(ppu, base64String, index) {
    this.downloadPdf(base64String, "Revision_Tecnica_" + ppu + "_" + formatDate(new Date(), 'yyyyMM', 'en'));
  }

  addTechnicalReview() {
    const modalRef = this.modalService.open(TechnicalReviewAddComponent, {
      centered: true,
      size: "md",
    });
    modalRef.componentInstance.name = "Registrar Revisión Técnica";

    modalRef.result.then(
      (result) => {
        var res = result.split(";", 2);
        if (res[0] == "Yes") {
          this.getTechnicalReviews(this.ppu, this.year, this.active);
        }
      },
      (cancel) => {
      }
    );
  }

  EliminarRevision(id_revision) {
    Swal.fire({
      title: "Eliminar Revisión Técnica?",
      text: "¿Estas seguro(a) de que desea la Revisión Técnica?",
      icon: "warning",
      width: "500px",
      heightAuto: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._TechnicalReviewService.deleteTechnicalReview(this.token, id_revision)
          .subscribe({

            next: (response) => {
              if (response.status == 'success') {
                this.SpinnerService.hide();
                Swal.fire("Revisión Técnica Eliminada", "", "success");
                this.getTechnicalReviews(this.ppu, this.year, this.active);
              } else {
                Swal.fire({
                  icon: 'info',
                  title: 'Oops...',
                  width: 400,
                  text: "Bus No Existe para registrar Revision Tecnica"
                });
                this.reDraw();
              }
            },
            error: (error) => {
              if (error.status != 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  width: 400,
                  text: error.error.message,
                  footer: '<a href="">¿Por qué tengo este problema?</a>'
                });
              } else if (error.status == 401) {
                Swal.fire({
                  icon: "warning",
                  title: "Sesión Expirada",
                  text: 'Su sesión ha finalizado'
                });
                this._router.navigate(['logout/1']);
              }
            },
            complete: () => {
              this.SpinnerService.hide();
            }

          });
      }
    });
  }
}
