import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { itemsUsersCertificador, itemsUsersOperador, itemsAdmin } from './../items';
import { UserService } from '../services/user.services';

@Component({
  selector: '[navigation]',
  templateUrl: './navigation.component.html',
  providers: [UserService]
})
export class NavigationComponent implements DoCheck, OnInit {
  items = itemsAdmin;
  active: any = {};
  appName = 'Gestión Flota SCANIA';
  routerLinkActive = false;
  identity: any;

  dropdownData = [
    {
      id: 1,
      name: 'Cerrar Sesión',
      url: '/logout/1'
    }];

  constructor(
   private router: Router, private _UserService: UserService
  ) {
    router.events.subscribe(e => {
      if (e instanceof RoutesRecognized) {
        // match the parent url
        const segmentPath = e.url === '/' ? ['/home'] : e.url.match(/^\/(\w+)/gm);
        this.active = this.items.find(item => {
          return item.url === segmentPath[0];
        }) || {};
      }
    });
  }

  ngOnInit() { }
  ngDoCheck() { this.CargarMenu(); }

  CargarMenu() {
    this.identity = this._UserService.getIdentity();
    this.items = null;
    if (this.identity && this.identity != 'undefined') {
      if (this.identity.perfil == 99) {
        this.items = itemsAdmin;
      }
      else{
        if (this.identity.tipo=='Operador')
          this.items = itemsUsersOperador;
        else
          this.items = itemsUsersCertificador;
      }
       
    } else {
      this.items = null;
      this.router.navigate(['logout/1']);
    }
  }

}
