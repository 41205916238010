import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../services/user.guard';
import { HojaVidaComponent } from './views/hoja-vida/hoja-vida.component';
import { ReportBusComponent } from './views/report-bus/report-bus.component';
import { EstadoCertificacionComponent } from './views/status-certification/status-certification.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
export const routes: Routes = [
  {
        path: 'buses',
        canActivate: [UserGuard],
        children:[
      { path: 'dashboard', component: DashboardComponent },    
      { path: 'hoja-vida', component: HojaVidaComponent },
      { path: 'report-bus', component: ReportBusComponent },
      { path: 'status-certification', component: EstadoCertificacionComponent },
      { path: '', redirectTo: 'hoja-vida', pathMatch: 'full' },
      { path: '**', redirectTo: '404' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports : [ RouterModule ]
})
export class BusesRoutingModule { }
