import { AfterViewInit, Component, DoCheck, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditsService } from '../../../services/audits.services';
import { dataTableSettingCertEstadoGeneralBus } from 'src/app/models/dictionary';
import Swal from 'sweetalert2';
import { InformeEGBComponent } from './modal/informe-egb.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DetailReviewStatusBusComponent } from 'src/app/views/detail-review-status-bus/detail-review-status-bus.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-certification-revision-egb',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.css'],
  providers: [UserService]
})
export class RevisionComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public token;
  public inspections:any[]=[];
  public datefrom:string="";
  public dateto:string="";
  public ppu="";
  public _disabled_button:boolean=false;
  constructor(
    private _UserService:UserService,
    private SpinnerService: NgxSpinnerService,
    private _auditsService: AuditsService,
    private modalService: NgbModal, 
    ) { }

  ngOnInit() {
    this.token=this._UserService.getToken();
    this.dtOptions = dataTableSettingCertEstadoGeneralBus;
    var hoy = new Date();

    this.datefrom =  formatDate(hoy, 'yyyy-MM', 'en')
    this.dateto = formatDate(hoy, 'yyyy-MM', 'en')
  }
  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }
  ngAfterViewInit(): void {      
    this.dtTrigger.next(0);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getEstadoGeneralBus(){
    
    this.SpinnerService.show();
    this._auditsService.getEstadoGeneralBus(this.token, this.ppu, this.datefrom, this.dateto)
    .subscribe({
      next: (resp) => {
        this.inspections = resp.data;

        // this.inspections.forEach(element =>{
        //   if (element.selected) this._disabled_button=true;
        // });

        this.reDraw();
      },
      error: (error) => {
        this.SpinnerService.hide();
      },
      complete: ()=> {
        this.SpinnerService.hide();
      }

    });
  }
  

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

  InformeAuditoria(ppu, inspection) {
    const modalRef = this.modalService.open(InformeEGBComponent, {
      centered: true,
      size: "md",
    });
    inspection.selected=true;
    modalRef.componentInstance.ppu = ppu;
    modalRef.componentInstance.inspections = this.inspections;

    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result == 'Yes') {
          this.ppu = "";
          this.inspections = [];
          this.reDraw();
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  downloadPdf(base64String, fileName) {
    var prefijo_file;
    prefijo_file='EstadoGeneralBus_';

    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${prefijo_file}${fileName}.pdf`
    link.click();
  }
  onClickDownloadPdf(base64, ppu, id){
    this.downloadPdf(base64, ppu + '_' + id);
  }

  DetalleRevisionEstadoGeneralBus(inspection) {
    const modalRef = this.modalService.open(DetailReviewStatusBusComponent, {
      centered: true,
      size: "xl",
    });
    
    modalRef.componentInstance.id_inspeccion = inspection.id_inspeccion;
    modalRef.componentInstance.ppu = inspection.ppu;
    modalRef.componentInstance.resultado = inspection.resultado;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  deleteReportReviewStatusBus(id_informe_egb){
    Swal.fire({
      title: "Eliminar Informe EGB?",
      text: "¿Estas seguro(a) de que desea eliminar?",
      icon: "warning",
      width: "500px",
      heightAuto: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.SpinnerService.show();
        this._auditsService.DeleteInformeEstadoGeneralBus(this.token, id_informe_egb)
        .subscribe({
          next:(res) => {

          if (res.status == 'success') {
            Swal.fire("Revisión EGB Eliminada", "", "success");
            this.getEstadoGeneralBus();
          } else {            
            Swal.fire({
              icon: "error",
              title: "Ha ocurrido el siguiente error:",
              text: res.message,
            });         
          }
        },
        error:(error)=>{
          this.SpinnerService.hide();
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido el siguiente error:",
            text: error});
        },
        complete:()=>{
          this.SpinnerService.hide();
        }
      });
      }
    });
  }

}
