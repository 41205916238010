import { ChangeDetectorRef, Component, OnInit, DoCheck } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Bus } from 'src/app/models/bus';
import Swal from 'sweetalert2';
import { BusService } from 'src/app/services/bus.services';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';
@Component({
  selector: 'app-delete-bus',
  templateUrl: './delete-bus.component.html',
  styleUrls: ['./delete-bus.component.css'],
  providers: [BusService, UserService]
})
export class DeleteBusComponent implements OnInit, DoCheck {
  public name: string = 'Dar de Baja Bus';
  public edit: boolean = false;
  public bus: any;
  public token;
  public motivo;
  constructor(
    public modal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private SpinnerService: NgxSpinnerService,
    private _BusService: BusService,
    private _UserService: UserService,
    private _router: Router,
  ) {


  }

  ngOnInit() {
    this.token = this._UserService.getToken();
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  CloseModal() {
    this.modal.close('Cerrar');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  EliminarBus() {
    this.SpinnerService.show();
    this.bus._motivo_baja = this.motivo;
    this._BusService.deleteBus(this.token, this.bus)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          Swal.fire("Bus Eliminado", "", "success");
          this.modal.close('Yes');
        },
        error => {
          if (error.status == 404) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"
            });
            this._router.navigate(['logout/1']);
          }
        }
      )
  }

}



