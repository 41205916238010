import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';
import { HTTP_OPTIONS } from './header.config';



@Injectable()
export class CertificationService{

	public url;


	constructor(
		public _http: HttpClient,
	){
		this.url = global.url;
	}

    getMantenimientosPeriodo(token, datefrom, dateto, ppu, estado?): Observable<any>{
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
										//.set('Authorization', token);
		let headers = new HttpHeaders()
				.set('Authorization', token);

		return this._http.get(this.url + '/mantenimientos/filterPeriod?datefrom='+ datefrom + '&dateto=' + dateto + '&ppu=' + ppu +"&status=" + estado, {headers:headers});

	}

	getAuditoriaDocumental(token, datefrom, dateto, ppu, estado?): Observable<any>{
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
										//.set('Authorization', token);
		let headers = new HttpHeaders()
				.set('Authorization', token);

		return this._http.get(this.url + '/audit/documental/get?datefrom='+ datefrom + '&dateto=' + dateto + '&ppu=' + ppu +"&status=" + estado, {headers:headers});

	}

	getMantenimientosFilesPeriodo(token, datefrom, ppu?, ot?): Observable<any>{
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
										//.set('Authorization', token);
		let headers = new HttpHeaders()
				.set('Authorization', token);
		return this._http.get(this.url + '/mantenimientos/filterFiles?datefrom='+ datefrom + '&ppu=' + ppu + '&ot=' + ot , {headers:headers});

	}

	saveDocumentaryAuditCertification(token, data:any): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
			let json = JSON.stringify(data);
			let params = 'json='+json;
		return this._http.post(this.url + '/audit/report/documental/create' , params, {headers: headers})
	}

	saveInspectionCertification(token, data:any): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
			let json = JSON.stringify(data);
			let params = 'json='+json;
		return this._http.post(this.url + '/audit/report/inspection/create' , params, {headers: headers})
	}

	saveStatusGeneralBusCertification(token, data:any): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', token);
			let json = JSON.stringify(data);
			let params = 'json='+json;
		return this._http.post(this.url + '/audit/review-status/report/create' , params, {headers: headers})
	}

	/*saveMantenimiento(maintenance: Maintenance): Observable<any>{
		//let headers = new HttpHeaders().set('Content-Type', 'application/json')
										//.set('Authorization', token);
		
		return this._http.post(this.url + '/mantenimiento/create', maintenance, HTTP_OPTIONS);

	}

	uploadFile(formData:FormData): Observable<any>{
		return this._http.post(this.url + '/mantenimientosTmp/import', formData);
	}

	cancelProcess(request:any): Observable<any>{
		return this._http.post(this.url + '/mantenimientosTmp/cancel', request, HTTP_OPTIONS);
	}

	processImport(request:any): Observable<any>{
		return this._http.post(this.url + '/mantenimientosTmp/process', request, HTTP_OPTIONS);
	}


	getRepuestos(token, id_mantenimiento:number):Observable<any>{
		let headers = new HttpHeaders().set('Authorization', token);
		return this._http.get(this.url + '/repuestos/get?id='+ id_mantenimiento,{headers:headers});
	}
/*
	updateBus(bus: Bus): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json')
										//.set('Authorization', token);
		
		return this._http.post(this.url + '/bus/update', bus, HTTP_OPTIONS);

	}

	deleteBus(bus :Bus):Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json')
		//.set('Authorization', token);

		return this._http.post(this.url + '/bus/delete', bus, HTTP_OPTIONS);
	}

	*/
}