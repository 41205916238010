import { AfterViewInit, OnDestroy, Component, OnInit, DoCheck, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { UserService } from 'src/app/services/user.services';
import { AuditsService } from '../../../services/audits.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { dataTableSettingInspectionTechnical, dataTableSettingInspectionTechnicalRevision } from 'src/app/models/dictionary';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InspeccionAddComponent } from './modal/inspeccion-add/inspeccion-add.component';
import { DetailInspectionComponent } from 'src/app/views/detail-inspection/detail-inspection.component';
import { ResumenInspectionComponent } from 'src/app/views/resumen-inspection/resumen-inspection.component';
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-inspection-technical',
  templateUrl: './inspeccion.component.html',
  styleUrls: ['./inspeccion.component.css'],
})
export class InspeccionComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;

  ppu:string="";
  datefrom:string="";
  dateto:string="";
  public auditories:any[];
  public inspections:any[];
  public token;

  public cumple:number=0;
  public nocumple:number=0;
  public noaplica:number=0;
  public c_alta:number =0;
  public c_media:number =0;
  public c_baja:number =0;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtTrigger2: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  constructor(
    private _userService: UserService,
    private _auditsService: AuditsService,
    private modalService: NgbModal, 
    private SpinnerService: NgxSpinnerService){
    }

  ngOnInit() {
    this.token = this._userService.getToken();
    this.dtOptions = dataTableSettingInspectionTechnical;
    var hoy = new Date();

    this.datefrom =  formatDate(hoy, 'yyyy-MM', 'en')
    this.dateto = formatDate(hoy, 'yyyy-MM', 'en')
  }

  ngDoCheck(): void {
    this.token = this._userService.getToken();
  }

  checkCheckBox($event){
    if ($event.target.checked)
      this.cumple += 1;
  }
  ngAfterViewInit(): void {      
   // this.dtTrigger.next(0);
    this.dtTrigger2.next(0);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger2.unsubscribe();
  }

  getAuditoriasDocumental(){
    this.SpinnerService.show();
    this._auditsService.getAuditoriasInspeccionTecnica(this.token, this.ppu, this.datefrom, this.dateto)
    .subscribe({
      next: (resp) => {
        this.auditories = resp.data;

        this.getInpecciones();
        this.rerender()
      },
      error: (error) => {
        this.SpinnerService.hide();
      },
      complete: ()=> {
        this.SpinnerService.hide();
      }

    })
  }

  getInpecciones(){
    this.SpinnerService.show();
    this._auditsService.getAuditInspeccionesTecnicas(this.token, this.ppu, this.datefrom, this.dateto)
    .subscribe({
      next: (resp) => {
        this.inspections = resp.data;
        this.rerender()
      },
      error: (error) => {
        this.SpinnerService.hide();
      },
      complete: ()=> {
        this.SpinnerService.hide();
      }

    })
  }

  reDraw(): void {
    this.dtElements[0].dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);
      this.dtTrigger2.next(0);
    });
  }

  comprobar(criticidad, check){

    if (criticidad='Alta'){
      if (check){
        this.c_alta = this.c_alta+1;
      }else
        this.c_alta = this.c_alta-1;
    }else if (criticidad = 'Media'){
      if (check){
        this.c_media = this.c_media+1;
      }else
        this.c_media = this.c_media-1;
    }else{
      if (check){
        this.c_baja = this.c_media+1;
      }else
        this.c_baja = this.c_baja-1;
    }
  }

  unselect($event){
    //if (  )
    var check = document.querySelectorAll("input[name=no_aplica1]")
    $("#no_aplica1").removeAttr("checked");
  }

  addPautaPreventiva(_inspection){
    const modalRef = this.modalService.open(InspeccionAddComponent, {
      centered:true,
      size:'xl'

    });
    modalRef.componentInstance.name = "Registrar Inspección Técnica";
    modalRef.componentInstance.inspeccion = _inspection;
    modalRef.componentInstance.periodo = this.datefrom;
    modalRef.componentInstance.categoria = 'PREVENTIVO';
    modalRef.result.then(
      (result) => {
        if (result == "Yes") {
          this.getAuditoriasDocumental();

        }

      },
      (cancel) => {
      }
    );
  }
  
  addPautaInspeccion(){
    const modalRef = this.modalService.open(InspeccionAddComponent, {
      centered:true,
      size:'xl'

    });
    modalRef.componentInstance.name = "Registro de Inspección";
    modalRef.result.then(
      (result) => {
        if (result == "Yes") {
          this.getInpecciones();

        }

      },
      (cancel) => {
      }
    );
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      if (dtElement.dtInstance)
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
    });
    setTimeout(() => {
      this.dtTrigger.next(0);
      this.dtTrigger2.next(0);
    });

  }

  DetalleInspeccion(id, resultado){
    const modalRef = this.modalService.open(DetailInspectionComponent, {
      centered:true,
      size:'xl'

    });
    modalRef.componentInstance.id_inspeccion = id;
    modalRef.componentInstance.resultado = resultado=='A'?'APROBADO':'NO APROBADO';
    modalRef.result.then(
      (result) => {
        if (result == "Yes") {
          console.info('Ok');
        }

      },
      (cancel) => {
      }
    );
  }

  ResumenInspeccion(id){
    const modalRef = this.modalService.open(ResumenInspectionComponent, {
      centered:true,
      size:'xl'

    });
    modalRef.componentInstance.id_inspeccion = id;
    
    modalRef.result.then(
      (result) => {
        if (result == "Yes") {
          console.info('Ok');
        }

      },
      (cancel) => {
      }
    );
  }

  DeleteInspeccion(id){
    Swal.fire({
      title: "Eliminar Inspección Técnica?",
      text: "¿Estas seguro(a) de que desea eliminar?",
      icon: "warning",
      width: "500px",
      heightAuto: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._auditsService.DeleteInspeccionTecnica(this.token, id)
        .subscribe({
          next:(res) => {

          if (res.status == 'success') {
            Swal.fire("Inspección Técnica Eliminada", "", "success");
            this.getInpecciones();
          } else {            
            Swal.fire({
              icon: "error",
              title: "Ha ocurrido el siguiente error:",
              text: res.message,
            });         
          }
        },
        error:(error)=>{
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido el siguiente error:",
            text: error});
        },
        complete:()=>{

        }
      });
      }
    });
  }
}
