import {  AfterViewInit, Component, OnInit, DoCheck, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Bus } from 'src/app/models/bus';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.services';

import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { dataTableSettingChexbox } from 'src/app/models/dictionary';
import { BusService } from 'src/app/services/bus.services';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-report-bus',
  templateUrl: './report-bus.component.html',
  styleUrls: ['./report-bus.component.css']
})
export class ReportBusComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public bus: Bus;
  reports: any[] = [];
  public ppu="";
  public token;
  public datefrom="";
  public dateto="";
  public ot="";
  public tipo="1";
  //public buses:any;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  constructor(
    private modalService: NgbModal,
    private _busService: BusService,
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.token = this._UserService.getToken();
    registerLocaleData(es);
    this.dtOptions = dataTableSettingChexbox();
    this.datefrom =  formatDate(new Date(), 'yyyy-MM', 'en')
    this.dateto = formatDate(new Date(), 'yyyy-MM', 'en')
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(0);
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }
  BuscarReports() {
    if (this.datefrom != '' || this.dateto != '') {
      this.SpinnerService.show();
      this._busService.getReportsCertification(this.token, this.ppu, this.datefrom, this.dateto, this.tipo)
        .subscribe({ 
          next:(response) => {

            if (response.status=='success'){
              this.reports = response.data;
                this.reDraw();
            }

          },
          error:(error)=> {
            console.error(error);
            this.SpinnerService.hide();
            if (error.status != 401) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado'
              });
              this._router.navigate(['logout/1']);
            }},
          complete: () => {
            console.info('complete');
            this.SpinnerService.hide();
          }
        });

    }else
    {
      Swal.fire({
        icon: 'info',
        title: 'Periodo Invalido',
        width: 400,
        text: "Debe seleccionar el periodo a buscar",
        footer: '<a href="">¿Por qué tengo este problema?</a>'
      });
    }
  }

  limpiar() {
    this.ppu = "";
    this.reports = [];
    this.datefrom="";
    this.dateto="";
    this.tipo='1';
    this.reDraw();
  }

  downloadPdf(base64String, fileName) {
    var prefijo_file;
    if (this.tipo=='1') prefijo_file='AuditoriaDocumental_';
    if (this.tipo=='2') prefijo_file='InspeccionTecnica_';
    if (this.tipo=='3') prefijo_file='EstadoGeneralBus_';

    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${prefijo_file}${fileName}.pdf`
    link.click();
  }
  onClickDownloadPdf(ppu, base64String){
    this.downloadPdf(base64String, ppu + "_" + this.datefrom);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  //checkAllCheckBox(ev) { // Angular 9
  checkAllCheckBox(ev: any) {
    // Angular 13
    this.reports.forEach((x) => (x.selected  = ev.target.checked));
  }
}
