import { ChangeDetectorRef, Component, OnInit, DoCheck } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
//import { Bus } from 'src/app/models/bus';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.services';
import { AuditsService } from 'src/app/services/audits.services';
import { Router } from '@angular/router';
import { Audit } from 'src/app/models/audit';

@Component({
  selector: 'app-observation',
  templateUrl: './observacion.component.html',
  styleUrls: ['./observacion.component.css'],
  
})
export class ObservacionComponent implements OnInit, DoCheck {
  public token;
  public observaciones=null;
  public estado="";
  public ppu="";
  public ot="";
  public ots;
  public fecha_inicio;
  public fecha_termino;

  constructor(
    public modal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private _AuditsService: AuditsService,
    private _router: Router,
  ) {


  }

  ngOnInit() {
    this.token = this._UserService.getToken();
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }
  CloseModal() {
    this.modal.close('Cerrar');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  saveAudit() {
    this.SpinnerService.show();
    let audit = new Audit();
    audit.ppu = this.ppu;
    audit.ot = this.ot;
    audit.ots = this.ots;
    audit.estado = this.estado;
    audit.observaciones = this.observaciones==""?null:this.observaciones;
    this._AuditsService.createAudit(this._UserService.getToken(), audit)
      .subscribe({
       next:(response) => {
          this.SpinnerService.hide();
          Swal.fire({
            icon: 'info',
            title: 'Auditoria Guardada',
            text: 'Auditoria almacenada correctamente'
          });
          this.modal.close('Yes');
        },
        error:(error) => {
          if (error.status != 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"
            });
            this.modal.close('No');
            this._router.navigate(['logout/1']);
          }
        }
  });

  }



}



