import { ChangeDetectorRef, Component, OnInit, DoCheck } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/models/user';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.services';
import { RutValidator } from 'src/app/models/dictionary';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css'],
  providers: [UserService]
})
export class UserComponent implements OnInit, DoCheck {
  public name: string = 'Registrar Usuario';
  public token;
  public user: User = new User();
  addUserForm: FormGroup;
  editUser: boolean = false;
  constructor(public modal: NgbActiveModal, private changeDetector: ChangeDetectorRef, private formBuilder: FormBuilder, private SpinnerService: NgxSpinnerService, private _userService: UserService,
    private _router: Router,) {
    this.addUserForm = this.formBuilder.group({
      rut: new FormControl("", [Validators.required, Validators.maxLength(10), RutValidator]),
      nombre: new FormControl("", [Validators.required, Validators.maxLength(50)]),
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
      passwordrepeat: new FormControl("", [Validators.required]),
      perfil: new FormControl("", Validators.required),
      tipo: new FormControl("", Validators.required)
    });

  }

  get form() {
    return this.addUserForm.controls;
  }
  ngOnInit() {
    this.token = this._userService.getToken();
    
    if (this.user.rut) {
      this.editUser = true;
      this.addUserForm.controls['rut'].setValue(this.user.rut);
      this.addUserForm.controls['nombre'].setValue(this.user.nombre);
      this.addUserForm.controls['password'].setValue(this.user.password);
      this.addUserForm.controls['passwordrepeat'].setValue(this.user.password);
      this.addUserForm.controls['email'].setValue(this.user.email);
      this.addUserForm.controls['perfil'].setValue(this.user.perfil);
      this.addUserForm.controls['tipo'].setValue(this.user.tipo);
      this.addUserForm.controls['rut'].disable();
    }else{
      this.addUserForm.controls['rut'].enable();
    }
  }

  ngDoCheck(): void {
    this.token = this._userService.getToken();
  }
  CloseModal() {
    this.modal.close('Cerrar');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  SaveUser() {
    this.SpinnerService.show();
    this.user.rut = this.addUserForm.get('rut').value;
    this.user.nombre = this.addUserForm.get('nombre').value;
    this.user.email = this.addUserForm.get('email').value;
    this.user.password = this.addUserForm.get('password').value;
    this.user.perfil = this.addUserForm.get('perfil').value;
    this.user.tipo = this.addUserForm.get('tipo').value;
    
    if (!this.editUser) {
      this._userService.createUser(this.token, this.user)
        .subscribe(
          response => {
            this.SpinnerService.hide();
            Swal.fire("Usuario Creado", "", "success");            
            this.modal.close('Yes');
          },
          error => {
            if (error.status == 404) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado',
                showCancelButton: false,
                confirmButtonColor: "#4dbd74",
                confirmButtonText: "Continuar"});
                this._router.navigate(['logout/1']);
            }
          }

        );
    }else{
      this._userService.editUser(this.user, this.token)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          Swal.fire("Usuario Actualizado", "", "success");
          
          this.modal.close('Yes');
        },
        error => {
            if (error.status == 404) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado',
                showCancelButton: false,
                confirmButtonColor: "#4dbd74",
                confirmButtonText: "Continuar"});
                this._router.navigate(['logout/1']);
            }
        }
      );
    }
  }
}



