import { AfterViewInit, Component, OnInit, DoCheck, OnDestroy, ViewChild } from '@angular/core';
import { Plan } from 'src/app/models/plan';
import { ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { PlanComponent } from './modal/register-plan/register-plan.component';
import { PlanService } from 'src/app/services/plan.services';
import { ApiResponse } from 'src/app/models/response';
import { UserService } from 'src/app/services/user.services';
import { TableUtil } from 'src/app/services/excel-export';
import * as XLSX from "xlsx";
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { Subject } from 'rxjs';
import { dataTableSettingPlans } from 'src/app/models/dictionary';


@Component({
  selector: 'app-admin-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css'],
})
export class PlansComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  public plans: Plan[] = new Array<Plan>();

  codigo_plan: string = "";
  categoria: string = "Todos";
  modelo: string = "";
  dataFilter: any[];
  files: File[];
  public token;
  public procesar: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  constructor(
    private modalService: NgbModal, 
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder, 
    private SpinnerService: NgxSpinnerService,
    private _planServices: PlanService, 
    private _UserService: UserService,
    private _router: Router,) { }

  ngOnInit() {
    this.token = this._UserService.getToken();
    this.dtOptions = dataTableSettingPlans;
    this.loadPlanes();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(0);
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  limpiar() {

  }

  loadPlanes() {
    this.SpinnerService.show();
    this._planServices.getPlanes(this.token, null)
      .subscribe(
        response => {
          
          this.plans = (response.data) as Plan[];
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              this.SpinnerService.hide();
              dtInstance.destroy();
              this.dtTrigger.next(0);
            });
          } else {
            this.SpinnerService.hide();
            this.isDtInitialized = true;
            this.reDraw();
          }
        },
        error => {
          if (error.status != 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"
            });
            this._router.navigate(['logout/1']);
          }
        }

      );
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

  filterPlans() {
    this.SpinnerService.show();
    this._planServices.filterPlanes(this.token, this.codigo_plan, this.categoria, this.modelo)
      .subscribe(
        response => {

          var resp = response as ApiResponse;
          if (resp.status == 'success') {
            this.plans = (resp.data) as Plan[];
            if (this.isDtInitialized) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                this.SpinnerService.hide();
                dtInstance.destroy();
                this.dtTrigger.next(0);
              });
            } else {
              this.SpinnerService.hide();
              this.isDtInitialized = true;
              this.dtTrigger.next(0);
            }
          }

        },
        error => {
          if (error.status != 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"
            });
            this._router.navigate(['logout/1']);
          }
        }
      );


  }

  addPlan(plan?: Plan) {
    const modalRef = this.modalService.open(PlanComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.name = "Registrar Plan";
    if (plan) {
      modalRef.componentInstance.name = "Editar Plan";
      modalRef.componentInstance.plan = plan;
    }
    modalRef.result.then(
      (result) => {
        if (result == "Yes") {
          this.loadPlanes();
        }

      },
      (cancel) => {
      }
    );
  }

  deletePlan(plan: Plan) {

    Swal.fire({
      title: "Eliminar Plan",
      text: "¿Estas seguro(a) de que desea continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4dbd74",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.SpinnerService.show();
        this._planServices.deletePlan(this.token, plan)
          .subscribe(
            response => {
              if (response.status = 'success') {
                this.SpinnerService.hide();
                Swal.fire("Plan Eliminado", "", "success");
                this.loadPlanes();
              }
            },
            error => {
              if (error.status != 401) {
                this.SpinnerService.hide();
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  width: 400,
                  text: error.error.message,
                  footer: '<a href="">¿Por qué tengo este problema?</a>'
                });
              } else if (error.status == 401) {
                this.SpinnerService.hide();
                Swal.fire({
                  icon: "warning",
                  title: "Sesión Expirada",
                  text: 'Su sesión ha finalizado',
                  showCancelButton: false,
                  confirmButtonColor: "#4dbd74",
                  confirmButtonText: "Continuar"
                });
                this._router.navigate(['logout/1']);
              }
            }
          );
      }
    });
  }

  uploadFile($event) {
    this.SpinnerService.show();
    this.files = $event.target.files;
    this.procesar = true;
    this.SpinnerService.hide();
  }

  ngOnDestroy(): void {
    clearTimeout(1000);
    this.dtTrigger.unsubscribe();
  }

  uploadAndProgress() {
    this.SpinnerService.show();
    if (!this.files) {
      this.SpinnerService.hide();
      //this.message = 'No se pudo importar el archivo';
      return false;
    }
    var formData = new FormData();
    Array.from(this.files).forEach(f => formData.append('file', f))

    this._planServices.importPlanes(this.token, formData)
      .subscribe({
        
        next: (resp) => {
          if (resp.status == 200)
        this.loadPlanes();        
        this.procesar = false;
        this.SpinnerService.hide();
        },
        error: (error) => {
          if (error.status != 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              width: 400,
              text: error.error.message,
              footer: '<a href="">¿Por qué tengo este problema?</a>'
            });
          } else if (error.status == 401) {
            this.SpinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Sesión Expirada",
              text: 'Su sesión ha finalizado',
              showCancelButton: false,
              confirmButtonColor: "#4dbd74",
              confirmButtonText: "Continuar"
            });
            this._router.navigate(['logout/1']);
          }
        }}

      );
  }

  ExportToExcel() {
    this.SpinnerService.show();
    TableUtil.exportArrayToExcel(this.plans, "ListadodePlanes");
    this.SpinnerService.hide();
  }

}

