import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements, addTheme } from 'corporate-ui';
import { theme as scania } from 'scania-theme';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

defineCustomElements();
addTheme(scania);
Chart.register(ChartDataLabels);