import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//import { UserService } from './user.service';

@Injectable()
export class NoIdentityGuard implements CanActivate {

	constructor(
		private _router: Router,
		//private _userService: UserService
		){}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){		
		
		
		let identity = localStorage.getItem('identity');
		if (identity){			
			this._router.navigate(['logout'], { queryParams: {ruta: state.url}});
			return false;
		}else{
			return true;
		}		
	}
}