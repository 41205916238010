import { AfterViewInit, OnDestroy, Component, OnInit, DoCheck, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.services';
import { AuditsService } from '../../../services/audits.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { dataTableSettingInspectionTechnical } from 'src/app/models/dictionary';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InformeInspeccionComponent } from './modal/informe-inspeccion.component';
import Swal from 'sweetalert2';
import { DetailInspectionComponent } from 'src/app/views/detail-inspection/detail-inspection.component';
import { ResumenInspectionComponent } from 'src/app/views/resumen-inspection/resumen-inspection.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-certification-inspection',
  templateUrl: './inspeccion.component.html',
  styleUrls: ['./inspeccion.component.css'],
})
export class InspeccionComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  datefrom:string="";
  dateto:string="";
  ppu:string="";
  public inspections:any[];
  public token;

  public cumple:number=0;
  public nocumple:number=0;
  public noaplica:number=0;
  public c_alta:number =0;
  public c_media:number =0;
  public c_baja:number =0;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  constructor(private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _auditsService: AuditsService,
    private modalService: NgbModal, 
    private SpinnerService: NgxSpinnerService){
    }

  ngOnInit() {
    this.token = this._userService.getToken();
    this.dtOptions = dataTableSettingInspectionTechnical;
    var hoy = new Date();

    this.datefrom =  formatDate(hoy, 'yyyy-MM', 'en')
    this.dateto = formatDate(hoy, 'yyyy-MM', 'en')
  }

  ngDoCheck(): void {
    this.token = this._userService.getToken();
  }

  checkCheckBox($event){
    if ($event.target.checked)
      this.cumple += 1;
  }
  ngAfterViewInit(): void {      
    this.dtTrigger.next(0);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  getInspeccionTecnica(){
    this.SpinnerService.show();
    if (this.ppu != "" && this.datefrom  != "" && this.dateto != "" ){
      this._auditsService.getAuditoriasInspeccionTecnica(this.token, this.ppu, this.datefrom, this.dateto)
      .subscribe({
        next: (resp) => {
          if (resp.status=="success"){
            this.inspections = resp.data;
            this.reDraw();
          }else{
            Swal.fire("Se ha producido un error: " + resp.error, "", "error");
          }
        },
        error: (error) => {
          this.SpinnerService.hide();
        },
        complete: ()=> {
          this.SpinnerService.hide();
        }

      });
    }else{
      this.SpinnerService.hide();
      Swal.fire({
        icon: 'info',
        title: 'Filtros de Busqueda',
        width: 400,
        text: "Debe ingresar todos los parametros de busqueda",
        footer: '<a href="">¿Por qué tengo este problema?</a>'
      });
    }
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

  comprobar(criticidad, check){

    if (criticidad='Alta'){
      if (check){
        this.c_alta = this.c_alta+1;
      }else
        this.c_alta = this.c_alta-1;
    }else if (criticidad = 'Media'){
      if (check){
        this.c_media = this.c_media+1;
      }else
        this.c_media = this.c_media-1;
    }else{
      if (check){
        this.c_baja = this.c_media+1;
      }else
        this.c_baja = this.c_baja-1;
    }
  }

  unselect($event){
    //if (  )
    var check = document.querySelectorAll("input[name=no_aplica1]")
    $("#no_aplica1").removeAttr("checked");
  }

  InformeAuditoria(_inspeccion) {
    const modalRef = this.modalService.open(InformeInspeccionComponent, {
      centered: true,
      size: "md",
    });


    modalRef.componentInstance.inspeccion = _inspeccion;

    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result == 'Yes') {
          this.ppu = "";
          this.inspections = [];
          this.reDraw();
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  getInpecciones(){
    this.SpinnerService.show();
    this._auditsService.getAuditInspeccionesTecnicas(this.token, this.ppu, this.datefrom, this.dateto)
    .subscribe({
      next: (resp) => {

        if (resp.status=="success"){
          this.inspections = resp.data;
          this.reDraw();
        }
      },
      error: (error) => {
        this.SpinnerService.hide();
      },
      complete: ()=> {
        this.SpinnerService.hide();
      }

    })
  }

  
  DetalleInspeccion(id, resultado){
    const modalRef = this.modalService.open(DetailInspectionComponent, {
      centered:true,
      size:'xl'

    });
    modalRef.componentInstance.id_inspeccion = id;
    modalRef.componentInstance.resultado = resultado=='A'?'APROBADO':'NO APROBADO';
    modalRef.result.then(
      (result) => {
        if (result == "Yes") {
          console.info('Ok');
        }

      },
      (cancel) => {
      }
    );
  }

  ResumenInspeccion(id){
    const modalRef = this.modalService.open(ResumenInspectionComponent, {
      centered:true,
      size:'xl'

    });
    modalRef.componentInstance.id_inspeccion = id;
    
    modalRef.result.then(
      (result) => {
        if (result == "Yes") {
          console.info('Ok');
        }

      },
      (cancel) => {
      }
    );
  }

  downloadPdf(base64String, fileName) {
    var prefijo_file;
    prefijo_file='InspeccionTecnica_';

    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${prefijo_file}${fileName}.pdf`
    link.click();
  }
  onClickDownloadPdf(base64, ppu, id){
    this.downloadPdf(base64, ppu + '_' + id);
  }

  DeleteInspeccion(id_inspeccion_informe){
    Swal.fire({
      title: "Eliminar Informe Inspección Técnica?",
      text: "¿Estas seguro(a) de que desea eliminar?",
      icon: "warning",
      width: "500px",
      heightAuto: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.SpinnerService.show();
        this._auditsService.DeleteInspeccionTecnicaReport(this.token, id_inspeccion_informe)
        .subscribe({
          next:(res) => {

          if (res.status == 'success') {
            Swal.fire("Informe Inspección Técnica Eliminada", "", "success");
            this.getInpecciones();
          } else {            
            Swal.fire({
              icon: "error",
              title: "Ha ocurrido el siguiente error:",
              text: res.message,
            });         
          }
        },
        error:(error)=>{
          this.SpinnerService.hide();
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido el siguiente error:",
            text: error});
        },
        complete:()=>{
          this.SpinnerService.hide();
        }
      });
      }
    });
  }


}