import { AfterViewInit, Component, OnInit, DoCheck, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MaintenanceService } from 'src/app/services/maintenance.services';
import { formatDate, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';
import { CertificationService } from 'src/app/services/certification.services';
import { InformeAuditoriaComponent } from './modal/informe-auditoria.component';
import { DataTableDirective } from "angular-datatables";
import { Subject } from 'rxjs';
import { dataTableSettingCertDocumental } from 'src/app/models/dictionary';
import { DetailSparePartsComponent } from 'src/app/views/detail-spareparts/detail-spareparts.component';
import { DetailMaintenancesComponent } from 'src/app/views/detail-maintenances/detail-maintenances.component';
import { AuditsService } from 'src/app/services/audits.services';
@Component({
  selector: 'app-certification-documentary-audit',
  templateUrl: './documental.component.html',
  styleUrls: ['./documental.component.css'],
})
export class DocumentalComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // pager object
  maintenances: any[] = new Array<any>();
  enabledRegister:boolean = false;
  public datefrom = "";
  public dateto = "";
  public ppu = "";
  public token;
  public ots:any[]= [];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  //public buses:any;
  constructor(
    private modalService: NgbModal,
    private _CertificationService: CertificationService,
    private SpinnerService: NgxSpinnerService,
    private _UserService: UserService,
    private _router: Router,
    private _auditsService:AuditsService
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.token = this._UserService.getToken();
    this.dtOptions = dataTableSettingCertDocumental;
    var hoy = new Date();

    hoy.setMonth(hoy.getMonth() - 1);

    this.datefrom =  formatDate(hoy, 'yyyy-MM', 'en')
    this.dateto = formatDate(hoy, 'yyyy-MM', 'en')
  }

  ngAfterViewInit(): void {      
    this.dtTrigger.next(0);
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }
  
  getMantenciones() {
      this.SpinnerService.show();
      this._CertificationService.getMantenimientosPeriodo(this._UserService.getToken(), this.datefrom, this.dateto, this.ppu, "")
        .subscribe(
          response => {
            
            this.maintenances = response.data;
            if (this.isDtInitialized) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                this.SpinnerService.hide();
                dtInstance.destroy();
                this.dtTrigger.next(0);
              });
            } else {
              this.SpinnerService.hide();
              this.isDtInitialized = true;
              this.reDraw();
            }
            let i=0;
            let id_informe=0;
            this.maintenances.forEach(element => {           
              if(element.estado=='A' || element.estado=='R'){
                this.ots.push({"ot": element.ot});
                id_informe = element.id_auditoria_informe;
                i++;
              }
            });


            if(i==this.maintenances.length && (id_informe==0 || id_informe== null) && this.ppu != "" && this.datefrom==this.dateto)
              this.enabledRegister=true;
            else
              this.enabledRegister=false;
            
          },
          error => {
            if (error.status != 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado',
                showCancelButton: false,
                confirmButtonColor: "#4dbd74",
                confirmButtonText: "Continuar"
              });
              this._router.navigate(['logout/1']);
            }
          }
        );
  }

  limpiarFiltro(){
    this.ppu="";
    this.maintenances= [];
    this.reDraw();
    this.enabledRegister=false;
  }

  InformeAuditoria() {
    const modalRef = this.modalService.open(InformeAuditoriaComponent, {
      centered: true,
      size: "MD",
    });
    modalRef.componentInstance.ppu = this.ppu;
    modalRef.componentInstance.datefrom = this.datefrom;
    modalRef.componentInstance.ots = this.ots;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
          }*/
        if (result == 'Yes') {
          this.limpiarFiltro();
          
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  DetalleRepuestos(ot: number) {
    const modalRef = this.modalService.open(DetailSparePartsComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.ot = ot;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
            ('yes');
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }

  DetalleMantenimientos(ot: string) {
    const modalRef = this.modalService.open(DetailMaintenancesComponent, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.ot = ot;
    modalRef.componentInstance.ppu = this.ppu;
    modalRef.result.then(
      (result) => {
        /* var res = result.split(";", 2);
          if (res[0] == "Yes") {
            ('yes');
          }*/
        if (result != 'Yes') {
        }

        //this.ngOnInit();
      },
      (cancel) => {
      }
    );
  }
  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }

  downloadPdf(base64String, fileName) {
    var prefijo_file;
    prefijo_file='Auditoria_Documental_';

    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${prefijo_file}${fileName}.pdf`
    link.click();
  }
  onClickDownloadPdf(base64, ppu, id){
    this.downloadPdf(base64, ppu + '_' + id);
  }

  deleteInformeAuditoria(id_auditoria_informe){
    Swal.fire({
      title: "Eliminar Informe Auditoria Documental?",
      text: "¿Estas seguro(a) de que desea eliminar?",
      icon: "warning",
      width: "500px",
      heightAuto: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.SpinnerService.show();
        this._auditsService.DeleteInformeAuditoriaDocumental(this.token, id_auditoria_informe)
        .subscribe({
          next:(res) => {

          if (res.status == 'success') {
            Swal.fire("Auditoria Documental Eliminada", "", "success");
            this.getMantenciones();
          } else {            
            Swal.fire({
              icon: "error",
              title: "Ha ocurrido el siguiente error:",
              text: res.message,
            });         
          }
        },
        error:(error)=>{
          this.SpinnerService.hide();
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido el siguiente error:",
            text: error});
        },
        complete:()=>{
          this.SpinnerService.hide();
        }
      });
      }
    });
  }

}

