import { ChangeDetectorRef, Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Category, SubCategory } from 'src/app/models/category';
import { MaintenanceService } from 'src/app/services/maintenance.services';
import { Maintenance, Repuestos } from 'src/app/models/maintence';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { Repuesto } from 'src/app/models/repuestos';
import { PagerService } from 'src/app/services/pager.services';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';
@Component({
  selector: 'app-register-maintenance',
  templateUrl: './register-maintenance.component.html',
  styleUrls: ['./register-maintenance.component.css'],
  providers: [MaintenanceService, PagerService, UserService]
})
export class RegisterMaintenanceComponent implements OnInit, DoCheck {
  @ViewChild("staticTabs", { static: false }) staticTab: TabsetComponent;

  public name: string = 'Registrar Mantenimiento';
  public ppu: string;
  public codigo_plan: string;
  public categoria: string;
  public maintenance: Maintenance = new Maintenance();
  public repuestos: Repuesto[] = new Array<Repuesto>()
  public categories: Category[] = new Array<Category>();
  addMaintenanceForm: FormGroup;
  addRepuestosForm: FormGroup;
  pager: any = {};
  // paged items
  pagedItems: any[];
  totalxPage: number = 5;
  public token;
  constructor(
    public modal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private _maintenanceService: MaintenanceService,
    private _pagerService: PagerService,
    private _UserService: UserService,
    private _router: Router,
  ) {

    this.addMaintenanceForm = this._formBuilder.group({
      ppu: new FormControl(this.ppu, Validators.required),
      ot: new FormControl(this.ppu, Validators.required),
      codigo_plan: new FormControl(this.codigo_plan, Validators.required),
      fecha_inicio: new FormControl("", Validators.required),
      fecha_final: new FormControl("", Validators.required),
      hora_inicio: new FormControl("", Validators.required),
      hora_final: new FormControl("", Validators.required),
      categoria: new FormControl(this.categoria, Validators.required),
      detalle: new FormControl("", Validators.required),
      kilometraje: new FormControl("", Validators.required),
      causa: new FormControl("", Validators.required)
    });

    this.addRepuestosForm = this._formBuilder.group({
      nroparte: new FormControl("", Validators.required),
      descripcion: new FormControl("", Validators.required),
      marca: new FormControl("", Validators.required),
      cantidad: new FormControl(0, [Validators.required, Validators.min(1)])
    });

  }

  ngOnInit() {
    this.token = this._UserService.getToken();
  }

  ngDoCheck(): void {
    this.token = this._UserService.getToken();
  }

  CloseModal() {
    this.modal.close('Cerrar');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  saveMaintenance() {
    this.SpinnerService.show();

    if (this.addMaintenanceForm.valid) {
      this.maintenance.ot =  this.addMaintenanceForm.get('ot').value;
      this.maintenance.ppu = this.addMaintenanceForm.get('ppu').value;
      this.maintenance.codigo_plan = this.addMaintenanceForm.get('codigo_plan').value;
      this.maintenance.fecha_inicio = this.addMaintenanceForm.get('fecha_inicio').value;
      this.maintenance.fecha_final = this.addMaintenanceForm.get('fecha_final').value;
      this.maintenance.hora_inicio = this.addMaintenanceForm.get('hora_inicio').value;
      this.maintenance.hora_final = this.addMaintenanceForm.get('hora_final').value;
      this.maintenance.categoria = this.addMaintenanceForm.get('categoria').value;
      this.maintenance.detalle = this.addMaintenanceForm.get('detalle').value;
      this.maintenance.kilometraje = this.addMaintenanceForm.get('kilometraje').value;
      this.maintenance.causa = this.addMaintenanceForm.get('causa').value;
      this.maintenance.repuestos = new Array<Repuestos>();
      this.maintenance.repuestos = this.repuestos;

      this._maintenanceService.saveMantenimiento(this.token, this.maintenance)
        .subscribe(
          response => {
            Swal.fire("Mantenimiento ingresado", "", "success");
            this.SpinnerService.hide();
            this.modal.close('Yes');
          },
          error => {
            if (error.status != 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado'
              });
              this._router.navigate(['logout/1']);
            }
          }
        )
    } else this.SpinnerService.hide();
  }

  AgregarRepuesto() {
    let rep = new Repuesto();

    rep.nroparte = this.addRepuestosForm.get('nroparte').value;
    rep.descripcion = this.addRepuestosForm.get('descripcion').value;
    rep.marca = this.addRepuestosForm.get('marca').value;
    rep.cantidad = +this.addRepuestosForm.get('cantidad').value;

    this.repuestos.push(rep);
    this.setPage(1);

  }
  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    // get pager object from service
    this.pager = this._pagerService.getPager(this.repuestos.length, page, +this.totalxPage);

    // get current page of items
    this.pagedItems = this.repuestos.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
}



