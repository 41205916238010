import { AfterViewInit, Component, OnInit, DoCheck, OnDestroy, ViewChild } from '@angular/core';
import { UserComponent } from './modal/register-user/register-user.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/models/user';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.services';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { Subject } from 'rxjs';
import { dataTableSettingNotButtonsMax } from 'src/app/models/dictionary';

@Component({
  selector: 'app-admin-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements AfterViewInit, OnDestroy, OnInit, DoCheck {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public users: User[] = new Array<User>();
  public token;
  public identity;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;

  constructor(private modalService: NgbModal, private SpinnerService: NgxSpinnerService, private _userService: UserService,
    private _router: Router,) {
    
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(0);
  }

  ngOnInit() {
    this.token = this._userService.getToken();
    this.identity = this._userService.getIdentity();
    this.dtOptions = dataTableSettingNotButtonsMax;
    this.getUsers();
  }

  ngDoCheck(): void {
    this.token = this._userService.getToken();
  }

  getUsers() {
    if (this.token) {
      this.SpinnerService.show();
      this._userService.getUsers(this.token)
        .subscribe(
          response => {
            this.users = response.data as User[];
            if (this.isDtInitialized) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                this.SpinnerService.hide();
                dtInstance.destroy();
                this.dtTrigger.next(0);
              });
            } else {
              this.SpinnerService.hide();
              this.isDtInitialized = true;
              this.reDraw();
            }
          },
          error => {
            if (error.status != 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                width: 400,
                text: error.error.message,
                footer: '<a href="">¿Por qué tengo este problema?</a>'
              });
            } else if (error.status == 401) {
              this.SpinnerService.hide();
              Swal.fire({
                icon: "warning",
                title: "Sesión Expirada",
                text: 'Su sesión ha finalizado',
                showCancelButton: false,
                confirmButtonColor: "#4dbd74",
                confirmButtonText: "Continuar"});
                this._router.navigate(['logout/1']);
            }
          }
        )
    }
  }
  
  addUser(user?: User) {
    const modalRef = this.modalService.open(UserComponent, {
      centered: true,
      size: "md",
    });
    modalRef.componentInstance.name = "Registrar Usuario";
    if (user) {
      modalRef.componentInstance.name = "Editar Usuario";
      modalRef.componentInstance.user = user;
    }
    modalRef.result.then(
      (result) => {
        var res = result.split(";", 2);
        if (res[0] == "Yes") {
          this.getUsers();
        }

      },
      (cancel) => {
      }
    );
  }

  DeleteUser(user) {
    Swal.fire({
      title: "Eliminar Usuario",
      text: "¿Estas seguro(a) de que desea continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4dbd74",
      cancelButtonColor: "#c8ced3",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.SpinnerService.show();
        this._userService.deleteUser(this.token, user)
          .subscribe(
            response => {
              if (response.status = 'success') {
                this.getUsers();
                this.SpinnerService.hide();
                Swal.fire("Usuario Eliminado", "", "success");
                
              }
              else{
                this.SpinnerService.hide();
                Swal.fire("Error Inesperado", response.message, "error");
                
              }

            },
            error => {
              if (error.status == 404) {
                this.SpinnerService.hide();
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  width: 400,
                  text: error.error.message,
                  footer: '<a href="">¿Por qué tengo este problema?</a>'
                });
              } else if (error.status == 401) {
                this.SpinnerService.hide();
                Swal.fire({
                  icon: "warning",
                  title: "Sesión Expirada",
                  text: 'Su sesión ha finalizado'
                });
                this._router.navigate(['logout/1']);
              }
            }
          )
      }
    });
  }

  reDraw(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().draw(); // Add this  line to clear all rows..
      dtInstance.destroy();      
      // dtTrigger la reconstruye
      this.dtTrigger.next(0);      
    });
  }

  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
  }
  
}
